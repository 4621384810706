export default {
  it: {
    translations: {
      common: {
        ok: 'Ok',
        yes: 'Sì',
        no: 'No',
        areYouSure: 'Sei sicuro?',
        confirmLogout: 'Sicuro di voler uscire?',
        cancel: 'Annulla',
        actions: 'Azioni',
        save: 'Salva',
        delete: 'Elimina',
        restore: 'Ripristina',
        loadMore: 'Carica Ancora',
        filter: 'Filtra',
        filterByName: 'Filtra per nome',
        send: 'Invia',
        resend: 'Re-invia',
        create: 'Crea',
        error: 'ERRORE',
        pending: 'In attesa...',

        application: 'Applicazione',
        applications: 'Applicazioni',
        group: 'Gruppo',
        groups: 'Gruppi',
        detail: 'Dettaglio',
        details: 'Dettagli',
        logout: 'Esci',
        defaultGroupName: 'Default',

        requiredField: 'Campo Obbligatorio',
        roles: 'Ruoli',
        email: 'Email',
        name: 'Nome',
        timezone: 'Fuso orario',
        owner: 'Proprietario',
        server: 'Server',
        ftpPort: 'Porta FTP',
        ftpUsername: 'Nome utente FTP',
        ftpPassword: 'Password FTP',
        period: 'Periodo',
        directory: 'Directory',
        directoryForEveryDevice: 'Directory per ogni dispositivi',
        enabled: 'Abilitato',
        urlResource: 'URL risorse',

        devID: 'DEV ID',
        devEUI: 'DEV EUI',
        appID: 'APP ID',
        appEUI: 'APP EUI',
        description: 'Descrizione',
        url: 'URL',
        processID: 'ID processo',
        accessKey: "Chiave d'accesso",
        rssi: 'RSSI',
        snr: 'SNR',
        dataRate: 'Data Rate',
        lastBatteryLevel: 'Livello Batteria',
        lastActivityTimestamp: 'Stato',
        dateAndTime: 'Data e ora',
        serialNumber: 'Numero Seriale',
        model: 'Modello',
        userCode: 'Codice utente',
        groupName: 'Nome gruppo',
        rainTip: 'Rain Tip',
        firmwareVersion: 'Versione FW',
        firmwareDate: 'Data FW',
        onlySIUnits: 'SOLO UNITÀ SI',
        isTimeSynchActive: 'Sincronizzazione tempo attiva',
        RFLogInterval: 'Intervallo RF/Log',
        measureInterval: 'Intervallo di misura',
        protocolType: 'Tipo di protocollo',
        timeSynchronization: 'Sincronizzazione temporale',
        loggingActive: 'Logging attivo',
        cyclicLogging: 'Logging circolare',
        buzzerAlarm: 'Allarme buzzer',
        refreshAllInfo: 'Aggiorna tutte le info',
        testBuzzer: 'Test buzzer',
        ignorePendingCommands: 'IGNORA COMANDI PENDENTI',
        askHistoricalData: 'Chiedi dati storici',
        devEUIorSerialNumber: 'DEV EUI / numero seriale',

        channels: 'Canali',
        status: 'Stato',
        measureType: 'Tipo di Misura',
        unitOfMeasure: 'Unità di misura',
        resolution: 'Precisione',
        channelName: 'Nome Canale',
        qualifier: 'Qualificatore',

        measure: 'Misurazione',
        measures: 'Misurazioni',
        measuresNoData: 'Non ci sono misurazioni disponibili per il periodo richiesto',
        propagable: 'Propagabili',
        propagateConfig: 'Propaga la configurazione corrente',
        confirmPropagateConfig: "Tutti i dispositivi dell'insieme riceveranno la stessa configurazione corrente. Sicuro di continuare?",
        propagateSettings: 'Propaga la configurazione',
        confirmPropagateSettings: "Tutti gli dispositivi dell'insieme riceveranno l'attuale configurazine. Sicuro di continuare?",
        operationSuccess: 'Operazione avvenuto con successo',
        timestamp: 'Orario',

        confirmDeleteGroup: 'Conferma rimuovere gruppo?',
        confirmDeleteApplication: 'Conferma rimuovere applicazione?',

        removeFromApplication: "Rimuovere dispositivo dall'applicazione",
        confirmRemoveFromApplication: "Sicuro di voler rimuovere il dispositivo dall'applicazione?",

        removeFromGroup: 'Rimuovere dispositivo dal gruppo',
        confirmRemoveFromGroup: 'Sicuro di voler rimuovere il dispositivo dal gruppo?',

        selectRange: 'Scegli intervallo',
        today: 'Oggi',
        lastHour: 'Ultima ora',
        thisWeek: 'Questa settimana',
        thisMonth: 'Questo mese',
        thisYear: "Quest'anno",
        CH: 'CH',

        minute: 'minuto',
        hour: 'ora',
        day: 'giorno',
        week: 'settimana',
        month: 'mese',
        year: 'anno',

        hideMarkers: 'Nascondi marcatori',
        multiColor: 'Multi-colore',

        nameOverride: 'Nome Sovrascritto',
        overrideChannelName: 'Sovrascrivere il nome del canale',
        overrideGroupSetting: 'Sovrascrivere anche se fa parte di un gruppo',
        notOverrideGroupSetting: 'NON sovrascrivere se fa parte di un gruppo',

        removeDeviceFromGroup: 'Rimuovi dispositivo dal gruppo',
        commandCommunications: 'Comunicazioni comandi',
        commandCommunicationNoData: 'Non ci sono comunicazioni',
      },
      enums: {
        groupRoles: {
          GROUP_MANAGER: 'Super Utente',
          GROUP_VIEWER: 'Utente',
        },
        periods: {
          SEC0: 'Tempo reale',
          SEC60: '1 min',
          SEC120: '2 min',
          SEC300: '5 min',
          SEC600: '10 min',
          SEC900: '15 min',
          SEC1800: '30 min',
          SEC3600: '1 ora',
          SEC7200: '2 ore',
          SEC14400: '4 ore',
          SEC28800: '8 ore',
          SEC43200: '12 ore',
          SEC86400: '24 ore',
        },
        intervals: {
          SEC1: '1 sec',
          SEC2: '2 sec',
          SEC5: '5 sec',
          SEC10: '10 sec',
          SEC15: '15 sec',
          SEC30: '30 sec',
          MIN1: '1 min',
          MIN2: '2 min',
          MIN5: '5 min',
          MIN10: '10 min',
          MIN15: '15 min',
          MIN30: '30 min',
          HOUR1: '1 ora',
          HOUR12: '12 ore',
          HOUR24: '24 ore',
        },
        alarms: {
          level2: 'allarme soglia inferiore',
          level3: 'allarme soglia superiore',
          level4: 'errore underflow e allarme inferiore',
          level5: 'errore not init e allarme inferiore',
          level6: 'errore overflow e allarme superiore',
          level7: 'errore e allarme superiore',
        },
        batteryLevels: {
          level0: 'batteria scarica',
          level1: 'metà batteria',
          level2: 'batteria piena',
          level3: 'alimentazione esterna e batteria di back-up',
          level4: 'alimentazione esterna',
        },
        qualifiers: {
          archiv: 'ARCHIV',
          avg: 'AVG',
          none: 'NONE',
          max: 'MAX',
          min: 'MIN',
          tot: 'TOT',
        },
        measureTypes: {
          temperature: 'Temperatura',
          relativeHumidity: 'Umidità relativa',
          dewPoint: 'Punto di rugiada',
          partialVaporPressure: 'Pressione di vapore parziale',
          mixingRatio: 'Rapporto di mescolanza',
          absoluteHumidity: 'Umidità assoluta',
          wetPoint: 'Temperatura di bulbo umido',
          differentialPressure: 'Pressione differenziale',
          solarRadiation: 'Radiazione solare',
          illuminance: 'Illuminamento',
          carbonMonoxide: 'Monossido di carbonio',
          atmosphericPressure: 'Pressione atmosferica',
          frequency: 'Frequenza',
          mappedFrequency: 'Frequenza mappata',
          dailyGlobalRadiation: 'Radiazione globale giornaliera',
          carbonDioxide: 'Biossido di carbonio',
          volumWaterContent: 'Contenuto idrico volumetrico',
          soilMoisture: 'Umidità del suolo',
          accelerationZ: 'Accelerazione Z',
          height: 'Altezza',
          shockTime: 'Tempo di shock',
          deltaSpeed: 'Differenza di velocità',
          windSpeed: 'Velocità del vento',
          windDirection: 'Direzione del vento',
          windChill: 'Temperatura del vento',
          pyranometer: 'Piranometro',
          uvaIrradiance: 'Irradiazione UVA',
          uvaProportion: 'Proporzione UVA',
          naturalWetBulb: 'Temperatura di bulbo umido naturale',
          globeTemperature: 'Globotermometro',
          wbgtIndoor: 'WBGT interno',
          wbgtOutdoor: 'WBGT esterno',
          windGust: 'Raffica di vento',
          maxRainRate: 'Massima intensità di pioggia',
          dailyRain: 'Pioggia giornaliera',
          batteryVoltage: 'Tensione batteria',
          mkt_1: 'Temperatura cinetica media 1',
          mkt_2: 'Temperatura cinetica media 2',
          mkt_3: 'Temperatura cinetica media 3',
          contactInput: 'Ingresso contatto',
          flow: 'Flusso',
          volumetricWaterContent: 'Contenuto idrico volumetrico 2',
          soilMoisture_2: 'Umidità del suolo 2',
          volumWaterContent_3: 'Contenuto idrico volumetrico 3',
          soilMoisture_3: 'Umidità del suolo 3',
          airSpeed: "Velocità dell'aria",
          par: 'PAR',
          maxRainLastHour: 'Massima pioggia ultima ora',
          gpsDirection: 'Direzione GPS',
          naturalWetBulbComp: 'Temperatura di bulbo umido naturale calc.',
          voltagePowerSupply: 'Tensione di alimentazione',
          currentRain: 'Pioggia corrente',
          sunPresence: 'Presenza sole',
          sunLastMinute: 'Sole ultimo minuto',
          sunLast_10Minutes: 'Sole ultimi 10 minuti',
          accelerationX: 'Accelerazione X',
          accelerazionY: 'Accelerazione Y',
          etHour: 'Evapotraspirazione oraria',
          dailyEt: 'Evapotraspirazione giornaliera',
          netRadiation: 'Radiazione netta',
          relativePressure: 'Pressione relativa',
          fluidLevel: 'Livello fluido',
          leafWetnessDown: 'Bagnatura foglia inferiore',
          leafWetnessUp: 'Bagnatura foglia superiore',
          windGustDirection: 'Direzione raffica di vento',
          uvbIrradiance: 'Irradiamento UVB',
          uvcIrradiance: 'Irradiamento UVC',
          albedo: 'Albedo',
          pm1_0: 'PM1.0',
          pm2_5: 'PM2.5',
          pm4_0: 'PM4.0',
          pm10: 'PM10',
          typParticleSize: 'Dimensione tipica particelle',
          VOCIndex: 'Indice VOC',
          eventRain: "Pioggia dell'evento",
          firstRain: 'Prima pioggia',
          temperaturePt100_2W: 'Temperatura PT100 2F',
          temperaturePt100_3W: 'Temperatura PT100 3F',
          temperaturePt100_4W: 'Temperatura PT100 4F',
          temperaturePt1000_2W: 'Temperatura PT1000 2F',
          temperaturePt1000_3W: 'Temperatura PT1000 3F',
          temperaturePt1000_4W: 'Temperatura PT1000 4F',
          temperatureTcK: 'Temperatura TC K',
          temperatureTcJ: 'Temperatura TC J',
          temperatureTcT: 'Temperatura TC T',
          temperatureTcN: 'Temperatura TC N',
          temperatureTcR: 'Temperatura TC R',
          temperatureTcS: 'Temperatura TC S',
          temperatureTcB: 'Temperatura TC B',
          temperatureTcE: 'Temperatura TC E',
          voltage_0_1V: 'Tensione 0-1V',
          voltage_0_50mV: 'Tensione 0-50mV',
          current_4_20mA: 'Corrente 4-20mA',
          potentiometer: 'Potenziomentro',
          mappedVoltage_0_1V: 'Tensione 0-1V mappata',
          mappedVoltage_0_50mV: 'Tensione 0-50mV mappata',
          mappedCurrent_4_20mA: 'Corrente 4-20mA mappata',
          mappedPotentiometer: 'Potenziomentro mappato',
          voltage_0_10V: 'Tensione 0-10V',
          mappedVoltage_0_10V: 'Tensione 0-10V mappata',
          digitalMeasure: 'Misura digitale',
          voltage_50_50mV: 'Tensione -50...+50mV',
          mapVolt_50_50mV: 'Tensione -50...+50mV mappata',
          pyrgeometer: 'Pirgeometro',
          current: 'Corrente',
          temperaturePt100_3WHr: 'Temperatura PT100 3F HR',
          temperaturePt100_4WHr: 'Temperatura PT100 4F HR',
          temperaturePt1000_3WHr: 'Temperatura PT1000 3F HR',
          temperaturePt1000_4WHr: 'Temperatura PT1000 4F HR',
          flowRate: 'Portata',
          counter: 'Contatore',
          mappedCounter: 'Contatore mappato',
          totalRainCompensed: 'Pioggia totale compensata',
          dailyRainCompensed: 'Pioggia giorno compensata',
          totaleEnergy: 'Energia totale',
          energy: 'Energia',
          rainCounter: 'Contatore pioggia',
          notImplemented: 'Non implementata',
          rssi: 'RSSI',
          snr: 'SNR',

          // Cayenne
          digitalIn: 'Ingresso digitale',
          digitalOut: 'Uscita digitale',
          analogIn: 'Ingresso analogico',
          analogOut: 'Uscita analogica',
          // illuminance: 'Illuminazione',
          presence: 'Presence',
          // temperature: 'Temperatura',
          // relativeHumidity: "Umidita' relativa",
          // accelerationX: 'Accelerazione X',
          accelerationY: 'Accelerazione Y',
          // accelerationZ: 'Accelerazione Z',
          // atmosphericPressure: 'Pressione Atmosferica',
        },
      },
      extractGraphQLErrors: {
        noDuplicate: 'Non sono permessi duplicati',
        networkError: 'Server non raggiungibile, controllare connessione',
        unexpectedError: 'Si è verificato un errore imprevisto',
        unauthenticated: 'NON sei autenticato per visualizzare queste informazioni',
      },
      header: {
        menu: {
          groups: 'Gruppi',
          applications: 'Applicazioni',
          devices: 'Dispositivi',
          logOut: 'Esci',
        },
      },
      allDevices: {
        list: {
          title: 'Tutti i dispositivi',
          subtitle: 'Lista tutti i dispositivi',
          noData: 'Non ci sono dispositivi',
        },
      },
      applications: {
        list: {
          title: 'Applicazioni',
          subtitle: 'Lista delle applicazioni',
          noData: 'Non ci sono applicazioni',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositivi',
            },
            settings: {
              title: 'Impostazioni',
            },
            exportIntegrations: {
              title: 'Esportazioni',
            },
          },
        },
        create: {
          title: 'Crea una nuova applicazione',
        },
        edit: {
          title: "Modifica l'applicazione",
        },
        delete: {
          title: "Sicuro di voler cancellare l'applicazione?",
        },
      },
      groups: {
        list: {
          title: 'Gruppi',
          subtitle: 'Lista dei gruppi',
          noData: 'Non ci sono gruppi',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositivi',
            },
            settings: {
              title: 'Impostazioni',
            },
            users: {
              title: 'Utenti',
            },
            exportIntegrations: {
              title: 'Esportazioni',
            },
          },
        },
        create: {
          title: 'Crea un nuovo gruppo',
        },
        edit: {
          title: 'Modifica il gruppo',
        },
        delete: {
          title: 'Sicuro di voler cancellare il gruppo?',
        },
        addDevice: {
          title: 'Aggiungi un dispositivo al gruppo',
        },
      },
      userPermissions: {
        list: {
          title: 'Permessi',
          subtitle: 'Lista degli utenti abilitati e relativi ruoli',
          noData: 'Non ci sono permessi',
        },
        edit: {
          title: 'Modifica un permesso',
        },
      },
      devices: {
        list: {
          title: 'Dispositivi',
          subtitle: 'Lista dei dispositivi',
          noData: 'Non ci sono dispositivi',
        },
        details: {
          tabs: {
            monitors: {
              title: 'Monitoraggio',
            },
            settings: {
              title: 'Impostazioni',
            },
            exportIntegrations: {
              title: 'Esportazioni',
            },
            advanced: {
              title: 'Impostazioni avanzate',
            },
            remoteConfigurations: {
              title: 'Configurazioni remote',
            },
          },
        },
        edit: {
          title: 'Modifica il dispositivo',
        },
        monitorsTabs: {
          realTime: {
            title: 'Tempo reale',
          },
          chart: {
            title: 'Grafici',
          },
          table: {
            title: 'Tabelle',
          },
        },
        remoteConfigurationsTabs: {
          configurations: {
            title: 'Configurazioni',
          },
          terminal: {
            title: 'Terminale Comandi',
          },
        },
        create: {
          title: 'Crea un dispositivo',
        },
        commands: {
          title: 'Invio comandi',
          log: 'Log comunicazione',
          GENERIC: 'Comando',
          GET_INFO: 'Richiedi informazioni',
          SET_DEVICE_NAME: 'Configura nome dipositivo',
          SET_GROUP_NAME: 'Configura nome gruppo',
          GET_COMMUNICATION_PROTOCOL: 'Leggi protocollo comunicazione',
          SET_COMMUNICATION_PROTOCOL: 'Configura protocollo comunicazione',
          SET_TRANSISSION_RATE: 'Configura rate trasmissivo',
          GET_TRANSISSION_RATE: 'Leggi rate trasmissivo',
          fPort: 'Port',
        },
      },
      groupInvites: {
        list: {
          title: 'Inviti',
          subtitle: 'Lista degli inviti pendenti che devono ancora essere accettati',
          noData: 'Non ci sono inviti',
        },
        create: {
          title: 'Crea un nuovo invito',
        },
      },
      groupOwnershipInvites: {
        create: {
          title: 'Invito a diventare proprietario del gruppo',
        },
      },
      exportIntegrations: {
        ftp: {
          title: 'FTP',
          edit: {
            title: 'Modifica impostazioni esportazioni FTP',
          },
          test: {
            title: 'Test',
            testFTP: 'Test connessione FTP',
            testFTPBetweenDatetime: 'Invia via FTP i dati di un intervallo temporale',
            resetLast: "Reset data dell'ultima esportazione",
          },
        },
        deltaCloud: {
          title: 'DeltaCloud',
          edit: {
            title: 'Modifica impostazioni esportazioni DeltaCloud',
            setDefaultURL: 'Imposta URL predefinito',
          },
          test: {
            title: 'Test',
            testDeltaCloud: 'Test connessione DeltaCloud',
            testDeltaCloudBetweenDatetime: 'Invia a DeltaCloud i dati di un intervallo temporale',
            resetLast: "Reset data dell'ultima esportazione",
          },
        },
      },
    },
  },
};
