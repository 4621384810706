import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';

import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
  CustomButton,
} from '../../generic';

import {
  DeltaCloudExportIntegrationForm,
  DeltaCloudExportIntegrationTestingCard,
} from '../../exportIntegration/components';

import {
  GROUP_QUERY,
  GROUP_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION,
  GROUP_PROPAGATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION,
} from '../queries';

const GroupDeltaCloudExportIntegrationEditCard = (props) => {
  const {
    group,
  } = props;

  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [askConfirmPropagateVisible, setAskConfirmPropagateVisible] = React.useState(false);

  const [
    groupUpdateDeltaCloudExportIntegrationMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    groupPropagateDeltaCloudExportIntegrationMutationAction,
    {
      loading: loadingPropagate,
      error: errorPropagate,
    },
  ] = useMutation(GROUP_PROPAGATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (data) => {
    try {
      const result = await groupUpdateDeltaCloudExportIntegrationMutationAction({
        variables: {
          input: {
            id: group.id,
            ...data,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handlePropagate = async () => {
    try {
      const result = await groupPropagateDeltaCloudExportIntegrationMutationAction({
        variables: {
          input: {
            id: group.id,
          },
        },
      });
      if (result) setSuccessOpen(true);
      setAskConfirmPropagateVisible(false);
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <CardWrapper
      cardTitle={t('exportIntegrations.deltaCloud.edit.title')}
      cardBody={
        (
          <>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper error={error || errorPropagate} />
            <Loader loading={loading || loadingPropagate} />
            <DeltaCloudExportIntegrationForm
              deltaCloudExportIntegration={group.deltaCloudExportIntegration}
              onFormSubmit={handleFormSubmit}
            />
            <div style={{ width: '100%', marginTop: 20 }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setAskConfirmPropagateVisible(true)}
              >
                <Typography>{t('common.propagateConfig')}</Typography>
              </Button>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={askConfirmPropagateVisible}
                onClose={() => setAskConfirmPropagateVisible(false)}
              >
                <DialogTitle id="draggable-dialog-title">
                  {t('common.confirmPropagateConfig')}
                </DialogTitle>
                <DialogActions>
                  <CustomButton autoFocus onClick={() => setAskConfirmPropagateVisible(false)} color="info">
                    {t('common.cancel')}
                  </CustomButton>
                  <CustomButton onClick={() => handlePropagate()} color="primary">
                    {t('common.ok')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
            </div>
            <DeltaCloudExportIntegrationTestingCard
              id={group.id}
            />
          </>
        )
      }
    />
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    deltaCloudExportIntegration: PropTypes.object,
  }).isRequired,
};

GroupDeltaCloudExportIntegrationEditCard.propTypes = propTypes;

export default GroupDeltaCloudExportIntegrationEditCard;
