import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Paper,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

import { clearTypenames } from '../../utils/graphql';
import { TimezoneSelector } from '../../generic';

const ApplicationForm = (props) => {
  const {
    application,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: application,
  });

  const handleFormSubmit = (values) => {
    const cleanedValuesForUpdate = values;
    if (application) {
      delete cleanedValuesForUpdate.appID;
      delete cleanedValuesForUpdate.url;
      delete cleanedValuesForUpdate.processID;
      delete cleanedValuesForUpdate.accessKey;
    }
    const fixedValues = application ? cleanedValuesForUpdate : values;

    onFormSubmit(clearTypenames({
      ...fixedValues,
    }));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.appID')}
                placeholder={t('common.appID')}
                error={errors.appID}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
                disabled
              />
            )}
            id="appID"
            defaultValue=""
            name="appID"
            control={control}
          />
          {errors.appID && <p>{t('common.requiredField')}</p>}
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.appEUI')}
                placeholder={t('common.appEUI')}
                error={errors.appEUI}
                fullWidth
                required
                autoCapitalize="none"
                disabled={application.isV3}
              />
            )}
            id="appEUI"
            defaultValue=""
            name="appEUI"
            control={control}
          />
          {errors.appEUI && <p>{t('common.requiredField')}</p>}

        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.description')}
                placeholder={t('common.description')}
                error={errors.description}
                fullWidth
                required
                autoCapitalize="none"
              />
            )}
            id="description"
            defaultValue=""
            name="description"
            control={control}
          />
          {errors.description && <p>{t('common.requiredField')}</p>}
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.url')}
                placeholder={t('common.url')}
                error={errors.url}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="url"
            defaultValue=""
            name="url"
            control={control}
          />
          {errors.url && <p>{t('common.requiredField')}</p>}
        </Grid>

        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.processID')}
                placeholder={t('common.processID')}
                error={errors.processID}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="processID"
            defaultValue=""
            name="processID"
            control={control}
          />
          {errors.processID && <p>{t('common.requiredField')}</p>}
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.accessKey')}
                placeholder={t('common.accessKey')}
                error={errors.accessKey}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="accessKey"
            defaultValue=""
            name="accessKey"
            control={control}
          />
          {errors.accessKey && <p>{t('common.requiredField')}</p>}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} style={{ padding: 20 }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TimezoneSelector
                  id="timezone"
                  name="timezone"
                  control={control}
                />
                {errors.timezone && <p>{t('common.requiredField')}</p>}
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>{t('common.onlySIUnits')}</FormLabel>
                  <Controller
                    name="onlySIUnits"
                    control={control}
                    render={(checkboxProps) => (
                      <Checkbox
                        onChange={(e) => checkboxProps.onChange(e.target.checked)}
                        checked={checkboxProps.value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>{t('common.isTimeSynchActive')}</FormLabel>
                  <Controller
                    name="isTimeSynchActive"
                    control={control}
                    render={(checkboxProps) => (
                      <Checkbox
                        onChange={(e) => checkboxProps.onChange(e.target.checked)}
                        checked={checkboxProps.value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={errors.name || errors.timezone}
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ApplicationForm.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
    appID: PropTypes.string,
    onlySIUnits: PropTypes.bool,
    isTimeSynchActive: PropTypes.bool,
    isV3: PropTypes.bool,
    timezone: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

ApplicationForm.defaultProps = {
  application: null,
};

export default ApplicationForm;
