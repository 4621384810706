import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-dashboard-react/components/cardHeaderStyle.js';

const useStyles = makeStyles(styles);

const CardHeader = (props) => {
  const classes = useStyles();

  const {
    className, children, color, icon, ...rest
  } = props;

  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[`${color}CardHeader`]]: color,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
    undefined,
  ]),
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  color: undefined,
  className: undefined,
};

CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

export default CardHeader;
