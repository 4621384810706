import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Loader,
  GraphQLErrorWrapper,
} from '../generic';

import { GROUP_QUERY } from './queries';

export const GroupContext = React.createContext();

export const GroupProvider = (props) => {
  const { children } = props;
  const { groupId } = useParams();

  const {
    error,
    loading,
    data,
  } = useQuery(GROUP_QUERY, {
    variables: {
      id: groupId,
    },
  });

  const group = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.group;
    }
    return null;
  }, [error, data, loading]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  if (loading) {
    return (
      <Loader loading={loading} />
    );
  }

  return (
    <GroupContext.Provider value={{ group }}>{children}</GroupContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

GroupProvider.propTypes = propTypes;

export const { Consumer: GroupConsumer } = GroupContext;
