import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  // Poll,
  Apps,
  TableChart,
} from '@material-ui/icons';

import {
  ChartLine,
} from 'mdi-material-ui';

import {
  GridContainer,
  CustomTabs,
} from '../../generic';
import {
  MeasureMonitorRealTimeTab,
  MeasureMonitorChartTab,
  MeasureMonitorTableTab,
} from '../../measure/tabs';

const DeviceMonitorsTab = (props) => {
  const { t } = useTranslation('translations');
  const { device } = props;

  return (
    <GridContainer>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('devices.monitorsTabs.realTime.title'),
            tabIcon: Apps,
            tabContent: (
              <MeasureMonitorRealTimeTab
                device={device}
              />
            ),
          },
          {
            tabName: t('devices.monitorsTabs.chart.title'),
            tabIcon: ChartLine,
            tabContent: (
              <MeasureMonitorChartTab
                device={device}
              />
            ),
          },
          {
            tabName: t('devices.monitorsTabs.table.title'),
            tabIcon: TableChart,
            tabContent: (
              <MeasureMonitorTableTab
                device={device}
              />
            ),
          },
        ]}
      />
    </GridContainer>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

DeviceMonitorsTab.propTypes = propTypes;

export default DeviceMonitorsTab;
