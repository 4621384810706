import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
} from '@material-ui/core';

import {
  // People,
  Info,
  Devices,
} from '@material-ui/icons';

import {
  AccountGroup,
} from 'mdi-material-ui';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  GridItem,
  GridContainer,
  CustomTabs,
} from '../generic';

import {
  GroupGeneralTab,
  GroupDevicesTab,
  GroupUsersTab,
  GroupExportIntegrationsTab,
} from './tabs';

import { GroupContext } from './GroupContext.jsx';
import { AuthenticationContext } from '../authentication';

const GroupDetailPage = () => {
  const { t } = useTranslation('translations');

  const { group } = React.useContext(GroupContext);
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const { iAmOwner, myRoles } = group;

  const canEdit = React.useMemo(() => (
    iAmAdmin || iAmOwner || myRoles.includes('GROUP_MANAGER')
  ), [iAmAdmin, iAmOwner, myRoles]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Typography variant="h4" component="h4" gutterBottom>
          {group.name}
        </Typography>
      </GridItem>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('groups.details.tabs.devices.title'),
            key: t('groups.details.tabs.devices.title'),
            tabIcon: Devices,
            tabContent: (
              <GroupDevicesTab
                group={group}
                canEdit={canEdit}
              />
            ),
          },
          {
            ...(canEdit && {
              tabName: t('groups.details.tabs.settings.title'),
              key: t('groups.details.tabs.settings.title'),
              tabIcon: Info,
              tabContent: (
                <GroupGeneralTab
                  group={group}
                  canEdit={canEdit}
                />
              ),
            }),
          },
          {
            ...(canEdit && {
              tabName: t('groups.details.tabs.users.title'),
              key: t('groups.details.tabs.users.title'),
              tabIcon: AccountGroup,
              tabContent: (
                <GroupUsersTab
                  group={group}
                  canEdit={canEdit}
                />
              ),
            }),
          },
          {
            ...(canEdit && {
              tabName: t('groups.details.tabs.exportIntegrations.title'),
              key: t('groups.details.tabs.exportIntegrations.title'),
              tabIcon: CloudUploadIcon,
              tabContent: (
                <GroupExportIntegrationsTab
                  group={group}
                  canEdit={canEdit}
                />
              ),
            }),
          },
        ]}
      />
    </GridContainer>
  );
};

export default GroupDetailPage;
