import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

import {
  Link,
  // IconButton,
  Grid,
  Button,
} from '@material-ui/core';

import {
  // Edit,
  Update,
} from '@material-ui/icons';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
  BatteryLevelIcon,
} from '../../generic';
import {
  formatDistanceToNowLocale,
  getTimezoneLabelWithOffset,
} from '../../utils/date';

import DeviceAddToGroupModal from './DeviceAddToGroupModal';

import {
  DeviceSearcher,
} from '../../device/components';

import {
  GROUP_DEVICES_QUERY,
} from '../queries';

import { AuthenticationContext } from '../../authentication';

const GroupDevicesList = (props) => {
  const { pageSize, filter, group } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  // const navigate = useNavigate();

  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const { iAmOwner, myRoles } = group;

  const canEdit = React.useMemo(() => (
    iAmAdmin || iAmOwner || myRoles.includes('GROUP_MANAGER')
  ), [iAmAdmin, iAmOwner, myRoles]);

  const [isDeviceAddToGroupModalOpen, setIsDeviceAddToGroupModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState(null);

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(GROUP_DEVICES_QUERY, {
    variables: {
      groupId: group.id,
      pageSize,
      filter: {
        ...filter,
        search,
      },
    },
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { group: groupQuery } = viewer;
      const { devices: devicesConnection } = groupQuery;
      const { pageInfo, edges } = devicesConnection;
      return {
        devices: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      devices: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const { devices, pageInfo } = queryResult;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.group.devices;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.group.devices;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              group: {
                ...previousResult.viewer.group,
                devices: {
                  ...previousResult.viewer.group.devices,
                  edges: [...oldEdges, ...newEdges],
                  pageInfo: newPageInfo,
                },
              },
            },
          }
          : previousResult;
      },
    });
  };

  // const handleEdit = (device) => {
  //   navigate(`/groups/${group.id}/devices/${device.id}`);
  // };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }

  return (
    <>
      <CardWrapper
        plain
        cardTitle={t('devices.list.title')}
        cardSubtitle={t('devices.list.subtitle')}
        cardHeaderAction={canEdit
          ? () => setIsDeviceAddToGroupModalOpen(true)
          : null}
        cardHeaderIcon={canEdit ? 'add' : null}
        cardBody={
          (
            <>
              <DeviceSearcher
                onSearch={setSearch}
              />
              <CustomTable
                loading={loading}
                noDataText={t('devices.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  ' ',
                  t('common.devID'),
                  t('common.devEUI'),
                  t('common.serialNumber'),
                  t('common.model'),
                  t('common.userCode'),
                  t('common.application'),
                  t('common.firmwareVersion'),
                  t('common.firmwareDate'),
                  t('common.rssi'),
                  t('common.lastBatteryLevel'),
                  t('common.lastActivityTimestamp'),
                  t('common.timezone'),
                  // <Grid container justify="flex-end">{t('common.actions')}</Grid>,
                ]}
                tableData={devices.map((device, index) => ([
                  index + 1,
                  <Link style={{ cursor: 'pointer' }} color="primary" href={`/groups/${group.id}/devices/${device.id}`}>
                    {device.devID}
                  </Link>,
                  device.devEUI,
                  device.serialNumber,
                  device.model,
                  device.userCode,
                  device.application ? device.application.appID : '',
                  device.firmwareVersion,
                  device.firmwareDate,
                  device.rssi,
                  (
                    <Grid container justify="center">
                      <BatteryLevelIcon level={device.lastBatteryLevel} />
                    </Grid>
                  ),
                  formatDistanceToNowLocale(device.lastActivityTimestamp, locale),
                  getTimezoneLabelWithOffset(device.timezone),
                  // (
                  //   <Grid container justify="flex-end">
                  //     <IconButton color="primary" onClick={() => handleEdit(device)}>
                  //       <Edit fontSize="small" />
                  //     </IconButton>
                  //   </Grid>
                  // ),
                ]))}
              />
            </>
          )
        }
        cardFooter={(pageInfo && pageInfo.hasNextPage)
          && (
            <Button
              fullWidth
              onClick={() => handleLoadMore()}
              color="primary"
              variant="contained"
              startIcon={<Update />}
            >
              {t('common.loadMore')}
            </Button>
          )}
      />
      <DeviceAddToGroupModal
        isModalOpen={isDeviceAddToGroupModalOpen}
        group={group}
        onClose={() => setIsDeviceAddToGroupModalOpen(false)}
      />
    </>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    iAmOwner: PropTypes.bool,
    myRoles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
  pageSize: 20,
  filter: {},
};

GroupDevicesList.propTypes = propTypes;
GroupDevicesList.defaultProps = defaultProps;

export default GroupDevicesList;
