import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  Delete,
  Send,
} from '@material-ui/icons';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import GroupInviteCreateModal from './GroupInviteCreateModal';

import {
  GROUP_QUERY,
} from '../../group/queries';
import {
  GROUP_INVITE_CREATE_MUTATION,
  GROUP_INVITE_DELETE_MUTATION,
} from '../queries';

const GroupInvitesList = (props) => {
  const { group } = props;
  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [isGroupInviteCreateModalOpen, setIsGroupInviteCreateModalOpen] = React.useState(false);

  const [
    groupInviteDeleteMutationAction,
    {
      loading: groupInviteDeleteLoading,
      error: groupInviteDeleteError,
    },
  ] = useMutation(GROUP_INVITE_DELETE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    groupInviteCreateMutationAction,
    {
      loading: groupInviteCreateLoading,
      error: groupInviteCreateError,
    },
  ] = useMutation(GROUP_INVITE_CREATE_MUTATION);

  const handleResend = async (data) => {
    try {
      const result = await groupInviteCreateMutationAction({
        variables: {
          input: {
            groupId: group.id,
            email: data.email,
            roles: data.roles,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const loading = React.useMemo(() => (
    groupInviteCreateLoading
    || groupInviteDeleteLoading
  ), [
    groupInviteCreateLoading,
    groupInviteDeleteLoading,
  ]);

  const errors = React.useMemo(
    () => groupInviteCreateError || groupInviteDeleteError,
    [
      groupInviteCreateError,
      groupInviteDeleteError,
    ],
  );

  const handleDelete = async (id) => {
    try {
      const result = await groupInviteDeleteMutationAction({
        variables: {
          input: {
            id,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const { groupInvites } = group;

  return (
    <>
      <CardWrapper
        plain
        cardTitle={t('groupInvites.list.title')}
        cardSubtitle={t('groupInvites.list.subtitle')}
        cardHeaderAction={() => setIsGroupInviteCreateModalOpen(true)}
        cardHeaderIcon="add"
        cardBody={
        (
          <>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper error={errors} />
            <Loader loading={loading} />
            <CustomTable
              noDataText={t('groupInvites.list.noData')}
              tableHeaderColor="primary"
              tableHead={[
                t('common.name'),
                t('common.roles'),
                (
                  <Grid container justify="flex-end">
                    {t('common.actions')}
                  </Grid>
                ),
              ]}
              tableData={groupInvites.map((groupsInvite) => ([
                groupsInvite.email,
                groupsInvite.roles.map((role) => t(`enums.groupRoles.${role}`)).join(', '),
                (
                  <Grid container justify="flex-end">
                    <IconButton color="primary" onClick={() => handleResend(groupsInvite)}>
                      <Send fontSize="small" />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(groupsInvite.id)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Grid>
                ),
              ]))}
            />
          </>
        )
      }
      />
      <GroupInviteCreateModal
        isModalOpen={isGroupInviteCreateModalOpen}
        group={group}
        onClose={() => setIsGroupInviteCreateModalOpen(false)}
      />
    </>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    groupInvites: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
    })),
  }).isRequired,
};

GroupInvitesList.propTypes = propTypes;

export default GroupInvitesList;
