// eslint-disable-next-line max-classes-per-file
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  add,
  sub,
  isValid,
} from 'date-fns';
import {
  format,
} from 'date-fns-tz';

import {
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import DateFnsUtils from '@date-io/date-fns';
import {
  // KeyboardDateTimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  getLocale,
  getTimezoneOffsetCorrection,
} from '../../utils/date';

// const getUtils = (timezone) => class LocalizedUtils extends DateFnsUtils {
//   constructor(options) {
//     super(options);
//     this.timezone = timezone;
//   }

//   getDatePickerHeaderText(date) {
//     return format(date, 'yyy/MM/dd HH:mm zzz', { timeZone: this.timezone });
//     // return format(date,
// 'yyyy/MM/dd HH:mm"', { locale: this.locale, timeZone: this.timezone });
//   }
// };

// class DCLocalizedUtils extends DateFnsUtils {
//   constructor(options) {
//     super(options);
//     this.locale = options.locale.locale;
//     this.timezone = options.locale.timezone;
//   }

//   dcfn(date) {
//     return format(date, 'd MMM yyyy', { timeZone: this.timezone, locale: this.locale });
//   }

//   getDatePickerHeaderText(date) {
//     return this.dcfn(date);
//   }

//   getDateTimePickerHeaderText(date) {
//     return this.dcfn(date);
//   }
// }

const DeviceMeasuresFilter = (props) => {
  const {
    from,
    to,
    step,
    onFormSubmit,
    timezone,
  } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;

  const SCROLL_STEPS_ENUM = [
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year',
  ];

  const [startRange, setStartRange] = React.useState(from);
  const [endRange, setEndRange] = React.useState(to);
  const [scrollStep, setScrollStep] = React.useState(step);
  const period = 'select';
  const correctionOffset = React.useMemo(
    () => getTimezoneOffsetCorrection(timezone), [timezone],
  );

  const handleFormSubmitWithValues = (start, end) => {
    onFormSubmit({
      from: start,
      to: end,
      step: scrollStep,
    });
  };

  const handleFormSubmit = () => {
    const newFilter = {
      from: startRange,
      to: endRange,
      step: scrollStep,
    };
    if (newFilter.to && newFilter.from) {
      onFormSubmit(newFilter);
    }
  };

  const handleSelectStep = (event) => {
    const { value } = event.target;
    setScrollStep(value);
  };

  const handleMoveByStep = (increment) => {
    let start = startRange;
    let end = endRange;
    switch (scrollStep) {
      case ('minute'): {
        start = add(start, { minutes: increment });
        end = add(end, { minutes: increment });
        break;
      }
      case ('hour'): {
        start = add(start, { hours: increment });
        end = add(end, { hours: increment });
        break;
      }
      case ('day'): {
        start = add(start, { days: increment });
        end = add(end, { days: increment });
        break;
      }
      case ('week'): {
        start = add(start, { weeks: increment });
        end = add(end, { weeks: increment });
        break;
      }
      case ('month'): {
        start = add(start, { months: increment });
        end = add(end, { months: increment });
        break;
      }
      case ('year'): {
        start = add(start, { years: increment });
        end = add(end, { years: increment });
        break;
      }
      // case ('offset'): {
      //   start = add(start, { seconds: correctionOffset });
      //   end = add(end, { seconds: correctionOffset });
      //   break;
      // }
      default: {
        start = startRange;
        end = endRange;
        break;
      }
    }

    setStartRange(start);
    setEndRange(end);
    handleFormSubmitWithValues(start, end);
  };

  const handleSelectPeriod = async (event) => {
    const NOW = new Date();
    if (event
      && event.target
      && event.target.value
    ) {
      const { value } = event.target;
      let start;
      let end;
      switch (value) {
        case ('today'): {
          start = startOfDay(NOW);
          end = endOfDay(NOW);
          break;
        }
        case ('lastHour'): {
          start = sub(NOW, { hours: 1 });
          end = NOW;
          break;
        }
        case ('thisWeek'): {
          start = startOfWeek(NOW);
          end = endOfWeek(NOW);
          break;
        }
        case ('thisMonth'): {
          start = startOfMonth(NOW);
          end = endOfMonth(NOW);
          break;
        }
        case ('thisYear'): {
          start = startOfYear(NOW);
          end = endOfYear(NOW);
          break;
        }
        default: {
          start = startRange;
          end = endRange;
          break;
        }
      }
      if (value !== 'lastHour') {
        start = sub(start, { seconds: correctionOffset });
        end = sub(end, { seconds: correctionOffset });
      }

      setStartRange(start);
      setEndRange(end);
      handleFormSubmitWithValues(start, end);
    }
  };

  const deviceTimezoneStart = React.useMemo(() => {
    if (isValid(startRange)) {
      return format(
        add(startRange, { seconds: correctionOffset }),
        'yyyy/MM/dd HH:mm', // 'PPp',
        { timeZone: timezone, locale: getLocale(locale) },
      );
    }
    return '---';
  }, [startRange, correctionOffset, timezone, locale]);

  const deviceTimezoneEnd = React.useMemo(() => {
    if (isValid(endRange)) {
      return format(
        add(endRange, { seconds: correctionOffset }),
        'yyyy/MM/dd HH:mm', // 'PPp',
        { timeZone: timezone, locale: getLocale(locale) },
      );
    }
    return '---';
  }, [endRange, correctionOffset, timezone, locale]);

  return (
    <Grid container spacing={4} direction="row" justify="center" alignItems="center" style={{ paddingTop: 15 }}>
      <Grid item>
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => handleMoveByStep(-1)}
            style={{ height: 30, width: 10 }}
            startIcon={<SkipPreviousIcon />}
          />

          <Select
            labelId="select-scroll-step"
            id="scrollStep"
            value={scrollStep}
            onChange={handleSelectStep}
            variant="outlined"
            style={{ height: 30 }}
          >
            {SCROLL_STEPS_ENUM.map((singleStep) => (
              <MenuItem value={singleStep} key={singleStep}>{t(`common.${singleStep}`)}</MenuItem>
            ))}
          </Select>

          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => handleMoveByStep(1)}
            style={{ height: 30, width: 10 }}
            endIcon={<SkipNextIcon />}
          />
        </Grid>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          // utils={getUtils(timezone)}
          locale={getLocale(locale)}
          // utils={DCLocalizedUtils}
          // locale={{
          //   locale: getLocale(locale),
          //   timezone,
          // }}
        >
          <DateTimePicker
            style={{ height: 30 }}
            value={startRange}
            onChange={setStartRange}
            showTodayButton
            todayLabel={t('common.today')}
            okLabel={t('common.ok')}
            cancelLabel={t('common.cancel')}
            ampm={false}
            format="yyyy/MM/dd HH:mm"
          />
          <DateTimePicker
            value={endRange}
            onChange={setEndRange}
            showTodayButton
            todayLabel={t('common.today')}
            okLabel={t('common.ok')}
            cancelLabel={t('common.cancel')}
            ampm={false}
            format="yyyy/MM/dd HH:mm"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <Select
          labelId="select-default-range"
          id="select-default-range"
          value={period}
          onChange={handleSelectPeriod}
          style={{ height: 30 }}
          variant="outlined"
        >
          <MenuItem value="select" key="default"><AppsIcon /></MenuItem>
          <MenuItem value="today" key="today">{t('common.today')}</MenuItem>
          <MenuItem value="lastHour" key="lastHour">{t('common.lastHour')}</MenuItem>
          <MenuItem value="thisWeek" key="thisWeek">{t('common.thisWeek')}</MenuItem>
          <MenuItem value="thisMonth" key="thisMonth">{t('common.thisMonth')}</MenuItem>
          <MenuItem value="thisYear" key="thisYear">{t('common.thisYear')}</MenuItem>
        </Select>
      </Grid>
      <Grid item>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ height: 30, width: 120 }}
          onClick={handleFormSubmit}
        >
          <Typography>{t('common.send')}</Typography>
        </Button>
      </Grid>
      {correctionOffset !== 0 && (
        <Grid container spacing={4} direction="row" justify="center" alignItems="center">
          <Grid item>
            <Typography>{deviceTimezoneStart}</Typography>
          </Grid>
          <Grid item>
            <Typography>{deviceTimezoneEnd}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DeviceMeasuresFilter.propTypes = {
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  step: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default DeviceMeasuresFilter;
