import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';

import {
  Loader,
  GraphQLErrorWrapper,
  SuccessSnackBar,
} from '../../generic';
import GroupInviteForm from './GroupInviteForm';

import {
  GROUP_QUERY,
} from '../../group/queries';
import {
  GROUP_INVITE_CREATE_MUTATION,
} from '../queries';

const GroupInviteCreateModal = (props) => {
  const {
    group,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);

  const [
    groupInviteCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_INVITE_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (values) => {
    try {
      const result = await groupInviteCreateMutationAction({
        variables: {
          input: {
            groupId: group.id,
            ...values,
          },
        },
      });
      if (result) setSuccessOpen(true);
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
      <Dialog
        fullWidth
        maxWidth="md"
        open={isModalOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('groupInvites.create.title')}</DialogTitle>
        <DialogContent>
          {error && (
            <GraphQLErrorWrapper error={error} />
          )}
          <Loader loading={loading} />
          <GroupInviteForm
            onFormSubmit={handleFormSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

GroupInviteCreateModal.propTypes = propTypes;

export default GroupInviteCreateModal;
