import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Button,
  Link,
  Grid,
} from '@material-ui/core';
import {
  Update,
} from '@material-ui/icons';
import {
  formatDistanceToNowLocale,
  getTimezoneLabelWithOffset,
} from '../../utils/date';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
  BatteryLevelIcon,
  Loader,
} from '../../generic';

import AllDevicesListSearcher from './AllDevicesListSearcher';
import { AuthenticationContext } from '../../authentication';

import {
  DEVICES_QUERY,
} from '../queries';

const AllDevicesList = (props) => {
  const { pageSize, filter } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;

  const [search, setSearch] = React.useState('');
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(DEVICES_QUERY, {
    variables: {
      pageSize,
      filter: {
        ...filter,
        search,
      },
    },
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { devices: devicesConnection } = viewer;
      const { pageInfo, edges } = devicesConnection;
      return {
        devices: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      devices: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const { devices, pageInfo } = queryResult;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.devices;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.devices;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              devices: {
                ...previousResult.viewer.devices,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }

  return (
    <>
      <CardWrapper
        cardTitle={t('allDevices.list.title')}
        cardSubtitle={t('allDevices.list.subtitle')}
        cardBody={
          (
            <>
              <AllDevicesListSearcher
                onSearch={(text) => setSearch(text)}
              />
              <CustomTable
                loading={loading}
                noDataText={t('allDevices.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  ' ',
                  t('common.devID'),
                  t('common.devEUI'),
                  iAmAdmin && t('common.appID'),
                  t('common.group'),
                  t('common.serialNumber'),
                  t('common.model'),
                  t('common.userCode'),
                  t('common.firmwareVersion'),
                  t('common.firmwareDate'),
                  t('common.rssi'),
                  t('common.lastBatteryLevel'),
                  t('common.lastActivityTimestamp'),
                  t('common.timezone'),
                ]}
                tableData={devices.map((device, index) => ([
                  index + 1,
                  device.devID,
                  device.devEUI,
                  ((iAmAdmin && device.application) && (
                    <Link style={{ cursor: 'pointer' }} color="primary" href={`/applications/${device.application.id}/devices/${device.id}`}>
                      <Typography style={device.application.deletedAt ? { textDecoration: 'line-through' } : {}}>
                        {device.application.appID}
                      </Typography>
                    </Link>
                  )),
                  (device.group ? (
                    <Link style={{ cursor: 'pointer' }} color="primary" href={`/groups/${device.group.id}/devices/${device.id}`}>
                      <Typography>
                        {device.group.name}
                      </Typography>
                    </Link>
                  ) : ''),
                  device.serialNumber,
                  device.model,
                  device.userCode,
                  device.firmwareVersion,
                  device.firmwareDate,
                  device.rssi,
                  (
                    <Grid container justify="center">
                      <BatteryLevelIcon level={device.lastBatteryLevel} />
                    </Grid>
                  ),
                  formatDistanceToNowLocale(device.lastActivityTimestamp, locale),
                  getTimezoneLabelWithOffset(device.timezone),
                ]))}
              />
            </>
          )
        }
        cardFooter={(pageInfo && pageInfo.hasNextPage)
          && (
          <Button
            fullWidth
            onClick={() => handleLoadMore()}
            color="primary"
            variant="contained"
            startIcon={<Update />}
          >
              {t('common.loadMore')}
          </Button>
          )}
      />
      <Loader loading={loading} />
    </>
  );
};

AllDevicesList.propTypes = {
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

AllDevicesList.defaultProps = {
  pageSize: 20,
  filter: {},
};

export default AllDevicesList;
