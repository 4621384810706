/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import {
  Paper,
  TableCell,
  Typography,
  Grid,
} from '@material-ui/core';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';

import {
  CardWrapper,
  Loader,
} from '../../generic';

const DeviceMeasuresTable = (props) => {
  const { t } = useTranslation('translations');
  const {
    columnsHeaders,
    measuresRows,
    downloadFilename,
  } = props;
  const rowHeight = 40;
  const headerHeight = 100;

  const [generatingCSV, setGeneratingCSV] = React.useState(false);

  const columns = React.useMemo(() => (
    columnsHeaders.map((ch, index) => {
      let width = 150;
      if ([0].includes(index)) width = 200;
      if ([1, 2].includes(index)) width = 60;

      return {
        ...ch,
        width,
        label: ch.labelMeasureType,
        dataKey: ch.channel ? ch.channel.toString() : 'timestamp',
      };
    })
  ), [columnsHeaders]);

  const cellRenderer = ({ cellData, columnIndex }) => (
    <TableCell
      component="div"
      variant="body"
      style={{
        height: rowHeight,
        display: 'flex',
        boxSizing: 'border-box',
        flex: 1,
      }}
      align={columnIndex === 0 ? 'left' : 'right'}
    >
      <Typography
        align={columnIndex === 0 ? 'left' : 'right'}
        color={(
          typeof cellData === 'string'
          && ['Underflow', 'Not init', 'Overflow', 'Error'].includes(cellData)
        )
          ? 'error'
          : 'inherit'}
      >
        {cellData}
      </Typography>
    </TableCell>
  );

  const headerRenderer = ({
    labelChannel, labelMeasureType, labelUnitOfMeasure, columnIndex,
  }) => (
    <TableCell
      component="div"
      variant="head"
      style={{
        display: 'block',
        height: headerHeight,
        justify: 'flex-end',
        alignItems: 'flex-end',
        textTransform: 'none !important',
      }}
      align={columnIndex === 0 ? 'left' : 'right'}
    >
      {columnIndex === 0
        ? (
          <Grid container justify="flex-start" alignItems="flex-start" direction="column">
            {labelMeasureType && (
              <Typography align="left">{labelMeasureType}</Typography>
            )}
          </Grid>
        )
        : (
          <Grid container justify="flex-start" alignItems="flex-end" direction="column">
            {labelChannel && (
              <Typography align={columnIndex === 0 ? 'left' : 'right'}>{labelChannel}</Typography>
            )}
            {labelMeasureType && (
              <Typography align="right" style={{ textTransform: 'none' }}>{labelMeasureType}</Typography>
            )}
            {labelUnitOfMeasure && (
              <Typography align="right" style={{ textTransform: 'none' }}>{labelUnitOfMeasure}</Typography>
            )}
          </Grid>
        )}
    </TableCell>
  );

  const MIN_TABLE_WIDTH = columns.length * 150;

  const generateCSV = async () => {
    if (columnsHeaders
      && columnsHeaders.length > 0
    ) {
      const activeChannelsCounter = (columnsHeaders.length - 3) || 0;
      const activeChannels = [];
      for (let i = 1; i <= activeChannelsCounter; i += 1) {
        activeChannels.push(i);
      }
      const sheetData = [
        columnsHeaders.map((ch, index) => (
          index === 0
            ? ch.labelMeasureType
            : `${ch.labelChannel ? `${ch.labelChannel} ` : ''}${ch.labelMeasureType} ${ch.labelUnitOfMeasure} `
        )),
        ...measuresRows.map((measRow) => (
          [
            measRow.timestamp,
            measRow[2000],
            measRow[2001],
            ...[...activeChannels].map((iter) => measRow[iter]),
          ]
        )),
      ];
      try {
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
        const outputFileName = downloadFilename;
        const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, outputFileName);
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    setGeneratingCSV(false);
    return true;
  };

  const handleDownloadCSV = async () => {
    setGeneratingCSV(true);
    await generateCSV();
  };

  if (generatingCSV) {
    return (<Loader loading={generatingCSV} />);
  }

  return (
    <CardWrapper
      cardTitle={t('common.measures')}
      cardHeaderAction={handleDownloadCSV}
      cardHeaderIcon="download"
      cardBody={
        (
          <Paper style={{ height: 600, width: '100%', overflow: 'auto' }}>
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  height={height}
                  width={width < MIN_TABLE_WIDTH ? MIN_TABLE_WIDTH : width}
                  rowHeight={rowHeight}
                  headerHeight={headerHeight}
                  rowCount={measuresRows.length}
                  rowGetter={({ index }) => measuresRows[index]}
                >
                  {columns.map((column, index) => (
                    <Column
                      width={column.width}
                      label={column.label}
                      dataKey={column.dataKey}
                      key={column.dataKey}
                      headerRenderer={(headerProps) => headerRenderer({
                        ...headerProps,
                        labelChannel: column.labelChannel,
                        labelMeasureType: column.labelMeasureType,
                        labelUnitOfMeasure: column.labelUnitOfMeasure,
                        columnIndex: index,
                      })}
                      cellRenderer={(cellProps) => cellRenderer({
                        ...cellProps,
                        columnIndex: index,
                      })}
                    />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </Paper>
        )
      }
    />
  );
};

DeviceMeasuresTable.propTypes = {
  columnsHeaders: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  measuresRows: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  downloadFilename: PropTypes.string,
};

DeviceMeasuresTable.defaultProps = {
  downloadFilename: 'downloadData.csv',
};

export default DeviceMeasuresTable;
