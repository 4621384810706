import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import {
  GroupEditCard,
} from '../components';

const GroupGeneralTab = (props) => {
  const { group, canEdit } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {canEdit ? (
          <GroupEditCard
            group={group}
          />
        ) : (
          <Typography variant="h5" component="h5" gutterBottom>
            {group.name}
          </Typography>
        )}
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

GroupGeneralTab.propTypes = propTypes;

export default GroupGeneralTab;
