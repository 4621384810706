import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import {
  CustomButton,
} from '../../generic';

import {
  AuthenticationContext,
} from '../../authentication/AuthenticationContext.jsx';

const DeviceChannels = (props) => {
  const {
    device,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');
  const [editModalChannelNumber, setEditModalChannelNumber] = React.useState(null);
  const [temporaryNameOverride, setTemporaryNameOverride] = React.useState('');
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const handleOpenEditDialog = (measureChannel, nameOverride) => {
    setTemporaryNameOverride(nameOverride);
    setEditModalChannelNumber(measureChannel);
  };

  const handleChangeText = (event) => {
    const { value } = event.target;
    setTemporaryNameOverride(value);
  };

  const handleFormSubmit = () => {
    const values = {
      nameOverride: temporaryNameOverride === '' ? null : temporaryNameOverride,
      measureChannel: editModalChannelNumber,
    };
    onFormSubmit(values);
    setEditModalChannelNumber(null);
  };

  const { measureChannels } = device;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography>{t('common.CH')}</Typography></TableCell>
                <TableCell><Typography>{t('common.measureType')}</Typography></TableCell>
                {iAmAdmin && (
                  <TableCell><Typography>_</Typography></TableCell>
                )}
                <TableCell><Typography>{t('common.unitOfMeasure')}</Typography></TableCell>
                <TableCell align="right"><Typography>{t('common.qualifier')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                measureChannels
                  .filter((row) => row.channel <= 12)
                  .map((row) => (
                    <TableRow key={row.channel} selected={row.measureType === null || row.measureType === 'notImplemented'}>
                      <TableCell component="th" scope="row">
                        <Typography>{row.channel <= 12 ? row.channel : ' '}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container direction="row" alignItems="center">
                          <Typography style={row.nameOverride ? { textDecoration: 'underline', fontWeight: 'bold' } : {}}>
                            {row.nameOverride || row.userCode || (row.measureType && t(`enums.measureTypes.${row.measureType}`))}
                          </Typography>
                          {(row.measureType !== null && row.measureType !== 'notImplemented') && (
                            <IconButton
                              color="primary"
                              onClick={() => handleOpenEditDialog(row.channel, row.nameOverride)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          )}
                        </Grid>
                      </TableCell>
                      {iAmAdmin && (
                        <TableCell>
                          <Typography>
                            {(row.measureTypeNumber || row.measureTypeNumber === 0)
                            && `${row.measureTypeNumber}.${t(`enums.measureTypes.${row.measureType}`)}`}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography>{row.unitOfMeasure || ''}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography>{row.qualifier ? t(`enums.qualifiers.${row.qualifier}`) : ''}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={editModalChannelNumber !== null}
        onClose={() => setEditModalChannelNumber(null)}
      >
        <DialogTitle id="draggable-dialog-title">
          {t('common.overrideChannelName')}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t('common.nameOverride')}
            placeholder={t('common.nameOverride')}
            fullWidth
            required
            autoFocus
            value={temporaryNameOverride || ''}
            onChange={handleChangeText}
            autoCapitalize="none"
          />
        </DialogContent>
        <DialogActions>
          <CustomButton autoFocus onClick={() => setEditModalChannelNumber(null)} color="info">
            {t('common.cancel')}
          </CustomButton>
          <CustomButton
            onClick={() => handleFormSubmit()}
            color="primary"
          >
            {t('common.ok')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

DeviceChannels.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

DeviceChannels.defaultProps = {
  device: {
    deviceChannels: [
      {}, {}, {}, {}, {}, {},
      {}, {}, {}, {}, {}, {},
    ],
  },
};

export default DeviceChannels;
