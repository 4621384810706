import { gql } from '@apollo/client';

export const DEVICE_FRAGMENT_WITH_COMMAND_COMMUNICATIONS = gql`
  fragment DeviceWithCommandCommunicationsFragment on Device {
    id
    timezone
    groupName
    userCode
    RFLogInterval
    measureInterval
    buzzerAlarm
    cyclicLogging
    loggingActive
    timeSynchronization
    protocolType
    commandCommunications(filter: $commandCommunicationFilter)
    pendingCommands
  }
`;

export const COMMAND_COMMUNICATION_FRAGMENT = gql`
  fragment CommandCommunicationFragment on CommandCommunication {
    id
    timestamp
    counter
    port
    payload
    payloadRaw
    payloadHex
    isAck
    isHex
    idConfirmed
    isReceived
    enqueue
  }
`;

export const GROUP_DEVICE_COMMAND_COMMUNICATIONS_QUERY = gql`
  query groupDeviceCommandCommunicationsQuery($groupId: ID!, $id: ID!, $commandCommunicationFilter: CommandCommunicationFilter!) {
    viewer {
      group(id: $groupId) {
        device(id: $id) {
          ...DeviceWithCommandCommunicationsFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT_WITH_COMMAND_COMMUNICATIONS}
`;

export const APPLICATION_DEVICE_COMMAND_COMMUNICATIONS_QUERY = gql`
  query applicationDeviceCommandCommunicationsQuery($applicationId: ID!, $id: ID!, $commandCommunicationFilter: CommandCommunicationFilter!) {
    viewer {
      application(id: $applicationId) {
        device(id: $id) {
          ...DeviceWithCommandCommunicationsFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT_WITH_COMMAND_COMMUNICATIONS}
`;

export const DEVICE_SEND_COMMAND_MUTATION = gql`
  mutation DeviceSendCommandMutation($input: DeviceSendCommandInput!) {
    deviceSendCommand(input: $input) {
      device {
        id
        timezone
        groupName
        userCode
        pendingCommands
      }
    }
  }
`;
