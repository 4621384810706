import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GroupForm } from './components';

import {
  CardWrapper,
  GridItem,
  GridContainer,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../generic';

import { GROUPS_QUERY, GROUP_CREATE_MUTATION } from './queries';

const GroupCreatePage = (props) => {
  const { t } = useTranslation('translations');
  const navigate = useNavigate();
  const { pageSize, filter } = props;

  const [successOpen, setSuccessOpen] = React.useState(false);

  const [
    groupCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUPS_QUERY,
          variables: {
            pageSize,
            filter,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (data) => {
    try {
      const result = await groupCreateMutationAction({
        variables: {
          input: {
            ...data,
          },
        },
      });
      if (result) setSuccessOpen(true);
      navigate('/groups', { replace: true });
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
        <GraphQLErrorWrapper error={error} />
        <Loader loading={loading} />
        <CardWrapper
          cardTitle={t('groups.create.title')}
          cardBody={
            <GroupForm group={null} onFormSubmit={handleFormSubmit} />
          }
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
  pageSize: 20,
  filter: {},
};

GroupCreatePage.propTypes = propTypes;

GroupCreatePage.defaultProps = defaultProps;

export default GroupCreatePage;
