const SEC120 = 7; // 2 min
const SEC300 = 8; // 5 min
const SEC600 = 9; // 10 min
const SEC900 = 10; // 15 min
const SEC1800 = 11; // 30 min
const SEC3600 = 12; // 1 h
const SEC43200 = 13; // 12 h
const SEC86400 = 14; // 24 H

export default {
  SEC120,
  SEC300,
  SEC600,
  SEC900,
  SEC1800,
  SEC3600,
  SEC43200,
  SEC86400,
};
