import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Grid,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GROUP_ROLES } from '../../enum';

import { clearTypenames } from '../../utils/graphql';

const GroupInviteForm = (props) => {
  const {
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      role: 'GROUP_VIEWER',
    },
  });

  const handleFormSubmit = (values) => {
    const filteredValues = {
      ...values,
      roles: [values.role],
    };
    delete filteredValues.role;
    onFormSubmit(clearTypenames(filteredValues));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            as={(
              <TextField
                label={t('common.email')}
                placeholder={t('common.email')}
                error={errors.email}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
              />
          )}
            id="email"
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>{t('common.roles')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="role"
                  id="role"
                  variant="outlined"
                >
                  {Object.keys(GROUP_ROLES).map((key) => (
                    <MenuItem key={GROUP_ROLES[key]} value={GROUP_ROLES[key]}>
                      {t(`enums.groupRoles.${GROUP_ROLES[key]}`)}
                    </MenuItem>
                  ))}
                  {/* // <Select
                  //   labelId="roles-label"
                  //   multiple
                  //   fullWidth
                  //   required
                  //   value={roles}
                  //   onChange={handleChange}
                  //   input={<Input id="roles-chip" />}
                  //   renderValue={(selected) => (
                  //     <div className={{ display: 'flex', flexWrap: 'wrap' }}>
                  //       {selected.map((value) => (
                  //         <Chip
                  //           key={value}
                  //           label={t(`enums.groupRoles.${GROUP_ROLES[value]}`)}
                  //           style={{ margin: 2 }}
                  //         />
                  //    ))}
                  //     </div>
                  //   )}
                  //   MenuProps={MenuProps}
                  // > */}
                </Select>
              )}
              id="role"
              name="role"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

GroupInviteForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default GroupInviteForm;
