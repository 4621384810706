import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
// import WindowedSelect from 'react-windowed-select';

import { Controller } from 'react-hook-form';
import {
  getTimezoneLabelWithOffset,
} from '../utils/date';

import {
  timezonesNumeric,
  timezonesByCities,
} from '../enum/timezones';

const timezoneOptions = [
  ...timezonesNumeric,
  ...timezonesByCities,
];

const TimezoneSelectorOptimized = (props) => {
  const {
    id,
    name,
    control,
  } = props;
  const { t } = useTranslation('translations');

  return (
    <div style={{ minWidth: '100%', marginTop: 10 }}>
      <FormControl
        required
        style={{ minWidth: '100%' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <InputLabel
          style={{ minWidth: '100%' }}
          id={id}
        >
          {t('common.timezone')}
        </InputLabel>
        <Controller
          as={(
            // <WindowedSelect
            //   options={timezoneOptions}
            //   windowThreshold={20}
            // />
            <Select>
              <MenuItem key="none" value="">
                <em> </em>
              </MenuItem>
              {timezoneOptions.map((option) => (
                <MenuItem
                  value={option}
                  key={option}
                >
                  {getTimezoneLabelWithOffset(option)}
                </MenuItem>
              ))}
            </Select>
          )}
          name={name}
          control={control}
          defaultValue=""
        />
      </FormControl>
    </div>
  );
};

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line
  control: PropTypes.object,
};

TimezoneSelectorOptimized.propTypes = propTypes;

export default TimezoneSelectorOptimized;
