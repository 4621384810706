import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';

import {
  // IconButton,
  // Grid,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import {
  // Edit,
  Update,
} from '@material-ui/icons';
import {
  getTimezoneLabelWithOffset,
} from '../../utils/date';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
} from '../../generic';

import ApplicationSearcher from './ApplicationSearcher';

import {
  APPLICATIONS_QUERY,
} from '../queries';

const ApplicationsList = (props) => {
  const { pageSize, filter } = props;
  const { t } = useTranslation('translations');

  const [search, setSearch] = React.useState('');

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(APPLICATIONS_QUERY, {
    variables: {
      pageSize,
      filter: {
        ...filter,
        search,
      },
    },
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { applications: applicationsConnection } = viewer;
      const { pageInfo, edges } = applicationsConnection;
      return {
        applications: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      applications: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const { applications, pageInfo } = queryResult;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.applications;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.applications;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              applications: {
                ...previousResult.viewer.applications,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }

  return (
    <>
      <CardWrapper
        cardTitle={t('applications.list.title')}
        cardSubtitle={t('applications.list.subtitle')}
        cardBody={
          (
            <>
              <ApplicationSearcher
                onSearch={setSearch}
              />
              <CustomTable
                loading={loading}
                noDataText={t('applications.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  ' ',
                  t('common.appID'),
                  t('common.appEUI'),
                  t('common.description'),
                  t('common.timezone'),
                ]}
                tableData={applications.map((application, index) => ([
                  index + 1,
                  (
                    <Link style={{ cursor: 'pointer' }} color="primary" href={`/applications/${application.id}`}>
                      <Typography style={application.deletedAt ? { textDecoration: 'line-through' } : {}}>
                        {application.appID}
                      </Typography>
                    </Link>
                  ),
                  application.appEUI,
                  application.description,
                  getTimezoneLabelWithOffset(application.timezone),
                ]))}
              />
            </>
          )
        }
        cardFooter={(pageInfo && pageInfo.hasNextPage)
          && (
            <Button
              fullWidth
              onClick={() => handleLoadMore()}
              color="primary"
              variant="contained"
              startIcon={<Update />}
            >
              {t('common.loadMore')}
            </Button>
          )}
      />
    </>
  );
};

ApplicationsList.propTypes = {
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

ApplicationsList.defaultProps = {
  pageSize: 20,
  filter: {},
};

export default ApplicationsList;
