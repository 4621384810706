import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
} from '@material-ui/core';
import {
  Battery20,
  Battery50,
  BatteryChargingFull,
  Power,
  BatteryFull,
  BatteryUnknown,
} from '@material-ui/icons';

const BatteryLevelIcon = (props) => {
  const {
    level,
    fontSize,
  } = props;
  const { t } = useTranslation('translations');

  const batteryIcon = React.useMemo(() => {
    let icon;
    switch (level) {
      case 0:
        icon = <Battery20 fontSize={fontSize} />;
        break;
      case 1:
        icon = <Battery50 fontSize={fontSize} />;
        break;
      case 2:
        icon = <BatteryFull fontSize={fontSize} />;
        break;
      case 3:
        icon = <BatteryChargingFull fontSize={fontSize} />;
        break;
      case 4:
        icon = <Power fontSize={fontSize} />;
        break;
      default:
        icon = <BatteryUnknown fontSize={fontSize} />;
        break;
    }
    return icon;
  }, [fontSize, level]);

  const batteryDescription = React.useMemo(() => (
    (level || level === 0) ? t(`enums.batteryLevels.level${level}`) : ''
  ), [level, t]);

  return (
    <Tooltip title={batteryDescription} aria-label={batteryDescription}>
      {batteryIcon}
    </Tooltip>
  );
};

const propTypes = {
  level: PropTypes.number,
  fontSize: PropTypes.string,
};

const defaultProps = {
  level: null,
  fontSize: 'large',
};

BatteryLevelIcon.propTypes = propTypes;
BatteryLevelIcon.defaultProps = defaultProps;

export default BatteryLevelIcon;
