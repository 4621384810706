import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-dashboard-react/components/cardIconStyle.js';

const useStyles = makeStyles(styles);

const CardIcon = (props) => {
  const classes = useStyles();

  const {
    className, children, color, ...rest
  } = props;

  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[`${color}CardHeader`]]: color,
    [className]: className !== undefined,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
};

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
  ]),
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  color: 'primary',
  className: undefined,
};

CardIcon.propTypes = propTypes;
CardIcon.defaultProps = defaultProps;

export default CardIcon;
