import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
  CustomButton,
} from '../../generic';

import {
  FTPExportIntegrationForm,
  FTPExportIntegrationTestingCard,
} from '../../exportIntegration/components';

import {
  APPLICATION_QUERY,
  APPLICATION_UPDATE_FTP_EXPORT_INTEGRATION_MUTATION,
  APPLICATION_PROPAGATE_FTP_EXPORT_INTEGRATION_MUTATION,
} from '../queries';

const FTPExportIntegrationEditCard = (props) => {
  const {
    application,
  } = props;

  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [askConfirmPropagateVisible, setAskConfirmPropagateVisible] = React.useState(false);
  const [overrideGroupSetting, setOverrideGroupSetting] = React.useState(false);

  const [
    applicationUpdateFTPExportIntegrationMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(APPLICATION_UPDATE_FTP_EXPORT_INTEGRATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: APPLICATION_QUERY,
          variables: {
            id: application.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    applicationPropagateFTPExportIntegrationMutationAction,
    {
      loading: loadingPropagate,
      error: errorPropagate,
    },
  ] = useMutation(APPLICATION_PROPAGATE_FTP_EXPORT_INTEGRATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: APPLICATION_QUERY,
          variables: {
            id: application.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (data) => {
    try {
      const result = await applicationUpdateFTPExportIntegrationMutationAction({
        variables: {
          input: {
            id: application.id,
            ...data,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handlePropagate = async (passedOverrideGroupSetting) => {
    try {
      const result = await applicationPropagateFTPExportIntegrationMutationAction({
        variables: {
          input: {
            id: application.id,
            overrideGroupSetting: passedOverrideGroupSetting,
          },
        },
      });
      if (result) setSuccessOpen(true);
      setAskConfirmPropagateVisible(false);
      setOverrideGroupSetting(false);
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <>
      <CardWrapper
        cardTitle={t('exportIntegrations.ftp.edit.title')}
        cardBody={
          (
            <>
              <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
              <GraphQLErrorWrapper error={error || errorPropagate} />
              <Loader loading={loading || loadingPropagate} />
              <FTPExportIntegrationForm
                ftpExportIntegration={application.ftpExportIntegration}
                onFormSubmit={handleFormSubmit}
              />
              <div style={{ width: '100%', marginTop: 20 }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => setAskConfirmPropagateVisible(true)}
                >
                  <Typography>{t('common.propagateConfig')}</Typography>
                </Button>
                <Dialog
                  fullWidth
                  maxWidth="sm"
                  open={askConfirmPropagateVisible}
                  onClose={() => setAskConfirmPropagateVisible(false)}
                >
                  <DialogTitle id="draggable-dialog-title">
                    {t('common.confirmPropagateConfig')}
                  </DialogTitle>
                  <DialogContent>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={overrideGroupSetting}
                          onChange={() => setOverrideGroupSetting(!overrideGroupSetting)}
                          name="overrideGroupSetting"
                          color="primary"
                        />
                      )}
                      label={
                        overrideGroupSetting
                          ? t('common.overrideGroupSetting')
                          : t('common.notOverrideGroupSetting')
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <CustomButton autoFocus onClick={() => setAskConfirmPropagateVisible(false)} color="info">
                      {t('common.cancel')}
                    </CustomButton>
                    <CustomButton onClick={() => handlePropagate(overrideGroupSetting)} color="primary">
                      {t('common.ok')}
                    </CustomButton>
                  </DialogActions>
                </Dialog>
              </div>

            </>
          )
        }
      />
      <FTPExportIntegrationTestingCard
        id={application.id}
        timezone={application.timezone}
      />
    </>
  );
};

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
    timezone: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    ftpExportIntegration: PropTypes.object,
  }).isRequired,
};

FTPExportIntegrationEditCard.propTypes = propTypes;

export default FTPExportIntegrationEditCard;
