import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  // Poll,
  Apps,
} from '@material-ui/icons';

import {
  MessageTextClockOutline,
} from 'mdi-material-ui';

import {
  GridContainer,
  CustomTabs,
} from '../../generic';

import {
  ConfigurationsTab,
  TerminalTab,
} from '../../remoteConfigurations/tabs';

const DeviceRemoteConfigurationsTab = (props) => {
  const { t } = useTranslation('translations');
  const { device } = props;

  return (
    <GridContainer>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('devices.remoteConfigurationsTabs.configurations.title'),
            tabIcon: Apps,
            tabContent: (
              <ConfigurationsTab
                device={device}
              />
            ),
          },
          {
            tabName: t('devices.remoteConfigurationsTabs.terminal.title'),
            tabIcon: MessageTextClockOutline,
            tabContent: (
              <TerminalTab
                device={device}
              />
            ),
          },
        ]}
      />
    </GridContainer>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
  // canEdit: PropTypes.bool.isRequired,
};

DeviceRemoteConfigurationsTab.propTypes = propTypes;
export default DeviceRemoteConfigurationsTab;
