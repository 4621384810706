import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  sub, add, // startOfDay, endOfDay,
} from 'date-fns';

import {
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CommandGenericFormRow,
  CommandCommunicationsTable,
  CommandCommunicationsFilter,
} from '.';
import {
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import { GroupContext } from '../../group/GroupContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import {
  GROUP_DEVICE_COMMAND_COMMUNICATIONS_QUERY,
  APPLICATION_DEVICE_COMMAND_COMMUNICATIONS_QUERY,
  DEVICE_SEND_COMMAND_MUTATION,
} from '../queries';
import {
  formatLocale,
  getTimezoneOffsetCorrection,
} from '../../utils/date';

const TerminalCard = (props) => {
  const { device } = props;
  const { timezone } = device;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { deviceId } = useParams();
  const [successOpen, setSuccessOpen] = React.useState(false);

  const correctionOffset = React.useMemo(() => getTimezoneOffsetCorrection(timezone), [timezone]);
  const NOW = new Date();

  const [filter, setFilter] = React.useState({
    from: sub(NOW, { days: 1 }),
    to: add(NOW, { days: 1 }),
    // to: sub(endOfDay(NOW), { seconds: correctionOffset }),
    step: 'day',
  });

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICE_COMMAND_COMMUNICATIONS_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICE_COMMAND_COMMUNICATIONS_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return {
        groupId: group.id,
        id: deviceId,
        commandCommunicationFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    if (application) {
      return {
        applicationId: application.id,
        id: deviceId,
        commandCommunicationFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    return null;
  }, [group, application, deviceId, filter.from, filter.to]);

  const {
    error,
    loading,
    data,
  } = useQuery(QUERY, {
    variables: queryVariables,
    pollInterval: 20000,
  });

  const queryResult = React.useMemo(() => {
    const columnsHeaders = [
      { label: 'timestamp' },
      { label: 'port' },
      { label: 'isReceived' },
      { label: 'payload' },
      { label: 'counter' },
    ];

    if (!loading && !error) {
      const { viewer } = data;
      const parent = viewer.application ? viewer.application : viewer.group;
      const { commandCommunications } = parent.device;

      return {
        columnsHeaders,
        rows: commandCommunications.map((cc) => {
          let { payload } = cc;

          payload = payload
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replaceAll(/[^ -~]+/g, '');

          if (cc.isAck) {
            payload = `${payload} (ACK REQ)`;
          }
          if (cc.enqueue) {
            payload = `${payload} [ENQUEUE]`;
          }
          if (cc.payloadHex) {
            payload = `${payload} [ ${cc.payloadHex.split(/(..)/g).filter((s) => s).join(' ')} ]`;
          }
          return ({
            ...cc,
            timestamp: formatLocale(new Date(cc.timestamp), 'yyyy/MM/dd HH:mm:ss', locale, timezone),
            isReceived: cc.isReceived || false, // ? '//&#9661;' : '/&#9651;',
            payload,
            counter: cc.counter || ' ',
          });
        }),
      };
    }
    return {
      columnsHeaders,
      rows: [],
    };
  }, [data, error, loading, locale, timezone]);

  const [
    parentSendCommandMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(DEVICE_SEND_COMMAND_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: QUERY,
          variables: queryVariables,
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (values) => {
    try {
      const result = await parentSendCommandMutationAction({
        variables: {
          input: {
            ...values,
            id: deviceId,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handleChangeFilter = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  if (error) {
    return <GraphQLErrorWrapper error={error || mutationError} />;
  }
  if (loading) {
    return (<Loader loading={loading || mutationLoading} />);
  }

  const { columnsHeaders, rows } = queryResult;

  return (
    <>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
      <Grid container spacing={2}>
        <CommandGenericFormRow onFormSubmit={handleFormSubmit} />
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: correctionOffset !== 0 ? 40 : 10 }}>
        <CommandCommunicationsFilter
          from={filter.from}
          to={filter.to}
          step={filter.step}
          timezone={timezone}
          onFormSubmit={(newFilter) => handleChangeFilter(newFilter)}
        />
      </Grid>
      {rows.length > 0
        ? (
          <CommandCommunicationsTable
            columnsHeaders={columnsHeaders}
            rows={rows}
            loading={loading}
          />
        )
        : (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="center">{t('common.commandCommunicationNoData')}</Typography>
            </Grid>
          </Grid>
        )}
    </>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

TerminalCard.propTypes = propTypes;

export default TerminalCard;
