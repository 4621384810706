import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loader = (props) => {
  const {
    text,
    loading,
  } = props;
  const classes = useStyles();

  if (!loading) {
    return null;
  }
  return (
    <div>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
        {text && <Typography color="inherit">{text}</Typography>}
      </Backdrop>
    </div>
  );
};

const propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
};

Loader.propTypes = propTypes;

const defaultProps = {
  loading: false,
  text: '',
};

Loader.defaultProps = defaultProps;

export default Loader;
