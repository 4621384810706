import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset',
  },
};

const useStyles = makeStyles(styles);

const GridContainer = (props) => {
  const classes = useStyles();
  const {
    children,
    ...rest
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
};

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridContainer;
