import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  FormControl,
  FormLabel,
  Checkbox,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

const CommandGenericFormRow = (props) => {
  const {
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    errors, control, handleSubmit,
  } = useForm();

  const handleFormSubmit = (values) => {
    const modifiedValues = {
      ...values,
      command: 'GENERIC',
      port: parseInt(values.port, 10),
    };
    onFormSubmit(modifiedValues);
  };

  return (
    <Paper style={{ width: 'inherit', padding: 10, margin: 10 }}>
      <form onSubmit={handleSubmit(handleFormSubmit)} id="GENERIC">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <Typography>{t('devices.commands.GENERIC')}</Typography>
          </Grid>
          <Grid item style={{ width: 80 }}>
            <Controller
              as={(
                <TextField
                  label={t('devices.commands.fPort')}
                  placeholder="100"
                  error={errors.param}
                  fullWidth
                  required
                  type="number"
                  autoCapitalize="none"
                />
              )}
              id="port"
              defaultValue="100"
              type="number"
              name="port"
              control={control}
            />
          </Grid>
          <Grid item>
            <FormControl>
              <FormLabel>ACK</FormLabel>
              <Controller
                name="isAck"
                control={control}
                defaultValue
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <FormLabel>HEX</FormLabel>
              <Controller
                name="isHex"
                control={control}
                defaultValue={false}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <FormLabel>ENQUEUE</FormLabel>
              <Controller
                name="enqueue"
                control={control}
                defaultValue
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={6}>
            <Controller
              as={(
                <TextField
                  label="Payload"
                  placeholder=""
                  error={errors.param}
                  fullWidth
                  required
                  multiline
                  autoCapitalize="none"
                />
              )}
              id="param"
              defaultValue=""
              name="param"
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
            >
              <Typography>{t('common.send')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

CommandGenericFormRow.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default CommandGenericFormRow;
