import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Typography,
  Link,
  Grid,
} from '@material-ui/core';

import {
  Info,
  Poll,
} from '@material-ui/icons';

import {
  Cog,
  CogTransfer,
} from 'mdi-material-ui';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  GridItem,
  GridContainer,
  CustomTabs,
  GraphQLErrorWrapper,
  Loader,
} from '../generic';

import {
  DeviceDetailTab,
  DeviceMonitorsTab,
  DeviceExportIntegrationsTab,
  DeviceAdvancedTab,
  DeviceRemoteConfigurationsTab,
} from '../device/tabs';

import { GROUP_DEVICE_QUERY } from '../device/queries';

import { GroupContext } from './GroupContext.jsx';
import { AuthenticationContext } from '../authentication';

const GroupDeviceDetailPage = () => {
  const { t } = useTranslation('translations');
  const { groupId, deviceId } = useParams();

  const {
    error,
    loading,
    data,
  } = useQuery(GROUP_DEVICE_QUERY, {
    variables: {
      id: deviceId,
      groupId,
    },
  });

  const { group } = React.useContext(GroupContext);
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const { iAmOwner, myRoles } = group;

  const canEdit = React.useMemo(() => (
    iAmAdmin || iAmOwner || myRoles.includes('GROUP_MANAGER')
  ), [iAmAdmin, iAmOwner, myRoles]);

  const device = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.group.device;
    }
    return null;
  }, [error, data, loading]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  if (loading) {
    return (
      <Loader loading={loading} />
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Grid container alignItems="center">
          <Typography variant="h4" component="h4" gutterBottom>
            <Link color="primary" style={{ cursor: 'pointer', paddingRight: 15 }} href={`/groups/${group.id}`}>
              {`${group.name.toUpperCase()} > `}
            </Link>
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            {device.devID}
          </Typography>
        </Grid>
      </GridItem>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('devices.details.tabs.monitors.title'),
            tabIcon: Poll,
            tabContent: (
              <DeviceMonitorsTab
                device={device}
              />
            ),
          },
          {
            ...(canEdit && {
              tabName: t('devices.details.tabs.settings.title'),
              tabIcon: Info,
              tabContent: (
                <DeviceDetailTab
                  device={device}
                  canEdit={canEdit}
                />
              ),
            }),
          },
          {
            ...(canEdit && {
              tabName: t('devices.details.tabs.exportIntegrations.title'),
              tabIcon: CloudUploadIcon,
              tabContent: (
                <DeviceExportIntegrationsTab
                  device={device}
                  canEdit={canEdit}
                />
              ),
            }),
          },
          {
            ...(canEdit && {
              tabName: t('devices.details.tabs.remoteConfigurations.title'),
              tabIcon: CogTransfer,
              tabContent: (
                <DeviceRemoteConfigurationsTab
                  device={device}
                  canEdit={canEdit}
                />
              ),
            }),
          },
          {
            ...(canEdit && device.group !== null && {
              tabName: t('devices.details.tabs.advanced.title'),
              tabIcon: Cog,
              tabContent: (
                <DeviceAdvancedTab
                  device={device}
                  canEdit={canEdit}
                />
              ),
            }),
          },
        ]}
      />
    </GridContainer>
  );
};

export default GroupDeviceDetailPage;
