import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';

import {
  GraphQLErrorWrapper,
  Loader,
} from '../../generic';

import GroupForm from './GroupForm';

import {
  GROUPS_QUERY,
  GROUP_CREATE_MUTATION,
} from '../queries';

const GroupCreateModal = (props) => {
  const {
    pageSize,
    filter,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation('translations');

  const [
    groupCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUPS_QUERY,
          variables: {
            pageSize,
            filter,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (data) => {
    try {
      await groupCreateMutationAction({
        variables: {
          input: {
            ...data,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('groups.create.title')}</DialogTitle>
      <DialogContent>
        <GraphQLErrorWrapper error={error} />
        <Loader loading={loading} />
        <GroupForm
          group={null}
          onFormSubmit={handleFormSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
  pageSize: 20,
  filter: {},
};

GroupCreateModal.propTypes = propTypes;

GroupCreateModal.defaultProps = defaultProps;

export default GroupCreateModal;
