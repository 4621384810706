import { gql } from '@apollo/client';
import {
  DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT,
  FTP_EXPORT_INTEGRATION_FRAGMENT,
} from '../exportIntegration/queries';
import {
  MEASURE_CHANNEL_FRAGMENT,
} from '../measure/queries.js';

export const DEVICE_LIST_ITEM_FRAGMENT = gql`
  fragment DeviceListItemFragment on Device {
    id
    devID
    devEUI
    serialNumber
    timezone
    onlySIUnits
    rssi
    lastBatteryLevel
    lastActivityTimestamp
    model
    userCode
    groupName
    rainTip
    userCode
    RFLogInterval
    measureInterval
    buzzerAlarm
    cyclicLogging
    loggingActive
    timeSynchronization
    protocolType
    firmwareVersion
    firmwareDate
    group {
      id
      name
      owner
    }
    application {
      id
      appID
    }
    pendingCommands
    createdAt
    updatedAt
    deletedAt
  }
`;

export const DEVICE_DETAIL_FRAGMENT = gql`
  fragment DeviceDetailFragment on Device {
    ...DeviceListItemFragment
    deltaCloudExportIntegration {
      ...DeltaCloudExportIntegrationFragment
    }
    ftpExportIntegration {
      ...FtpExportIntegrationFragment
    }
    measureChannels {
      ...MeasureChannelFragment
    }
  }
  ${DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT}
  ${FTP_EXPORT_INTEGRATION_FRAGMENT}
  ${DEVICE_LIST_ITEM_FRAGMENT}
  ${MEASURE_CHANNEL_FRAGMENT}
`;

export const GROUP_DEVICE_QUERY = gql`
  query groupDeviceQuery($groupId: ID!, $id: ID!) {
    viewer {
      group(id: $groupId) {
        device(id: $id) {
          ...DeviceDetailFragment
        }
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const APPLICATION_DEVICE_QUERY = gql`
  query applicationDeviceQuery($applicationId: ID!, $id: ID!) {
    viewer {
      application(id: $applicationId) {
        device(id: $id) {
          ...DeviceDetailFragment
        }
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_UPDATE_MUTATION = gql`
  mutation DeviceUpdateMutation($input: DeviceUpdateInput!) {
    deviceUpdate(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_UPDATE_GROUP_MUTATION = gql`
  mutation DeviceUpdateGroupMutation($input: DeviceUpdateGroupInput!) {
    deviceUpdateGroup(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_ADD_TO_GROUP_MUTATION = gql`
  mutation DeviceAddToGroupMutation($input: DeviceAddToGroupInput!) {
    deviceAddToGroup(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_CHANGE_GROUP_MUTATION = gql`
  mutation DeviceChangeGroupMutation($input: DeviceChangeGroupInput!) {
    deviceChangeGroup(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_UNLINK_APPLICATION_MUTATION = gql`
  mutation DeviceUnlinkApplicationMutation($input: DeviceUnlinkApplicationInput!) {
    deviceUnlinkApplication(input: $input) {
      success
    }
  }
`;

export const DEVICE_UNLINK_GROUP_MUTATION = gql`
  mutation DeviceUnlinkGroupMutation($input: DeviceUnlinkGroupInput!) {
    deviceUnlinkGroup(input: $input) {
      success
    }
  }
`;

export const DEVICE_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION = gql`
  mutation DeviceUpdateDeltaCloudExportIntegrationMutation($input: DeviceUpdateDeltaCloudExportIntegrationInput!) {
    deviceUpdateDeltaCloudExportIntegration(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const DEVICE_UPDATE_FTP_EXPORT_INTEGRATION_MUTATION = gql`
  mutation DeviceUpdateFTPExportIntegrationMutation($input: DeviceUpdateFTPExportIntegrationInput!) {
    deviceUpdateFTPExportIntegration(input: $input) {
      device {
        ...DeviceDetailFragment
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;
