import React from 'react';
import PropTypes from 'prop-types';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import TerminalCard from '../components/TerminalCard.jsx';

const TerminalTab = (props) => {
  const { device } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TerminalCard
          device={device}
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

TerminalTab.propTypes = propTypes;
export default TerminalTab;
