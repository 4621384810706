import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Loader,
  GraphQLErrorWrapper,
} from '../generic';

import { APPLICATION_QUERY } from './queries';

export const ApplicationContext = React.createContext();

export const ApplicationProvider = (props) => {
  const { children } = props;
  const { applicationId } = useParams();

  const {
    error,
    loading,
    data,
  } = useQuery(APPLICATION_QUERY, {
    variables: {
      id: applicationId,
    },
  });

  const application = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.application;
    }
    return null;
  }, [error, data, loading]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  if (loading) {
    return (
      <Loader loading={loading} />
    );
  }

  return (
    <ApplicationContext.Provider value={{ application }}>{children}</ApplicationContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

ApplicationProvider.propTypes = propTypes;

export const { Consumer: ApplicationConsumer } = ApplicationContext;
