const SEC1 = 0;
const SEC2 = 1;
const SEC5 = 2;
const SEC10 = 3;
const SEC15 = 4;
const SEC30 = 5;
const MIN1 = 6;
const MIN2 = 7;
const MIN5 = 8;
const MIN10 = 9;
const MIN15 = 10;
const MIN30 = 11;
const HOUR1 = 12;
const HOUR12 = 13;
const HOUR24 = 14;

export default {
  SEC1,
  SEC2,
  SEC5,
  SEC10,
  SEC15,
  SEC30,
  MIN1,
  MIN2,
  MIN5,
  MIN10,
  MIN15,
  MIN30,
  HOUR1,
  HOUR12,
  HOUR24,
};
