import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
} from '@material-ui/core';
import {
  Info,
  Devices,
} from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  GridItem,
  GridContainer,
  CustomTabs,
} from '../generic';
import {
  ApplicationGeneralTab,
  ApplicationDevicesTab,
  ApplicationExportIntegrationsTab,
} from './tabs';

import { ApplicationContext } from './ApplicationContext.jsx';
import { AuthenticationContext } from '../authentication';

const ApplicationDetailPage = () => {
  const { t } = useTranslation('translations');

  const { application } = React.useContext(ApplicationContext);
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const { iAmOwner, myRoles } = application;

  const canEdit = React.useMemo(() => (
    iAmAdmin || iAmOwner || myRoles.includes('GROUP_MANAGER')
  ), [iAmAdmin, iAmOwner, myRoles]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Typography variant="h4" component="h4" gutterBottom>
          {application.appID}
        </Typography>
      </GridItem>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('applications.details.tabs.devices.title'),
            tabIcon: Devices,
            tabContent: (
              <ApplicationDevicesTab
                application={application}
                canEdit={canEdit}
              />
            ),
          },
          {
            tabName: t('applications.details.tabs.settings.title'),
            tabIcon: Info,
            tabContent: (
              <ApplicationGeneralTab
                application={application}
                canEdit={canEdit}
              />
            ),
          },
          {
            tabName: t('applications.details.tabs.exportIntegrations.title'),
            tabIcon: CloudUploadIcon,
            tabContent: (
              <ApplicationExportIntegrationsTab
                application={application}
                canEdit={canEdit}
              />
            ),
          },
        ]}
      />
    </GridContainer>
  );
};

export default ApplicationDetailPage;
