import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  Card,
  CardBody,
  CardHeader,
} from './Card';

import styles from '../assets/jss/material-dashboard-react/components/customTabsStyle.js';

const useStyles = makeStyles(styles);

const CustomTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, changedValue) => {
    setValue(changedValue);
  };
  const classes = useStyles();

  const {
    headerColor, tabs,
  } = props;

  return (
    <Card>
      <CardHeader color={headerColor}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone,
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((prop, key) => {
            let icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />,
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                label={prop.tabName}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            // eslint-disable-next-line react/no-array-index-key
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
};

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
  ]).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      tabContent: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default CustomTabs;
