import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import {
  ApplicationEditCard,
} from '../components';

const ApplicationGeneralTab = (props) => {
  const { application, canEdit } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {canEdit ? (
          <ApplicationEditCard
            application={application}
          />
        ) : (
          <Typography variant="h5" component="h5" gutterBottom>
            {application.appID}
          </Typography>
        )}
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
    appID: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ApplicationGeneralTab.propTypes = propTypes;

export default ApplicationGeneralTab;
