import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import {
  GraphQLErrorWrapper,
} from '../../generic';

import {
  GROUPS_QUERY,
} from '../queries';

const GroupAutocompleteSearcher = (props) => {
  const { value: currentValue, onChange } = props;
  const { t } = useTranslation('translations');

  const [value, setValue] = React.useState(currentValue);
  const [inputValue, setInputValue] = React.useState(currentValue ? currentValue.name : '');
  const [debauncedValue] = useDebounce(inputValue, 500);

  const [
    loadGroups,
    {
      data,
      loading,
      error,
      refetch,
      called,
    },
  ] = useLazyQuery(GROUPS_QUERY);

  const groups = React.useMemo(() => {
    if (!loading && !error && data) {
      return data.viewer.groups.edges.map((e) => e.node);
    }
    return [];
  }, [data, loading, error]);

  const options = React.useMemo(() => groups.map((group) => ({
    ...group,
  })), [groups]);

  React.useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  React.useEffect(() => {
    const queryOptions = {
      variables: {
        filter: {
          search: debauncedValue,
        },
        pageSize: 20,
      },
    };
    if (called) {
      refetch(queryOptions.variables);
    }
    else {
      loadGroups(queryOptions);
    }
  }, [called, debauncedValue, loadGroups, refetch]);

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }

  return (
    <Autocomplete
      value={value}
      onChange={(event, selectedGroup) => {
        setValue(selectedGroup);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="groupAutocomplete"
      options={options}
      // getOptionLabel={(option) => option.name}
      getOptionLabel={(option) => `${option.name} - ${option.owner}`}
      getOptionSelected={(option, selectedValue) => (
        option.name === selectedValue.name
        && option.owner === selectedValue.owner
      )}
      loading={loading}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={t('common.group')}
          variant="outlined"
        />
      )}
    />
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: null,
};
GroupAutocompleteSearcher.propTypes = propTypes;
GroupAutocompleteSearcher.defaultProps = defaultProps;

export default GroupAutocompleteSearcher;
