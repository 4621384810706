import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import {
  // IconButton,
  Button,
  Grid,
  Link,
} from '@material-ui/core';
import {
  //   Edit,
  Update,
} from '@material-ui/icons';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
  BatteryLevelIcon,
} from '../../generic';
import {
  formatDistanceToNowLocale,
  getTimezoneLabelWithOffset,
} from '../../utils/date';

import {
  DeviceSearcher,
} from '../../device/components';

import {
  APPLICATION_DEVICES_QUERY,
} from '../queries';

const ApplicationDevicesList = (props) => {
  const { pageSize, filter, application } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  // const navigate = useNavigate();

  const [search, setSearch] = React.useState(null);

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(APPLICATION_DEVICES_QUERY, {
    variables: {
      applicationId: application.id,
      pageSize,
      filter: {
        ...filter,
        search,
      },
    },
  });

  // const handleEdit = (device) => {
  //   navigate(`/applications/${application.id}/devices/${device.id}`);
  // };

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { application: applicationQuery } = viewer;
      const { devices: devicesConnection } = applicationQuery;
      const { pageInfo, edges } = devicesConnection;
      return {
        devices: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      devices: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const { devices, pageInfo } = queryResult;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          edges: oldEdges,
        } = previousResult.viewer.application.devices;
        const {
          pageInfo: newPageInfo, edges: newEdges,
        } = fetchMoreResult.viewer.application.devices;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              application: {
                ...previousResult.viewer.application,
                devices: {
                  ...previousResult.viewer.application.devices,
                  edges: [...oldEdges, ...newEdges],
                  pageInfo: newPageInfo,
                },
              },
            },
          }
          : previousResult;
      },
    });
  };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }

  return (
    <>
      <CardWrapper
        plain
        cardTitle={t('devices.list.title')}
        cardSubtitle={t('devices.list.subtitle')}
        cardBody={
          (
            <>
              <DeviceSearcher
                onSearch={setSearch}
              />
              <CustomTable
                loading={loading}
                noDataText={t('devices.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  ' ',
                  t('common.devID'),
                  t('common.devEUI'),
                  t('common.serialNumber'),
                  t('common.model'),
                  t('common.userCode'),
                  t('common.group'),
                  t('common.firmwareVersion'),
                  t('common.firmwareDate'),
                  t('common.rssi'),
                  t('common.lastBatteryLevel'),
                  t('common.lastActivityTimestamp'),
                  t('common.timezone'),
                  // (
                  //   <Grid container justify="flex-end">
                  //     {t('common.actions')}
                  //   </Grid>
                  // ),
                ]}
                tableData={devices.map((device, index) => ([
                  index + 1,
                  <Link style={{ cursor: 'pointer' }} color="primary" href={`/applications/${application.id}/devices/${device.id}`}>
                    {device.devID}
                  </Link>,
                  device.devEUI,
                  device.serialNumber,
                  device.model,
                  device.userCode,
                  device.group ? device.group.name : '',
                  device.firmwareVersion,
                  device.firmwareDate,
                  device.rssi,
                  (
                    <Grid container justify="center">
                      <BatteryLevelIcon level={device.lastBatteryLevel} />
                    </Grid>
                  ),
                  formatDistanceToNowLocale(device.lastActivityTimestamp, locale),
                  getTimezoneLabelWithOffset(device.timezone),
                  // (
                  //   <Grid container justify="flex-end">
                  //     <IconButton color="primary" onClick={() => handleEdit(device)}>
                  //       <Edit fontSize="small" />
                  //     </IconButton>
                  //   </Grid>
                  // ),
                ]))}
              />
            </>
          )
        }
        cardFooter={(pageInfo && pageInfo.hasNextPage)
          && (
            <Button
              fullWidth
              onClick={() => handleLoadMore()}
              color="primary"
              variant="contained"
              startIcon={<Update />}
            >
              {t('common.loadMore')}
            </Button>
          )}
      />
    </>
  );
};

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

const defaultProps = {
  pageSize: 20,
  filter: {},
};

ApplicationDevicesList.propTypes = propTypes;
ApplicationDevicesList.defaultProps = defaultProps;

export default ApplicationDevicesList;
