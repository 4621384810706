import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SuccessSnackBar = (props) => {
  const { t } = useTranslation('translations');
  const {
    open,
    setOpen,
    message,
  } = props;

  if (!message) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <MuiAlert
        onClose={() => setOpen(false)}
        elevation={6}
        variant="filled"
        severity="success"
      >
        {t(message)}
      </MuiAlert>
    </Snackbar>
  );
};

const propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

SuccessSnackBar.propTypes = propTypes;

export default SuccessSnackBar;
