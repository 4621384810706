export default {
  en: {
    translations: {
      common: {
        ok: 'Ok',
        yes: 'Yes',
        no: 'No',
        areYouSure: 'Are you sure?',
        confirmLogout: 'Confirm logout?',
        cancel: 'Cancel',
        actions: 'Actions',
        save: 'Save',
        delete: 'Delete',
        restore: 'Restore',
        loadMore: 'Load more',
        filter: 'Filter',
        filterByName: 'Filter by name',
        send: 'Send',
        resend: 'Resend',
        create: 'Create',
        error: 'ERROR',
        pending: 'Pending...',

        application: 'Application',
        applications: 'Applications',
        group: 'Group',
        groups: 'Groups',
        detail: 'Detail',
        details: 'Details',
        logout: 'Logout',
        defaultGroupName: 'Default',

        requiredField: 'Required field',
        roles: 'Roles',
        email: 'Email',
        name: 'Name',
        timezone: 'Time Zone',
        owner: 'Owner',
        server: 'Server',
        ftpPort: 'FTP port',
        ftpUsername: 'FTP user name',
        ftpPassword: 'FTP password',
        period: 'Period',
        directory: 'Directory',
        directoryForEveryDevice: 'Directory for every device',
        enabled: 'Enabled',
        urlResource: 'URL resources',

        devID: 'DEV ID',
        devEUI: 'DEV EUI',
        appID: 'APP ID',
        appEUI: 'APP EUI',
        description: 'Description',
        url: 'URL',
        processID: 'Process ID',
        accessKey: 'Access Key',
        rssi: 'RSSI',
        snr: 'SNR',
        dataRate: 'Data Rate',
        lastBatteryLevel: 'Battery level',
        lastActivityTimestamp: 'Status',
        dateAndTime: 'Date and time',
        serialNumber: 'Serial number',
        model: 'Model',
        userCode: 'User Code',
        groupName: 'Group Name',
        rainTip: 'Rain Tip',
        firmwareVersion: 'FW Version',
        firmwareDate: 'FW Date',
        onlySIUnits: 'ONLY SI UNITS',
        isTimeSynchActive: 'Time synchronization',
        RFLogInterval: 'RF/Log Interval',
        measureInterval: 'Measure Interval',
        protocolType: 'Protocol Type',
        timeSynchronization: 'Time Synchronization',
        loggingActive: 'Logging active',
        cyclicLogging: 'Cyclic Logging',
        buzzerAlarm: 'Buzzer Alarm',
        refreshAllInfo: 'Refresh all info',
        testBuzzer: 'Test buzzer',
        ignorePendingCommands: 'IGNORE PENDING COMMANDS',
        askHistoricalData: 'ask historical data',
        devEUIorSerialNumber: 'DEV EUI / Serial Number',

        channels: 'Channels',
        status: 'Status',
        measureType: 'Type of meas.',
        unitOfMeasure: 'Unit measure',
        resolution: 'Precision',
        channelName: 'Channel name',
        qualifier: 'Qualifier',

        measure: 'Measurement',
        measures: 'Measurements',
        measuresNoData: 'There are no available measures for the requested period',
        propagable: 'Propagable',
        propagateConfig: 'Propagate current configuration',
        confirmPropagateConfig: 'All devices in the set will receive the same current configuration. Are you sure to continue?',
        propagateSettings: 'Propagate the configuration',
        confirmPropagateSettings: 'All devices in the set will receive the current configuration. Are you sure to continue?',
        operationSuccess: 'Operation successful',
        timestamp: 'Timestamp',

        confirmDeleteGroup: 'Comfirm remove group?',
        confirmDeleteApplication: 'Comfirm remove application?',

        removeFromApplication: 'Remove device from the application',
        confirmRemoveFromApplication: 'Are you sure to remove the device from application?',

        removeFromGroup: 'Remove device from group',
        confirmRemoveFromGroup: 'Are you sure to remove the device from group?',

        selectRange: 'Select interval',
        today: 'Today',
        lastHour: 'Last hour',
        thisWeek: 'This week',
        thisMonth: 'This month',
        thisYear: 'This year',
        CH: 'CH',

        minute: 'minute',
        hour: 'hour',
        day: 'day',
        week: 'week',
        month: 'month',
        year: 'year',

        hideMarkers: 'Hide markers',
        multiColor: 'Multi-color',

        nameOverride: 'Name overwritten',
        overrideChannelName: 'Overwrite channel name',
        overrideGroupSetting: 'Overwrite even if it belongs to a group',
        notOverrideGroupSetting: 'Do NOT overwrite if it belongs to a group',

        removeDeviceFromGroup: 'Remove device from group',
        commandCommunications: 'Command communications',
        commandCommunicationNoData: 'No communication commands',
      },
      enums: {
        groupRoles: {
          GROUP_MANAGER: 'Super User',
          GROUP_VIEWER: 'User',
        },
        periods: {
          SEC0: 'Real Time',
          SEC60: '1 min',
          SEC120: '2 min',
          SEC300: '5 min',
          SEC600: '10 min',
          SEC900: '15 min',
          SEC1800: '30 min',
          SEC3600: '1 hour',
          SEC7200: '2 hour',
          SEC14400: '4 hour',
          SEC28800: '8 hour',
          SEC43200: '12 hour',
          SEC86400: '24 hour',
        },
        intervals: {
          SEC1: '1 sec',
          SEC2: '2 sec',
          SEC5: '5 sec',
          SEC10: '10 sec',
          SEC15: '15 sec',
          SEC30: '30 sec',
          MIN1: '1 min',
          MIN2: '2 min',
          MIN5: '5 min',
          MIN10: '10 min',
          MIN15: '15 min',
          MIN30: '30 min',
          HOUR1: '1 hour',
          HOUR12: '12 hour',
          HOUR24: '24 hour',
        },
        alarms: {
          level2: 'down threshold alarm',
          level3: 'up threshold alarm',
          level4: 'underflow error and down alarm',
          level5: 'not init error and down alarm',
          level6: 'overflow error and up alarm',
          level7: 'error and up alarm',
        },
        batteryLevels: {
          level0: 'low battery',
          level1: 'half battery',
          level2: 'full battery',
          level3: 'external power supply and back-up battery',
          level4: 'external power supply',
        },
        qualifiers: {
          archiv: 'ARCHIV',
          avg: 'AVG',
          none: 'NONE',
          max: 'MAX',
          min: 'MIN',
          tot: 'TOT',
        },
        measureTypes: {
          temperature: 'Temperature',
          relativeHumidity: 'Relative humidity',
          dewPoint: 'Dew point',
          partialVaporPressure: 'Partial vapor pressure',
          mixingRatio: 'Mixing ratio',
          absoluteHumidity: 'Absolute humidity',
          wetPoint: 'Wet bulb temperature',
          differentialPressure: 'Differential pressure',
          solarRadiation: 'Solar radiation',
          illuminance: 'Illuminance',
          carbonMonoxide: 'Carbon monoxide',
          atmosphericPressure: 'Atmospheric pressure',
          frequency: 'Frequency',
          mappedFrequency: 'Mapped frequency',
          dailyGlobalRadiation: 'Daily global radiation',
          carbonDioxide: 'Carbon Dioxide',
          volumWaterContent: 'Volumetric water content',
          soilMoisture: 'Soil moisture',
          accelerationZ: 'Acceleration Z',
          height: 'Height',
          shockTime: 'Shock time',
          deltaSpeed: 'Delta speed',
          windSpeed: 'Wind speed',
          windDirection: 'Wind direction',
          windChill: 'Wind chill',
          pyranometer: 'Pyranometer',
          uvaIrradiance: 'UVA irradiance',
          uvaProportion: 'UVA Proportion',
          naturalWetBulb: 'Natural wet bulb temperature',
          globeTemperature: 'Globe Temperature',
          wbgtIndoor: 'WBGT indoor',
          wbgtOutdoor: 'WBGT outdoor',
          windGust: 'Wind gust',
          maxRainRate: 'Max rain rate',
          dailyRain: 'Daily rain',
          batteryVoltage: 'Battery voltage',
          mkt_1: 'Mean kinetic temperature 1',
          mkt_2: 'Mean kinetic temperature 2',
          mkt_3: 'Mean kinetic temperature 3',
          contactInput: 'Contact input',
          flow: 'Flow',
          volumetricWaterContent: 'Volumetric water content 2',
          soilMoisture_2: 'Soil moisture 2',
          volumWaterContent_3: 'Volumetric water content 3',
          soilMoisture_3: 'Soil moisture 3',
          airSpeed: 'Air speed',
          par: 'PAR',
          maxRainLastHour: 'Max rain last hour',
          gpsDirection: 'GPS direction',
          naturalWetBulbComp: 'Natural wet bulb computed',
          voltagePowerSupply: 'Voltage power supply',
          currentRain: 'Current rain',
          sunPresence: 'Sun presence',
          sunLastMinute: 'Sun last minute',
          sunLast_10Minutes: 'Sun last 10 minutes',
          accelerationX: 'Acceleration X',
          accelerazionY: 'Acceleration Y',
          etHour: 'Hour evapotranspiration',
          dailyEt: 'Daily evapotranspiration',
          netRadiation: 'Net radiaiton',
          relativePressure: 'Relative pressure',
          fluidLevel: 'Fluid level',
          leafWetnessDown: 'Leaf wetness down',
          leafWetnessUp: 'Leaf wetness up',
          windGustDirection: 'Wind gust direction',
          uvbIrradiance: 'UVB irradiance',
          uvcIrradiance: 'UVC irradiance',
          albedo: 'Albedo',
          pm1_0: 'PM1.0',
          pm2_5: 'PM2.5',
          pm4_0: 'PM4.0',
          pm10: 'PM10',
          typParticleSize: 'Typical particle size',
          VOCIndex: 'VOC index',
          eventRain: 'Event rain',
          firstRain: 'First rain',
          temperaturePt100_2W: 'Temperature PT100 2W',
          temperaturePt100_3W: 'Temperature PT100 3W',
          temperaturePt100_4W: 'Temperature PT100 4W',
          temperaturePt1000_2W: 'Temperature PT1000 2W',
          temperaturePt1000_3W: 'Temperature PT1000 3W',
          temperaturePt1000_4W: 'Temperature PT1000 4W',
          temperatureTcK: 'Temperature TC K',
          temperatureTcJ: 'Temperature TC J',
          temperatureTcT: 'Temperature TC T',
          temperatureTcN: 'Temperature TC N',
          temperatureTcR: 'Temperature TC R',
          temperatureTcS: 'Temperature TC S',
          temperatureTcB: 'Temperature TC B',
          temperatureTcE: 'Temperature TC E',
          voltage_0_1V: 'Voltage 0-1V',
          voltage_0_50mV: 'Voltage 0-50mV',
          current_4_20mA: 'Current 4-20mA',
          potentiometer: 'Potentiometer',
          mappedVoltage_0_1V: 'Mapped voltage 0-1V',
          mappedVoltage_0_50mV: 'Mapped voltage 0-50mV',
          mappedCurrent_4_20mA: 'Mapped current 4-20mA',
          mappedPotentiometer: 'Mapped potentiometer',
          voltage_0_10V: 'Voltage 0-10V',
          mappedVoltage_0_10V: 'Mapped voltage 0-10V',
          digitalMeasure: 'Digital measure',
          voltage_50_50mV: 'Voltage -50...+50mV',
          mapVolt_50_50mV: 'Mapped voltage -50...+50mV',
          pyrgeometer: 'Pyrgeometer',
          current: 'Current',
          temperaturePt100_3WHr: 'Temperature PT100 3W HR',
          temperaturePt100_4WHr: 'Temperature PT100 4W HR',
          temperaturePt1000_3WHr: 'Temperature PT1000 3W HR',
          temperaturePt1000_4WHr: 'Temperature PT1000 4W HR',
          flowRate: 'Flow rate',
          counter: 'Counter',
          mappedCounter: 'Mapped counter',
          totalRainCompensed: 'Total rain compensed',
          dailyRainCompensed: 'Total rain compensed',
          totaleEnergy: 'Total energy',
          energy: 'Energy',
          rainCounter: 'Rain Counter',
          notImplemented: 'Not implemented',
          rssi: 'RSSI',
          snr: 'SNR',

          // Cayenne
          digitalIn: 'Digital Input',
          digitalOut: 'Digital Output',
          analogIn: 'Analog Input',
          analogOut: 'Analog Output',
          // illuminance: 'Illuminazione',
          presence: 'Presence',
          // temperature: 'Temperatura',
          // relativeHumidity: "Umidita' relativa",
          // accelerationX: 'Accelerazione X',
          accelerationY: 'Acceleration Y',
        // accelerationZ: 'Accelerazione Z',
        // atmosphericPressure: 'Pressione Atmosferica',
        },
      },
      extractGraphQLErrors: {
        noDuplicate: 'Duplicates not available',
        networkError: 'Server unreachable, check connection',
        unexpectedError: 'There was an unexpected error',
        unauthenticated: 'You are NOT authenticated to visualize this information',
      },
      header: {
        menu: {
          groups: 'Groups',
          applications: 'Applications',
          devices: 'Devices',
          logOut: 'Exit',
        },
      },
      allDevices: {
        list: {
          title: 'All devices',
          subtitle: 'List of all devices',
          noData: 'No devices available',
        },
      },
      applications: {
        list: {
          title: 'Applications',
          subtitle: 'List of applications',
          noData: 'No applications available',
        },
        details: {
          tabs: {
            devices: {
              title: 'Devices',
            },
            settings: {
              title: 'Settings',
            },
            exportIntegrations: {
              title: 'Export integrations',
            },
          },
        },
        create: {
          title: 'Create new application',
        },
        edit: {
          title: 'Modify application',
        },
        delete: {
          title: 'Are you sure to delete the application?',
        },
      },
      groups: {
        list: {
          title: 'Groups',
          subtitle: 'List of groups',
          noData: 'No groups available',
        },
        details: {
          tabs: {
            devices: {
              title: 'Devices',
            },
            settings: {
              title: 'Settings',
            },
            users: {
              title: 'Users',
            },
            exportIntegrations: {
              title: 'Export integrations',
            },
          },
        },
        create: {
          title: 'Create new group',
        },
        edit: {
          title: 'Modify group',
        },
        delete: {
          title: 'Are you sure to delete the group?',
        },
        addDevice: {
          title: 'Add device to the group',
        },
      },
      userPermissions: {
        list: {
          title: 'Authorizations',
          subtitle: 'List of authrized users and relative roles',
          noData: 'There are no authorizations',
        },
        edit: {
          title: 'Modify an authorization',
        },
      },
      devices: {
        list: {
          title: 'Devices',
          subtitle: 'List of devices',
          noData: 'No devices available',
        },
        details: {
          tabs: {
            monitors: {
              title: 'Monitoring',
            },
            settings: {
              title: 'Settings',
            },
            exportIntegrations: {
              title: 'Export integrations',
            },
            advanced: {
              title: 'Advanced settings',
            },
            remoteConfigurations: {
              title: 'Remote configurations',
            },
          },
        },
        edit: {
          title: 'Modify the device',
        },
        monitorsTabs: {
          realTime: {
            title: 'RealTime',
          },
          chart: {
            title: 'Charts',
          },
          table: {
            title: 'Tables',
          },
        },
        remoteConfigurationsTabs: {
          configurations: {
            title: 'Configurations',
          },
          terminal: {
            title: 'Command Terminal',
          },
        },
        create: {
          title: 'Create new device',
        },
        commands: {
          title: 'Send commands',
          log: 'Communication logs',
          GENERIC: 'Command',
          GET_INFO: 'Get information',
          SET_DEVICE_NAME: 'Set device name',
          SET_GROUP_NAME: 'Set group name',
          GET_COMMUNICATION_PROTOCOL: 'Get communication protocol',
          SET_COMMUNICATION_PROTOCOL: 'Set communication protocol',
          SET_TRANSISSION_RATE: 'Set transmission rate',
          GET_TRANSISSION_RATE: 'Get transmission rate',
          fPort: 'Port',
        },
      },
      groupInvites: {
        list: {
          title: 'Invitations',
          subtitle: 'List of pending invitations not yet accepted',
          noData: 'No invitations',
        },
        create: {
          title: 'Create new invitation',
        },
      },
      groupOwnershipInvites: {
        create: {
          title: 'Invitation to be owner of the group',
        },
      },
      exportIntegrations: {
        ftp: {
          title: 'FTP',
          edit: {
            title: 'Modify settings for FTP exports',
          },
          test: {
            title: 'Test',
            testFTP: 'Test FTP connection',
            testFTPBetweenDatetime: 'Testing FTP upload between datetimes',
            resetLast: 'Reset datetime of last export',
          },
        },
        deltaCloud: {
          title: 'DeltaCloud',
          edit: {
            title: 'Modify settings for DeltaCloud exports',
            setDefaultURL: 'Set default URL',
          },
          test: {
            title: 'Test',
            testDeltaCloud: 'Test DeltaCloud connection',
            testDeltaCloudBetweenDatetime: 'Testing DeltaCloud upload between datetimes',
            resetLast: 'Reset datetime of last export',
          },
        },
      },
    },
  },
};
