import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
} from '@material-ui/core';
import {
  AddCircleOutline,
  CloudDownloadRounded,
} from '@material-ui/icons';
import {
  Cached,
} from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Card from './Card/Card';
import CardHeader from './Card/CardHeader';
import CardBody from './Card/CardBody';
import CardFooter from './Card/CardFooter';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  plusActionButton: {
    float: 'right',
    color: '#FFFFFF',
  },
};

const useStyles = makeStyles(styles);

const CardWrapper = (props) => {
  const classes = useStyles(styles);
  const {
    cardTitle,
    cardSubtitle,
    cardHeaderIcon,
    cardHeaderAction,
    cardBody,
    cardFooter,
  } = props;

  const renderIcon = () => {
    let result = null;
    switch (cardHeaderIcon) {
      case 'add':
        result = <AddCircleOutline fontSize="large" />;
        break;
      case 'download':
        result = <CloudDownloadRounded fontSize="large" />;
        break;
      case 'cached':
        result = <Cached fontSize="large" />;
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <Card>
      <CardHeader color="primary">
        {cardHeaderAction && (
          <IconButton className={classes.plusActionButton} onClick={cardHeaderAction}>
            {renderIcon()}
          </IconButton>
        )}
        <h4 className={classes.cardTitleWhite}>{cardTitle}</h4>
        {cardSubtitle && <p className={classes.cardCategoryWhite}>{cardSubtitle}</p>}
      </CardHeader>
      <CardBody>
        {cardBody}
      </CardBody>
      {cardFooter && (
        <CardFooter>
          {cardFooter}
        </CardFooter>
      )}
    </Card>
  );
};

const propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardSubtitle: PropTypes.string,
  cardHeaderIcon: PropTypes.string,
  cardHeaderAction: PropTypes.func,
  cardBody: PropTypes.node.isRequired,
  cardFooter: PropTypes.node,
};

const defaultProps = {
  cardSubtitle: null,
  cardHeaderIcon: null,
  cardHeaderAction: null,
  cardFooter: null,
};

CardWrapper.propTypes = propTypes;
CardWrapper.defaultProps = defaultProps;

export default CardWrapper;
