import React from 'react';

import {
  GridItem,
  GridContainer,
} from '../generic';

import {
  GroupsList,
} from './components';

const GroupsListPage = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <GroupsList />
    </GridItem>
  </GridContainer>
);

export default GroupsListPage;
