import React from 'react';
import {
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  // People,
  Apps,
  Devices,
} from '@material-ui/icons';
import {
  FolderAccountOutline,
} from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import { AuthenticationContext } from '../authentication';

import Navbar from '../generic/Navbars/Navbar';
import Sidebar from '../generic/Sidebar';

import styles from '../assets/jss/material-dashboard-react/layouts/adminStyle.js';

import logo from '../assets/img/logo.png';

import {
  AllDevicesListPage,
} from '../allDevicesList';

import {
  GroupCreatePage,
  GroupDetailPage,
  GroupDeviceDetailPage,
  GroupsListPage,
  GroupProvider,
  GroupAcceptOwnershipInvitePage,
} from '../group';
import {
  ApplicationDetailPage,
  ApplicationDeviceDetailPage,
  ApplicationsListPage,
  ApplicationProvider,
} from '../application';
import {
  GroupInviteAcceptPage,
} from '../groupInvite';

const useStyles = makeStyles(styles);

const AuthenticatedLayout = ({ ...rest }) => {
  const { t } = useTranslation('translations');
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const SIDEBAR_COMMON_ROUTES = React.useMemo(() => ([
    {
      path: '/groups',
      name: t('header.menu.groups'),
      icon: FolderAccountOutline,
    },
    {
      path: '/alldevices',
      name: t('header.menu.devices'),
      icon: Devices,
    },
  ]), [t]);

  const SIDEBAR_ADMIN_ROUTES = React.useMemo(() => ([
    {
      path: '/applications',
      name: t('header.menu.applications'),
      icon: Apps,
    },
  ]), [t]);

  const SIDEBAR_ROUTES = React.useMemo(() => {
    if (iAmAdmin) {
      return [
        ...SIDEBAR_COMMON_ROUTES,
        ...SIDEBAR_ADMIN_ROUTES,
      ];
    }
    return SIDEBAR_COMMON_ROUTES;
  }, [
    SIDEBAR_ADMIN_ROUTES,
    SIDEBAR_COMMON_ROUTES,
    iAmAdmin,
  ]);

  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={SIDEBAR_ROUTES}
        logoText="Delta Ohm"
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={SIDEBAR_ROUTES}
          handleDrawerToggle={handleDrawerToggle}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Routes>
              <Route path="/" element={<GroupsListPage />} />
              <Route path="/groupInviteAccept/:token" element={<GroupInviteAcceptPage />} />
              <Route path="/groupAcceptOwnershipInvite/:token" element={<GroupAcceptOwnershipInvitePage />} />
              <Route path="groups" element={<Outlet />}>
                <Route path="/" element={<GroupsListPage />} />
                <Route path="new" element={<GroupCreatePage />} />
                <Route path=":groupId" element={<GroupProvider><Outlet /></GroupProvider>}>
                  <Route path="/" element={<GroupDetailPage />} />
                  <Route path="/devices/:deviceId" element={<GroupDeviceDetailPage />} />
                </Route>
              </Route>
              <Route path="alldevices" element={<Outlet />}>
                <Route path="/" element={<AllDevicesListPage />} />
              </Route>
              {iAmAdmin && (
                <Route path="applications" element={<Outlet />}>
                  <Route path="/" element={<ApplicationsListPage />} />
                  <Route path=":applicationId" element={<ApplicationProvider><Outlet /></ApplicationProvider>}>
                    <Route path="/" element={<ApplicationDetailPage />} />
                    <Route path="/devices/:deviceId" element={<ApplicationDeviceDetailPage />} />
                  </Route>
                </Route>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayout;
