import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  TextField,
  Grid,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

import { clearTypenames } from '../../utils/graphql';

const DeviceAddToGroupForm = (props) => {
  const {
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: {
      id: null,
    },
  });

  const handleFormSubmit = (values) => {
    if (values.devEUI !== null) {
      onFormSubmit(clearTypenames({
        ...values,
        devEUI: values.devEUI.toUpperCase(),
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            as={(
              <TextField
                label={t('common.devEUIorSerialNumber')}
                placeholder={t('common.devEUIorSerialNumber')}
                error={errors.devEUI}
                fullWidth
                required
                autoFocus
                inputProps={{
                  maxLength: 16,
                  // minLength: 16,
                  pattern: '^[A-Z0-9]*$',
                }}
                onChange={(value) => value.toUpperCase()}
              />
        )}
            id="devEUI"
            defaultValue=""
            name="devEUI"
            control={control}
          />
          {errors.id && <p>{t('common.devEUI')}</p>}
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={errors.name || errors.timezone}
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

DeviceAddToGroupForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default DeviceAddToGroupForm;
