import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
  CustomButton,
} from '../../generic';

import ApplicationForm from './ApplicationForm';

import {
  APPLICATION_QUERY,
  APPLICATION_UPDATE_MUTATION,
  APPLICATION_PROPAGATE_SETTINGS_MUTATION,
  APPLICATION_DELETE_MUTATION,
} from '../queries';

const ApplicationEditCard = (props) => {
  const {
    application,
  } = props;
  const navigate = useNavigate();

  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [askConfirmPropagateVisible, setAskConfirmPropagateVisible] = React.useState(false);
  const [askConfirmDeleteVisible, setAskConfirmDeleteVisible] = React.useState(false);
  const [overrideGroupSetting, setOverrideGroupSetting] = React.useState(false);

  const [
    applicationUpdateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(APPLICATION_UPDATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: APPLICATION_QUERY,
          variables: {
            id: application.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    applicationPropagateSettingsMutationAction,
    {
      loading: loadingPropagate,
      error: errorPropagate,
    },
  ] = useMutation(APPLICATION_PROPAGATE_SETTINGS_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: APPLICATION_QUERY,
          variables: {
            id: application.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    applicationDeleteMutationAction,
    {
      loading: loadingDelete,
      error: errorDelete,
    },
  ] = useMutation(APPLICATION_DELETE_MUTATION);

  const handleFormSubmit = async (data) => {
    try {
      const result = await applicationUpdateMutationAction({
        variables: {
          input: {
            id: application.id,
            ...data,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handlePropagate = async () => {
    try {
      const result = await applicationPropagateSettingsMutationAction({
        variables: {
          input: {
            id: application.id,
            overrideGroupSetting,
          },
        },
      });
      if (result) setSuccessOpen(true);
      setAskConfirmPropagateVisible(false);
    }
    catch (e) { } // eslint-disable-line
  };

  const handleDeleteApplication = async () => {
    try {
      const result = await applicationDeleteMutationAction({
        variables: {
          input: {
            id: application.id,
          },
        },
      });
      setAskConfirmDeleteVisible(false);
      if (result) {
        setSuccessOpen(true);
        navigate('/applications', { replace: true });
      }
    }
    catch (e) {
      setAskConfirmDeleteVisible(false);
    } // eslint-disable-line
  };

  return (
    <CardWrapper
      cardTitle={t('applications.edit.title')}
      cardBody={
        (
          <>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper error={error || errorPropagate || errorDelete} />
            <Loader loading={loading || loadingPropagate || loadingDelete} />
            <ApplicationForm
              application={application}
              onFormSubmit={handleFormSubmit}
            />
            <div style={{ width: '100%', marginTop: 20 }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setAskConfirmPropagateVisible(true)}
              >
                <Typography>{t('common.propagateSettings')}</Typography>
              </Button>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={askConfirmPropagateVisible}
                onClose={() => setAskConfirmPropagateVisible(false)}
              >
                <DialogTitle id="draggable-dialog-title">
                  {t('common.confirmPropagateSettings')}
                </DialogTitle>
                <DialogContent>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={overrideGroupSetting}
                        onChange={() => setOverrideGroupSetting(!overrideGroupSetting)}
                        name="overrideGroupSetting"
                        color="primary"
                      />
                    )}
                    label={
                      overrideGroupSetting
                        ? t('common.overrideGroupSetting')
                        : t('common.notOverrideGroupSetting')
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <CustomButton autoFocus onClick={() => setAskConfirmPropagateVisible(false)} color="info">
                    {t('common.cancel')}
                  </CustomButton>
                  <CustomButton onClick={() => handlePropagate()} color="primary">
                    {t('common.ok')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
            </div>
            <div style={{ width: '100%', marginTop: 20 }}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => setAskConfirmDeleteVisible(true)}
              >
                <Typography>{t('common.delete')}</Typography>
              </Button>
              <Dialog
                fullWidth
                maxWidth="sm"
                open={askConfirmDeleteVisible}
                onClose={() => setAskConfirmDeleteVisible(false)}
              >
                <DialogTitle id="draggable-dialog-title">
                  {t('common.confirmDeleteApplication')}
                </DialogTitle>
                <DialogActions>
                  <CustomButton autoFocus onClick={() => setAskConfirmDeleteVisible(false)} color="info">
                    {t('common.cancel')}
                  </CustomButton>
                  <CustomButton onClick={() => handleDeleteApplication()} color="primary">
                    {t('common.ok')}
                  </CustomButton>
                </DialogActions>
              </Dialog>
            </div>
          </>
        )
      }
    />
  );
};

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
    appID: PropTypes.string,
  }).isRequired,
};

ApplicationEditCard.propTypes = propTypes;

export default ApplicationEditCard;
