import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Delete,
} from '@material-ui/icons';
import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import GroupOwnershipInviteCreateModal from './GroupOwnershipInviteCreateModal';

import {
  GROUP_QUERY,
  GROUP_DELETE_OWNERSHIP_INVITE_MUTATION,
} from '../queries';

const GroupOwnershipInvite = (props) => {
  const {
    group,
    canEdit,
  } = props;
  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [
    isGroupOwnershipInviteCreateModalOpen,
    setIsGroupOwnershipInviteCreateModalOpen,
  ] = React.useState(false);

  const [
    groupOwnershipInviteDeleteOwnershipMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_DELETE_OWNERSHIP_INVITE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleDelete = async () => {
    try {
      const result = await groupOwnershipInviteDeleteOwnershipMutationAction({
        variables: {
          input: {
            id: group.id,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <>
      <CardWrapper
        cardTitle={t('common.owner')}
        cardHeaderAction={() => setIsGroupOwnershipInviteCreateModalOpen(true)}
        cardHeaderIcon="cached"
        cardBody={
          (
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Typography>{group.owner}</Typography>
              </Grid>
              {group.ownershipInvite && (
                <Grid item xs={5}>
                  <Typography>{group.ownershipInvite}</Typography>
                </Grid>
              )}
              {group.ownershipInvite && (
                <Grid item xs={1}>
                  <IconButton color="secondary" onClick={() => handleDelete()}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )
        }
      />
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
      <GraphQLErrorWrapper error={error} />
      <Loader loading={loading} />
      {canEdit && (
        <GroupOwnershipInviteCreateModal
          isModalOpen={isGroupOwnershipInviteCreateModalOpen}
          group={group}
          onClose={() => setIsGroupOwnershipInviteCreateModalOpen(false)}
        />
      )}
    </>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    ownershipInvite: PropTypes.string,
  }).isRequired,
};

GroupOwnershipInvite.propTypes = propTypes;

export default GroupOwnershipInvite;
