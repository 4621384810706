import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import {
  Info,
  Poll,
} from '@material-ui/icons';
import {
  Cog,
  CogTransfer,
} from 'mdi-material-ui';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  GridItem,
  GridContainer,
  CustomTabs,
  GraphQLErrorWrapper,
  Loader,
} from '../generic';

import {
  DeviceDetailTab,
  DeviceExportIntegrationsTab,
  DeviceMonitorsTab,
  DeviceAdvancedTab,
  DeviceRemoteConfigurationsTab,
} from '../device/tabs';

import { APPLICATION_DEVICE_QUERY } from '../device/queries';

import { ApplicationContext } from './ApplicationContext.jsx';
import { AuthenticationContext } from '../authentication';

const ApplicationDeviceDetailPage = () => {
  const { t } = useTranslation('translations');
  const { applicationId, deviceId } = useParams();

  const {
    error,
    loading,
    data,
  } = useQuery(APPLICATION_DEVICE_QUERY, {
    variables: {
      id: deviceId,
      applicationId,
    },
  });

  const { application } = React.useContext(ApplicationContext);
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const { iAmOwner, myRoles } = application;

  const canEdit = React.useMemo(() => (
    iAmAdmin || iAmOwner || myRoles.includes('GROUP_MANAGER')
  ), [iAmAdmin, iAmOwner, myRoles]);

  const device = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.application.device;
    }
    return null;
  }, [error, data, loading]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  if (loading) {
    return (
      <Loader loading={loading} />
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Grid container alignItems="center">
          <Typography variant="h4" component="h4" gutterBottom>
            <Link color="primary" style={{ cursor: 'pointer', paddingRight: 15 }} href={`/applications/${application.id}`}>
              {`${application.appID.toUpperCase()} > `}
            </Link>
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            {device.devID}
          </Typography>
        </Grid>
      </GridItem>
      <CustomTabs
        headerColor="rose"
        tabs={[
          {
            tabName: t('devices.details.tabs.monitors.title'),
            tabIcon: Poll,
            tabContent: (
              <DeviceMonitorsTab
                device={device}
              />
            ),
          },
          {
            tabName: t('devices.details.tabs.settings.title'),
            tabIcon: Info,
            tabContent: (
              <DeviceDetailTab
                device={device}
                canEdit={canEdit}
              />
            ),
          },
          {
            tabName: t('devices.details.tabs.exportIntegrations.title'),
            tabIcon: CloudUploadIcon,
            tabContent: (
              <DeviceExportIntegrationsTab
                device={device}
                canEdit={canEdit}
              />
            ),
          },
          {
            tabName: t('devices.details.tabs.remoteConfigurations.title'),
            tabIcon: CogTransfer,
            tabContent: (
              <DeviceRemoteConfigurationsTab
                device={device}
                canEdit={canEdit}
              />
            ),
          },
          {
            tabName: t('devices.details.tabs.advanced.title'),
            tabIcon: Cog,
            tabContent: (
              <DeviceAdvancedTab
                device={device}
                canEdit={canEdit}
              />
            ),
          },
        ]}
      />
    </GridContainer>
  );
};

export default ApplicationDeviceDetailPage;
