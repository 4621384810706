import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Typography,
} from '@material-ui/core';

import {
  GridItem,
  GridContainer,
  GraphQLErrorWrapper,
  BatteryLevelIcon,
} from '../../generic';

import {
  MeasureCard,
} from '../components';
import {
  formatLocale,
} from '../../utils/date';

import { GroupContext } from '../../group/GroupContext.jsx';
import { AuthenticationContext } from '../../authentication/AuthenticationContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import {
  GROUP_DEVICE_WITH_MEASURE_CHANNELS_QUERY,
  APPLICATION_DEVICE_WITH_MEASURE_CHANNELS_QUERY,
} from '../queries';

const DeviceMonitorRealTimeTab = () => {
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { deviceId } = useParams();
  const { iAmAdmin } = React.useContext(AuthenticationContext);

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICE_WITH_MEASURE_CHANNELS_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICE_WITH_MEASURE_CHANNELS_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return { groupId: group.id, id: deviceId };
    }
    if (application) {
      return { applicationId: application.id, id: deviceId };
    }
    return null;
  }, [group, application, deviceId]);

  const {
    error,
    loading,
    data,
  } = useQuery(QUERY, {
    variables: queryVariables,
    pollInterval: 20000,
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const parent = viewer.application ? viewer.application : viewer.group;
      const { device } = parent;
      const { measureChannels } = device;
      return {
        measureChannels: measureChannels
          .filter((mc) => mc.realLastValue !== null && mc.measureType !== 'notImplemented'),
        device,
      };
    }
    return {
      measureChannels: [],
      device: {},
    };
  }, [data, error, loading]);

  const {
    measureChannels,
    device,
  } = queryResult;

  const getAlarmString = (alarm) => {
    let alarmString;
    switch (alarm) {
      case 4:
        alarmString = 'Underflow';
        break;
      case 5:
        alarmString = 'Not init';
        break;
      case 6:
        alarmString = 'Overflow';
        break;
      case 7:
        alarmString = 'Error';
        break;
      default:
        alarmString = null;
        break;
    }
    return alarmString ? alarmString.toUpperCase() : null;
  };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }
  if (loading) return (<Typography>Loading Data....</Typography>);

  return (
    <GridContainer direction="column" spacing={10}>
      <GridContainer justify="space-evenly" alignContent="center" spacing={5}>
        {(device.lastBatteryLevel || device.lastBatteryLevel === 0) && (
          <GridContainer direction="row" justify="center" alignContent="center">
            <Typography>{`${t('common.lastBatteryLevel')}:\u00A0`}</Typography>
            <BatteryLevelIcon level={device.lastBatteryLevel} fontSize="small" />
          </GridContainer>
        )}
        {device.lastActivityTimestamp && (
          <GridContainer direction="row" justify="center" alignContent="center">
            <Typography>{`${t('common.dateAndTime')}:\u00A0`}</Typography>
            <Typography>{formatLocale(device.lastActivityTimestamp, 'yyyy/MM/dd HH:mm:ss', locale, device.timezone)}</Typography>
          </GridContainer>
        )}
        {(device.dataRate && iAmAdmin) && (
          <GridContainer direction="row" justify="center" alignContent="center">
            <Typography>{`${t('common.dataRate')}:\u00A0`}</Typography>
            <Typography>{device.dataRate}</Typography>
          </GridContainer>
        )}
      </GridContainer>
      <GridContainer>
        {measureChannels && measureChannels.map((measureChannel) => (
          <GridItem xs={12} sm={4} md={3} key={measureChannel.channel}>
            <MeasureCard
              measureType={measureChannel.nameOverride
                || measureChannel.userCode
                || (measureChannel.measureType && t(`enums.measureTypes.${measureChannel.measureType}`))}
              realLastValue={(measureChannel.realLastValue || measureChannel.realLastValue === 0)
                ? measureChannel.realLastValue.toFixed(measureChannel.resolution)
                : null}
              unitOfMeasure={measureChannel.unitOfMeasure}
              alarm={measureChannel.alarm && measureChannel.alarm !== 1 && t(`enums.alarms.level${measureChannel.alarm}`)}
              error={measureChannel.alarm && getAlarmString(measureChannel.alarm)}
              icon={measureChannel.icon || 'mdiChartLine'}
            />
          </GridItem>
        ))}
        {device.rssi && (
          <GridItem xs={12} sm={4} md={3}>
            <MeasureCard
              measureType={t('common.rssi')}
              realLastValue={device.rssi}
              unitOfMeasure="dBm"
              icon="mdiSignalVariant"
            />
          </GridItem>
        )}
        {device.snr && (
          <GridItem xs={12} sm={4} md={3}>
            <MeasureCard
              measureType={t('common.snr')}
              realLastValue={device.snr}
              unitOfMeasure="dB"
              icon="mdiSignalVariant"
            />
          </GridItem>
        )}
      </GridContainer>
    </GridContainer>
  );
};

export default DeviceMonitorRealTimeTab;
