import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  // TextField,
  Typography,
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PERIODS } from '../../enum';

import { clearTypenames } from '../../utils/graphql';

const DeltaCloudExportIntegrationForm = (props) => {
  const {
    deltaCloudExportIntegration,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    control, handleSubmit, setValue, errors,
  } = useForm({
    defaultValues: {
      enabled: false,
      ...deltaCloudExportIntegration,
    },
  });

  const handleFormSubmit = (valuesData) => {
    const { urlResource } = valuesData;
    const fixedValues = {
      ...valuesData,
      urlResource: (urlResource && urlResource.endsWith('/')) ? urlResource.slice(0, -1) : urlResource,
    };
    onFormSubmit(clearTypenames(fixedValues));
  };

  const handleSetDefaultURL = () => {
    setValue('urlResource', 'http://deltaohm.cloud/rest/v1/device', { shouldDirty: true });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.urlResource')}
                placeholder={t('common.urlResource')}
                error={errors.name}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
                InputLabelProps={{ shrink: true }}
              />
            )}
            defaultValue="http://deltaohm.cloud/rest/v1/device"
            id="urlResource"
            name="urlResource"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSetDefaultURL()}
          >
            <Typography>{t('exportIntegrations.deltaCloud.edit.setDefaultURL')}</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>{t('common.period')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="roles-label"
                  fullWidth
                  required
                >
                  <MenuItem key="null" value=""> </MenuItem>
                  {Object.keys(PERIODS).map((key) => (
                    <MenuItem key={PERIODS[key]} value={PERIODS[key]}>
                      {t(`enums.periods.${key}`)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              id="period"
              name="period"
              defaultValue=""
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>{t('common.enabled')}</FormLabel>
            <Controller
              name="enabled"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

DeltaCloudExportIntegrationForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deltaCloudExportIntegration: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

DeltaCloudExportIntegrationForm.defaultProps = {
  deltaCloudExportIntegration: null,
};

export default DeltaCloudExportIntegrationForm;
