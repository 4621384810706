import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  AxisZArrow,
} from 'mdi-material-ui';

import {
  GridItem,
  GridContainer,
  CustomTabs,
} from '../../generic';

import {
  DeviceFTPExportIntegrationEditCard,
  DeviceDeltaCloudExportIntegrationEditCard,
} from '../components';

const DeviceExportIntegrationsTab = (props) => {
  const { device, canEdit } = props;
  const { t } = useTranslation('translations');

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="rose"
          tabs={[
            {
              tabName: t('exportIntegrations.ftp.title'),
              tabIcon: AxisZArrow,
              tabContent: (
                <DeviceFTPExportIntegrationEditCard
                  device={device}
                  canEdit={canEdit}
                />
              ),
            },
            {
              tabName: t('exportIntegrations.deltaCloud.title'),
              tabIcon: AxisZArrow,
              tabContent: (
                <DeviceDeltaCloudExportIntegrationEditCard
                  device={device}
                  canEdit={canEdit}
                />
              ),
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  // eslint-disable-next-line react/require-default-props, react/forbid-prop-types
  device: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
};

DeviceExportIntegrationsTab.propTypes = propTypes;

export default DeviceExportIntegrationsTab;
