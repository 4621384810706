import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import ExitToApp from '@material-ui/icons/ExitToApp';
import {
  ExitRun,
} from 'mdi-material-ui';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

import config from '../../config';

import CustomButton from '../CustomButton';

import styles from '../../assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';

const useStyles = makeStyles(styles);

const AdminNavbarLinks = () => {
  const { t } = useTranslation('translations');
  const classes = useStyles();

  const { logout, currentUser } = React.useContext(AuthenticationContext);
  const [askLogoutVisible, setAskLogoutVisible] = React.useState(false);

  const handleLogout = () => {
    setAskLogoutVisible(false);
    logout();
  };

  return (
    <div>
      {window.innerWidth > 863 && (
        <div className={classes.manager}>
          <a rel="noopener noreferrer" target="_blank" href={config.edit_profile_uri}>
            <Grid container wrap="nowrap" spacing={1} justify="center" alignItems="center" style={{ paddingRight: 5 }}>
              <Grid item xs zeroMinWidth>
                <Typography style={{ color: 'white' }} noWrap>{currentUser.displayName}</Typography>
                <Typography style={{ color: 'white' }} noWrap>{currentUser.email}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.small} />
              </Grid>
            </Grid>
          </a>
        </div>
      )}

      <div className={classes.manager}>
        <CustomButton
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-haspopup="true"
          onClick={() => setAskLogoutVisible(true)}
          className={classes.buttonLink}
          size="lg"
        >
          <ExitRun />
          {/* <ExitToApp fontSize="large" /> */}
          {/* <Typography className={classes.linkText}>{t('common.logout')}</Typography> */}
        </CustomButton>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={askLogoutVisible}
        onClose={() => setAskLogoutVisible(false)}
      >
        <DialogTitle id="draggable-dialog-title">
          {t('common.confirmLogout')}
        </DialogTitle>
        <DialogActions>
          <CustomButton autoFocus onClick={() => setAskLogoutVisible(false)} color="info">
            {t('common.cancel')}
          </CustomButton>
          <CustomButton onClick={() => handleLogout()} color="primary">
            {t('common.logout')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminNavbarLinks;
