import { gql } from '@apollo/client';
import { DEVICE_LIST_ITEM_FRAGMENT } from '../device/queries';

// eslint-disable-next-line import/prefer-default-export
export const DEVICES_QUERY = gql`
  query devicesQuery($afterCursor: String, $pageSize: Int!, $filter: AllDeviceFilter) {
    viewer {
      devices(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...DeviceListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${DEVICE_LIST_ITEM_FRAGMENT}
`;
