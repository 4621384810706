import { gql } from '@apollo/client';
import { DEVICE_DETAIL_FRAGMENT } from '../device/queries';
import {
  DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT,
  FTP_EXPORT_INTEGRATION_FRAGMENT,
} from '../exportIntegration/queries';

export const GROUP_LIST_ITEM_FRAGMENT = gql`
  fragment GroupListItemFragment on Group {
    id
    name
    timezone
    onlySIUnits
    owner
    updatedAt
    deletedAt
  }
`;

export const GROUP_DETAIL_FRAGMENT = gql`
  fragment GroupDetailFragment on Group {
    id
    name
    owner
    onlySIUnits
    timezone
    deletedAt
    iAmOwner
    myRoles
    userPermissions {
      email
      roles
    }
    groupInvites {
      id
      email
      roles
    }
    ownershipInvite
    deltaCloudExportIntegration {
      ...DeltaCloudExportIntegrationFragment
    }
    ftpExportIntegration {
      ...FtpExportIntegrationFragment
    }
    createdAt
    updatedAt
    deletedAt
  },
  ${DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT}
  ${FTP_EXPORT_INTEGRATION_FRAGMENT}
`;

export const GROUPS_QUERY = gql`
  query groupsQuery($afterCursor: String, $pageSize: Int!, $filter: GroupFilter) {
    viewer {
      groups(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...GroupListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${GROUP_LIST_ITEM_FRAGMENT}
`;

export const GROUP_QUERY = gql`
  query groupQuery($id: ID!) {
    viewer {
      group(id: $id) {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_DEVICES_QUERY = gql`
  query groupDevicesQuery($afterCursor: String, $pageSize: Int!, $groupId: ID!, $filter: DeviceFilter) {
    viewer {
      group(id: $groupId) {
        devices(first: $pageSize, after: $afterCursor, filter: $filter) {
          edges {
            node {
              ...DeviceDetailFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const GROUP_CREATE_MUTATION = gql`
  mutation GroupCreateMutation($input: GroupCreateInput!) {
    groupCreate(input: $input) {
      group {
        ...GroupListItemFragment
      }
    }
  }
  ${GROUP_LIST_ITEM_FRAGMENT}
`;

export const GROUP_UPDATE_MUTATION = gql`
  mutation GroupUpdateMutation($input: GroupUpdateInput!) {
    groupUpdate(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_DELETE_MUTATION = gql`
  mutation GroupDeleteMutation($input: GroupDeleteInput!) {
    groupDelete(input: $input) {
      success
    }
  }
`;

export const GROUP_RESTORE_MUTATION = gql`
  mutation GroupRestoreMutation($input: GroupRestoreInput!) {
    groupRestore(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_UPDATE_USER_PERMISSION_MUTATION = gql`
  mutation GroupUpdateUserPermissionMutation($input: GroupUpdateUserPermissionInput!) {
    groupUpdateUserPermission(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_DELETE_USER_PERMISSION_MUTATION = gql`
  mutation GroupDeleteUserPermissionMutation($input: GroupDeleteUserPermissionInput!) {
    groupDeleteUserPermission(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_CREATE_OWNERSHIP_INVITE_MUTATION = gql`
  mutation GroupCreateOwnershipInviteMutation($input: GroupCreateOwnershipInviteInput!) {
    groupCreateOwnershipInvite(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_DELETE_OWNERSHIP_INVITE_MUTATION = gql`
  mutation GroupDeleteOwnershipInviteMutation($input: GroupDeleteOwnershipInviteInput!) {
    groupDeleteOwnershipInvite(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_ACCEPT_OWNERSHIP_INVITE_MUTATION = gql`
  mutation GroupAcceptOwnershipInviteMutation($input: GroupAcceptOwnershipInviteInput!) {
    groupAcceptOwnershipInvite(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION = gql`
  mutation GroupUpdateDeltaCloudExportIntegrationMutation($input: GroupUpdateDeltaCloudExportIntegrationInput!) {
    groupUpdateDeltaCloudExportIntegration(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_UPDATE_FTP_EXPORT_INTEGRATION_MUTATION = gql`
  mutation GroupUpdateFTPExportIntegrationMutation($input: GroupUpdateFTPExportIntegrationInput!) {
    groupUpdateFTPExportIntegration(input: $input) {
      group {
        ...GroupDetailFragment
      }
    }
  }
  ${GROUP_DETAIL_FRAGMENT}
`;

export const GROUP_PROPAGATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION = gql`
  mutation GroupPropagateDeltaCloudExportIntegrationMutation($input: GroupPropagateDeltaCloudExportIntegrationInput!) {
    groupPropagateDeltaCloudExportIntegration(input: $input) {
      success
    }
  }
`;

export const GROUP_PROPAGATE_FTP_EXPORT_INTEGRATION_MUTATION = gql`
  mutation GroupPropagateFTPExportIntegrationMutation($input: GroupPropagateFTPExportIntegrationInput!) {
    groupPropagateFTPExportIntegration(input: $input) {
      success
    }
  }
`;

export const GROUP_PROPAGATE_SETTINGS_MUTATION = gql`
  mutation GroupPropagateSettingsMutation($input: GroupPropagateSettingsInput!) {
    groupPropagateSettings(input: $input) {
      success
    }
  }
`;
