import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import config from './config';

import en from './locales/en.js';
import it from './locales/it.js';
import es from './locales/es.js';
import fr from './locales/fr.js';

const { defaultLanguage } = config;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
    },
    fallbackLng: defaultLanguage,
    resources: {
      ...en,
      ...it,
      ...es,
      ...fr,
    },
    // debug: true,
  });

export default i18n;
