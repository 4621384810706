/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  TableCell,
  Typography,
  TextField,
} from '@material-ui/core';
import {
  ArrowUpward,
  ArrowDownward,
  // ArrowDropDown,
  // ArrowDropUp,
} from '@material-ui/icons';
// import Icon from '@material-ui/core/Icon';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';

const CommandCommunicationsTable = (props) => {
  const {
    columnsHeaders,
    rows,
  } = props;
  const rowHeight = 40;
  const headerHeight = 30;

  const columns = React.useMemo(() => (
    columnsHeaders.map((ch, index) => {
      let width = 150;
      if ([0].includes(index)) width = 200;
      if ([1].includes(index)) width = 75;
      if ([2].includes(index)) width = 50;
      if ([3].includes(index)) width = 500;
      if ([4].includes(index)) width = 105;
      return {
        ...ch,
        width,
        label: ch.label,
        dataKey: ch.label,
      };
    })
  ), [columnsHeaders]);

  const cellRenderer = ({ cellData, label }) => {
    let cellContent;
    if (['string', 'number'].includes(typeof cellData)) {
      cellContent = <Typography align="left">{cellData}</Typography>;
    }
    if (label === 'isReceived') {
      cellContent = cellData ? <ArrowUpward /> : <ArrowDownward color="secondary" />;
    }
    if (label === 'payload') {
      cellContent = <TextField fullWidth type="text" value={cellData} />;
    }
    return (
      <TableCell
        component="div"
        variant="body"
        style={{
          height: rowHeight,
          display: 'flex',
          boxSizing: 'border-box',
          flex: 1,
        }}
        align="left"
      >
        {cellContent}
      </TableCell>
    );
  };
  // cellData ? (<Icon>arrow_drop_down</Icon>) : (<Icon>arrow_drop_up_circle</Icon>)

  const headerRenderer = ({
    label,
  }) => (
    <TableCell
      component="div"
      variant="head"
      style={{
        display: 'block',
        height: headerHeight,
        textTransform: 'none !important',
        flex: 'none !important',
      }}
      align="left"
    >
      <Typography align="left">{label !== 'isReceived' ? label : ' '}</Typography>
    </TableCell>
  );

  const MIN_TABLE_WIDTH = columns.length * 150;

  return (
    <Paper
      style={{
        height: 600, width: '100%', overflow: 'auto',
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width < MIN_TABLE_WIDTH ? MIN_TABLE_WIDTH : width}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
          >
            {columns.map((column) => (
              <Column
                width={column.width}
                label={column.label}
                dataKey={column.dataKey}
                key={column.dataKey}
                headerRenderer={(headerProps) => headerRenderer({
                  ...headerProps,
                  label: column.label,
                })}
                cellRenderer={(cellProps) => cellRenderer({
                  ...cellProps,
                  label: column.label,
                })}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Paper>
  );
};

CommandCommunicationsTable.propTypes = {
  columnsHeaders: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  rows: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default CommandCommunicationsTable;
