import React from 'react';
import PropTypes from 'prop-types';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import {
  ApplicationDevicesList,
} from '../components';

const ApplicationDevicesTab = (props) => {
  const { application, canEdit } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <ApplicationDevicesList
          application={application}
          canEdit={canEdit}
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ApplicationDevicesTab.propTypes = propTypes;

export default ApplicationDevicesTab;
