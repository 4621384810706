import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

const ApplicationSearcher = (props) => {
  const { onSearch } = props;
  const { t } = useTranslation('translations');

  const debounceUpdate = useDebouncedCallback((searchText) => {
    onSearch(searchText);
  }, 500);

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === null || newValue.trim() === '') {
      onSearch(null);
    }
    else {
      debounceUpdate.callback(newValue);
    }
  };

  return (
    <TextField
      onChange={handleChange}
      label={t('common.filter')}
      type="search"
      fullWidth
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

const propTypes = {
  onSearch: PropTypes.func.isRequired,
};

ApplicationSearcher.propTypes = propTypes;

export default ApplicationSearcher;
