import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
} from '@material-ui/core';

import Menu from '@material-ui/icons/Menu';

import CustomButton from '../CustomButton';

import styles from '../../assets/jss/material-dashboard-react/components/headerStyle.js';

import AdminNavbarLinks from './AdminNavbarLinks';

const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();

  function makeBrand() {
    let name;

    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.path) !== -1) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }

  const { handleDrawerToggle } = props;

  const appBarClasses = classNames({
    [` ${classes.info}`]: 'info',
  });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* il pulsante aveva il capo href={#} da capire se mettere un breadcrumb o altro */}
          <CustomButton color="transparent" className={classes.title}>
            {makeBrand()}
          </CustomButton>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const propTypes = {
  // color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']).isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Header.propTypes = propTypes;

export default Header;
