import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { extractGraphQLErrors } from '../utils/graphql';

const GraphQLErrorWrapper = (props) => {
  const { t } = useTranslation('translations');
  const {
    error,
  } = props;

  if (!error) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!!error}
      autoHideDuration={6000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="error"
      >
        {extractGraphQLErrors(error, t)}
      </MuiAlert>
    </Snackbar>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
};

const defaultProps = {
  error: null,
};

GraphQLErrorWrapper.propTypes = propTypes;

GraphQLErrorWrapper.defaultProps = defaultProps;

export default GraphQLErrorWrapper;
