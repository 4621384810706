import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  Checkbox,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  RF_LOG_INTERVALS,
  MEASURE_INTERVALS,
} from '../../enum';

import { clearTypenames } from '../../utils/graphql';
import { TimezoneSelector } from '../../generic';

const DeviceForm = (props) => {
  const {
    device,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  // TODO check se va anche su IE
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: device
      ? {
        ...device,
      }
      : {
        timezone: tz || '',
      },
  });

  const handleFormSubmit = (values) => {
    onFormSubmit(clearTypenames({
      id: device.id,
      userCode: values.userCode,
      timezone: values.timezone,
      onlySIUnits: values.onlySIUnits,
    }));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.devID')}
                placeholder={t('common.devID')}
                error={errors.devID}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
                disabled
              />
            )}
            id="devID"
            defaultValue=""
            name="devID"
            control={control}
          />
          {errors.devID && <p>{t('common.requiredField')}</p>}
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.devEUI')}
                placeholder={t('common.devEUI')}
                error={errors.devEUI}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
                disabled
              />
            )}
            id="devEUI"
            defaultValue=""
            name="devEUI"
            control={control}
          />
          {errors.devEUI && <p>{t('common.requiredField')}</p>}
        </Grid>

        <Grid item xs={3}>
          <TimezoneSelector
            id="timezone"
            name="timezone"
            control={control}
          />
          {errors.timezone && <p>{t('common.requiredField')}</p>}
        </Grid>
        {/* <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.groupName')}
                placeholder={t('common.groupName')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
        )}
            id="groupName"
            defaultValue=""
            name="groupName"
            control={control}
          />
        </Grid> */}
        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.userCode')}
                placeholder={t('common.userCode')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="userCode"
            defaultValue=""
            name="userCode"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.rainTip')}
                placeholder={t('common.rainTip')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="rainTip"
            defaultValue=""
            name="rainTip"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <FormLabel>{t('common.onlySIUnits')}</FormLabel>
            <Controller
              name="onlySIUnits"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.firmwareVersion')}
                placeholder={t('common.firmwareVersion')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="firmwareVersion"
            defaultValue=""
            name="firmwareVersion"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.firmwareDate')}
                placeholder={t('common.firmwareDate')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="firmwareDate"
            defaultValue=""
            name="firmwareDate"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.model')}
                placeholder={t('common.model')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="model"
            defaultValue=""
            name="model"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            as={(
              <TextField
                label={t('common.serialNumber')}
                placeholder={t('common.serialNumber')}
                fullWidth
                autoCapitalize="none"
                disabled
              />
            )}
            id="serialNumber"
            defaultValue=""
            name="serialNumber"
            control={control}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>{t('common.RFLogInterval')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="RFLogIntervals"
                  fullWidth
                  required
                >
                  <MenuItem key="null" value=""> </MenuItem>
                  {Object.keys(RF_LOG_INTERVALS)
                    .map((key) => (
                      <MenuItem key={key} value={RF_LOG_INTERVALS[key]}>
                        {t(`enums.intervals.${key}`)}
                      </MenuItem>
                    ))}
                </Select>
                    )}
              id="RFLogInterval"
              name="RFLogInterval"
              defaultValue=""
              control={control}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>{t('common.measureInterval')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="measureIntervals"
                  fullWidth
                  required
                >
                  <MenuItem key="null" value=""> </MenuItem>
                  {Object.keys(MEASURE_INTERVALS)
                    .map((key) => (
                      <MenuItem key={key} value={MEASURE_INTERVALS[key]}>
                        {t(`enums.intervals.${key}`)}
                      </MenuItem>
                    ))}
                </Select>
                    )}
              id="measureInterval"
              name="measureInterval"
              defaultValue=""
              control={control}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormLabel>{t('common.protocolType')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="protocolTypes"
                  fullWidth
                  required
                >
                  <MenuItem key="null" value=""> </MenuItem>
                  <MenuItem key="Cayenne" value={0}>Cayenne LPP</MenuItem>
                  <MenuItem key="Proprietary" value={1}>Proprietary protocol</MenuItem>
                </Select>
                    )}
              id="protocolType"
              name="protocolType"
              defaultValue={1}
              control={control}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <FormLabel>{t('common.timeSynchronization')}</FormLabel>
            <Controller
              name="timeSynchronization"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  disabled
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <FormLabel>{t('common.loggingActive')}</FormLabel>
            <Controller
              name="loggingActive"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  disabled
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <FormLabel>{t('common.cyclicLogging')}</FormLabel>
            <Controller
              name="cyclicLogging"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  disabled
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl>
            <FormLabel>{t('common.buzzerAlarm')}</FormLabel>
            <Controller
              name="buzzerAlarm"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  disabled
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>

        </Grid>
      </Grid>
    </form>
  );
};

DeviceForm.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    devID: PropTypes.string,
    devEUI: PropTypes.string,
    timezone: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

DeviceForm.defaultProps = {
  device: {
    application: null,
  },
};

export default DeviceForm;
