import { gql } from '@apollo/client';

export const DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT = gql`
  fragment DeltaCloudExportIntegrationFragment on DeltaCloudExportIntegration {
    enabled
    period
    urlResource
  }
`;

export const FTP_EXPORT_INTEGRATION_FRAGMENT = gql`
  fragment FtpExportIntegrationFragment on FTPExportIntegration {
    enabled
    period
    server
    ftpPort
    ftpUsername
    ftpPassword
    directory
    directoryForEveryDevice
  }
`;

export const FTP_EXPORT_INTEGRATION_TEST_MUTATION = gql`
  mutation FTPExportIntegrationTestMutation($input: FTPExportIntegrationTestInput!) {
    FTPExportIntegrationTest(input: $input) {
      success
    }
  }
`;

export const FTP_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION = gql`
  mutation FTPExportIntegrationTestBetweenDatetimeMutation($input: FTPExportIntegrationTestBetweenDatetimeInput!) {
    FTPExportIntegrationTestBetweenDatetime(input: $input) {
      success
    }
  }
`;

export const FTP_EXPORT_INTEGRATION_RESET_LAST_MUTATION = gql`
  mutation FTPExportIntegrationResetLastMutation($input: FTPExportIntegrationResetLastInput!) {
    FTPExportIntegrationResetLast(input: $input) {
      success
    }
  }
`;

export const FTP_EXPORT_INTEGRATION_TEST_JOB_MUTATION = gql`
  mutation FTPExportIntegrationTestJobMutation($input: FTPExportIntegrationTestJobInput!) {
    FTPExportIntegrationTestJob(input: $input) {
      success
    }
  }
`;

export const DELTA_CLOUD_EXPORT_INTEGRATION_TEST_MUTATION = gql`
  mutation DeltaCloudExportIntegrationTestMutation($input: DeltaCloudExportIntegrationTestInput!) {
    deltaCloudExportIntegrationTest(input: $input) {
      success
    }
  }
`;

export const DELTA_CLOUD_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION = gql`
  mutation DeltaCloudExportIntegrationTestBetweenDatetimeMutation($input: DeltaCloudExportIntegrationTestBetweenDatetimeInput!) {
    deltaCloudExportIntegrationTestBetweenDatetime(input: $input) {
      success
    }
  }
`;

export const DELTA_CLOUD_EXPORT_INTEGRATION_RESET_LAST_MUTATION = gql`
  mutation DeltaCloudExportIntegrationResetLastMutation($input: DeltaCloudExportIntegrationResetLastInput!) {
    deltaCloudExportIntegrationResetLast(input: $input) {
      success
    }
  }
`;

export const DELTA_CLOUD_EXPORT_INTEGRATION_TEST_JOB_MUTATION = gql`
  mutation DeltaCloudExportIntegrationTestJobMutation($input: DeltaCloudExportIntegrationTestJobInput!) {
    deltaCloudExportIntegrationTestJob(input: $input) {
      success
    }
  }
`;
