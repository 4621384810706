import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { startOfDay } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import {
  getLocale,
} from '../../utils/date';
import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import {
  DELTA_CLOUD_EXPORT_INTEGRATION_TEST_MUTATION,
  DELTA_CLOUD_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION,
  DELTA_CLOUD_EXPORT_INTEGRATION_RESET_LAST_MUTATION,
  DELTA_CLOUD_EXPORT_INTEGRATION_TEST_JOB_MUTATION,
} from '../queries';

const DeltaCloudExportIntegrationTestingCard = (props) => {
  const {
    id,
  } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { currentUser } = React.useContext(AuthenticationContext);

  const [successOpen, setSuccessOpen] = React.useState(false);
  const [startRange, setStartRange] = React.useState(startOfDay(new Date()));
  const [endRange, setEndRange] = React.useState(new Date());
  const [resetLastDatetime, setResetLastDatetime] = React.useState(new Date());

  const [
    deltaCloudExportIntegrationTestMutation,
    {
      loading,
      error,
    },
  ] = useMutation(DELTA_CLOUD_EXPORT_INTEGRATION_TEST_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    deltaCloudExportIntegrationTestBetweenDatetimeMutation,
    {
      loading: betweenDatetimeLoading,
      error: betweenDatetimeError,
    },
  ] = useMutation(DELTA_CLOUD_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    deltaCloudExportIntegrationResetLastMutation,
    {
      loading: resetLastLoading,
      error: resetLastError,
    },
  ] = useMutation(DELTA_CLOUD_EXPORT_INTEGRATION_RESET_LAST_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    deltaCloudExportIntegrationTestJobMutation,
    {
      loading: testJobLoading,
      error: testJobError,
    },
  ] = useMutation(DELTA_CLOUD_EXPORT_INTEGRATION_TEST_JOB_MUTATION);

  const handleTestSubmit = async () => {
    try {
      const result = await deltaCloudExportIntegrationTestMutation({
        variables: {
          input: {
            id,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleTestBetweenDatetimeSubmit = async () => {
    try {
      const result = await deltaCloudExportIntegrationTestBetweenDatetimeMutation({
        variables: {
          input: {
            id,
            from: startRange,
            to: endRange,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleResetLastSubmit = async () => {
    try {
      const result = await deltaCloudExportIntegrationResetLastMutation({
        variables: {
          input: {
            id,
            date: resetLastDatetime,
            overrideGroupSetting: true,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleTestJobSubmit = async () => {
    try {
      const result = await deltaCloudExportIntegrationTestJobMutation({
        variables: {
          input: {
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <CardWrapper
      cardTitle={t('exportIntegrations.deltaCloud.test.title')}
      style={{ marginTop: 40 }}
      cardBody={
        (
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper
              error={
                error || testJobError || betweenDatetimeError || resetLastError || testJobError
              }
            />
            <Loader
              loading={
                // eslint-disable-next-line max-len
                loading || testJobLoading || betweenDatetimeLoading || resetLastLoading || testJobLoading
              }
            />
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleTestSubmit()}
                >
                  <Typography>{t('exportIntegrations.deltaCloud.test.testDeltaCloud')}</Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      style={{ height: 30 }}
                      value={startRange}
                      onChange={setStartRange}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      value={endRange}
                      onChange={setEndRange}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleTestBetweenDatetimeSubmit()}
                  >
                    <Typography>
                      {t('exportIntegrations.deltaCloud.test.testDeltaCloudBetweenDatetime')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      value={resetLastDatetime}
                      onChange={setResetLastDatetime}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleResetLastSubmit()}
                  >
                    <Typography>{t('exportIntegrations.deltaCloud.test.resetLast')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {false && currentUser.email === 'emmanuele.gonzato@geekcups.com' && (
              // solo per sviluppo
              <Grid item xs={12}>
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleTestJobSubmit()}
                  >
                    <Typography>TEST JOB</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        )
      }
    />
  );
};

DeltaCloudExportIntegrationTestingCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DeltaCloudExportIntegrationTestingCard;
