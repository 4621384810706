import React from 'react';

import {
  GridItem,
  GridContainer,
} from '../generic';

import {
  AllDevicesList,
} from './components';

const AllDevicesListPage = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <AllDevicesList />
    </GridItem>
  </GridContainer>
);

export default AllDevicesListPage;
