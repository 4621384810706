import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import { GroupContext } from '../../group/GroupContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import DeviceChangeGroupForm from './DeviceChangeGroupForm';
import DeviceUnlinkApplicationForm from './DeviceUnlinkApplicationForm';
import DeviceUnlinkGroupForm from './DeviceUnlinkGroupForm';

import {
  APPLICATION_DEVICES_QUERY,
} from '../../application/queries';
import {
  GROUP_DEVICES_QUERY,
} from '../../group/queries';

import {
  DEVICE_CHANGE_GROUP_MUTATION,
  DEVICE_UNLINK_GROUP_MUTATION,
  DEVICE_UNLINK_APPLICATION_MUTATION,
} from '../queries';

const DeviceEditCard = (props) => {
  const { device, canEdit } = props;
  const { t } = useTranslation('translations');
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [successOpen, setSuccessOpen] = React.useState(false);

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICES_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICES_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return {
        groupId: group.id,
        pageSize: 20,
        filter: {},
      };
    }
    if (application) {
      return {
        applicationId: application.id,
        pageSize: 20,
        filter: {},
      };
    }
    return null;
  }, [group, application]);

  const [
    unlinkApplicationMutationAction,
    {
      loading: unlinApplicationLoading,
      error: unlinkApplicationError,
    },
  ] = useMutation(DEVICE_UNLINK_APPLICATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: QUERY,
          variables: queryVariables,
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    unlinkGroupMutationAction,
    {
      loading: unlinGroupLoading,
      error: unlinkGroupError,
    },
  ] = useMutation(DEVICE_UNLINK_GROUP_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: QUERY,
          variables: queryVariables,
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    changeGroupMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(DEVICE_CHANGE_GROUP_MUTATION);

  const handleChangeGroupFormSubmit = async (data) => {
    try {
      const result = await changeGroupMutationAction({
        variables: {
          input: {
            id: deviceId,
            groupId: data.groupId,
          },
        },
      });
      if (result) {
        setSuccessOpen(true);
        navigate(`/groups/${data.groupId}`);
      }
    }
    catch (e) { } // eslint-disable-line
  };

  const handleUnlinkApplicationFormSubmit = async () => {
    try {
      const result = await unlinkApplicationMutationAction({
        variables: {
          input: {
            id: deviceId,
          },
        },
      });
      if (result) {
        setSuccessOpen(true);
        navigate(`/applications/${application.id}`);
      }
    }
    catch (e) { } // eslint-disable-line
  };

  const handleUnlinkGroupFormSubmit = async () => {
    try {
      const result = await unlinkGroupMutationAction({
        variables: {
          input: {
            id: deviceId,
          },
        },
      });
      if (result) {
        setSuccessOpen(true);
        navigate(`/groups/${group.id}`);
      }
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <CardWrapper
      cardTitle={t('devices.edit.title')}
      cardBody={(
        <>
          <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
          <GraphQLErrorWrapper error={error || unlinkApplicationError || unlinkGroupError} />
          <Loader loading={loading || unlinApplicationLoading || unlinGroupLoading} />
          <Grid container spacing={8}>
            {application && (
              <Grid item xs={12}>
                <DeviceUnlinkApplicationForm
                  device={device}
                  canEdit={canEdit}
                  onFormSubmit={handleUnlinkApplicationFormSubmit}
                />
              </Grid>
            )}
            {group && (

            <Grid item xs={12}>
              <DeviceChangeGroupForm
                device={device}
                canEdit={canEdit}
                onFormSubmit={handleChangeGroupFormSubmit}
              />
            </Grid>
            )}
            {group && (

            <Grid item xs={12}>
              <DeviceUnlinkGroupForm
                device={device}
                canEdit={canEdit}
                onFormSubmit={handleUnlinkGroupFormSubmit}
              />
            </Grid>
            )}
          </Grid>
        </>
      )}
    />
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

DeviceEditCard.propTypes = propTypes;

export default DeviceEditCard;
