import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-dashboard-react/components/cardStyle.js';

const useStyles = makeStyles(styles);

const Card = (props) => {
  const classes = useStyles();
  const {
    className, children, ...rest
  } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [className]: className !== undefined,
  });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  className: undefined,
};

Card.propTypes = propTypes;

Card.defaultProps = defaultProps;

export default Card;
