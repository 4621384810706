import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import { GroupContext } from '../../group/GroupContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import {
  DeltaCloudExportIntegrationForm,
  DeltaCloudExportIntegrationTestingCard,
} from '../../exportIntegration/components';

import {
  APPLICATION_DEVICE_QUERY,
  GROUP_DEVICE_QUERY,
  DEVICE_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION,
} from '../queries';

const DeviceDeltaCloudExportIntegrationEditCard = (props) => {
  const { device, canEdit } = props;
  const { t } = useTranslation('translations');
  const { deviceId } = useParams();
  const [successOpen, setSuccessOpen] = React.useState(false);

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICE_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICE_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return { groupId: group.id, id: deviceId };
    }
    if (application) {
      return { applicationId: application.id, id: deviceId };
    }
    return null;
  }, [group, application, deviceId]);

  const [
    deviceUpdateDeltaCloudExportIntegrationMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(DEVICE_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: QUERY,
          variables: queryVariables,
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleFormSubmit = async (data) => {
    try {
      const result = await deviceUpdateDeltaCloudExportIntegrationMutationAction({
        variables: {
          input: {
            ...data,
            id: deviceId,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <>
      <CardWrapper
        cardTitle={t('exportIntegrations.deltaCloud.edit.title')}
        cardBody={
          (
            <>
              <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
              <GraphQLErrorWrapper error={error} />
              <Loader loading={loading} />
              <DeltaCloudExportIntegrationForm
                canEdit={canEdit}
                deltaCloudExportIntegration={device.deltaCloudExportIntegration}
                onFormSubmit={handleFormSubmit}
              />
            </>
          )
        }
      />
      <DeltaCloudExportIntegrationTestingCard
        id={device.id}
      />
    </>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

DeviceDeltaCloudExportIntegrationEditCard.propTypes = propTypes;

export default DeviceDeltaCloudExportIntegrationEditCard;
