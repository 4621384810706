import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import {
  Button,
  TextField,
  Typography,
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PERIODS } from '../../enum';

import { clearTypenames } from '../../utils/graphql';

const FTPExportIntegrationForm = (props) => {
  const {
    ftpExportIntegration,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');
  const { deviceId } = useParams();
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: {
      enabled: false,
      directoryForEveryDevice: false,
      ...ftpExportIntegration,
    },
  });

  const handleFormSubmit = (values) => {
    const { directory } = values;
    let sanitizedDirectory = directory;
    sanitizedDirectory = directory.endsWith('/') ? sanitizedDirectory.slice(0, -1) : sanitizedDirectory;
    sanitizedDirectory = directory.startsWith('/') ? sanitizedDirectory : `/${sanitizedDirectory}`;
    const fixedValues = {
      ...values,
      directory: sanitizedDirectory,
      ftpPort: parseInt(values.ftpPort, 10),
    };
    onFormSubmit(clearTypenames(fixedValues));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.server')}
                placeholder={t('common.server')}
                error={errors.name}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
              />
          )}
            id="server"
            name="server"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.ftpPort')}
                placeholder={t('common.ftpPort')}
                error={errors.ftpPort}
                fullWidth
                required
                type="number"
                inputProps={{
                  min: 0,
                }}
              />
        )}
            id="ftpPort"
            name="ftpPort"
            control={control}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.ftpUsername')}
                placeholder={t('common.ftpUsername')}
                error={errors.ftpUsername}
                fullWidth
                required
                autoCapitalize="none"
              />
        )}
            id="ftpUsername"
            name="ftpUsername"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            render={(passwordProps) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-password">
                  {t('common.ftpPassword')}
                </InputLabel>
                <Input
                  value={passwordProps.value}
                  onChange={(e) => passwordProps.onChange(e.target.value)}
                  // placeholder={t('common.ftpPassword')}
                  error={errors.ftpPassword}
                  fullWidth
                  required
                  autoCapitalize="none"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            )}
            id="ftpPassword"
            name="ftpPassword"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.directory')}
                placeholder={t('common.directory')}
                error={errors.directory}
                fullWidth
                required
                autoCapitalize="none"
              />
        )}
            id="directory"
            name="directory"
            control={control}
          />
        </Grid>
        {!deviceId && (
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>{t('common.directoryForEveryDevice')}</FormLabel>
              <Controller
                name="directoryForEveryDevice"
                control={control}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>{t('common.period')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="roles-label"
                  fullWidth
                  required
                >
                  <MenuItem key="null" value=""> </MenuItem>
                  {Object.keys(PERIODS)
                    .filter((key) => PERIODS[key] > 0)
                    .map((key) => (
                      <MenuItem key={key} value={PERIODS[key]}>
                        {t(`enums.periods.${key}`)}
                      </MenuItem>
                    ))}
                </Select>
          )}
              id="period"
              name="period"
              defaultValue=""
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel>{t('common.enabled')}</FormLabel>
            <Controller
              name="enabled"
              control={control}
              render={(checkboxProps) => (
                <Checkbox
                  onChange={(e) => checkboxProps.onChange(e.target.checked)}
                  checked={checkboxProps.value}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

FTPExportIntegrationForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ftpExportIntegration: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
};

FTPExportIntegrationForm.defaultProps = {
  ftpExportIntegration: null,
};

export default FTPExportIntegrationForm;
