export default {
  es: {
    translations: {
      common: {
        ok: 'Ok',
        yes: 'Sí',
        no: 'No',
        areYouSure: '¿Está seguro?',
        confirmLogout: '¿Seguro que quieres salir?',
        cancel: 'Cancelar',
        actions: 'Acciones',
        save: 'Guardar',
        delete: 'Borrar',
        restore: 'Restablece',
        loadMore: 'Vuelve a cargar',
        filter: 'Filtrar',
        filterByName: 'Filtrar por nombre',
        send: 'Enviar',
        resend: 'Reenviar',
        create: 'Crear',
        error: 'ERROR',
        pending: 'Esperando...',

        application: 'Aplicación',
        applications: 'Aplicaciones',
        group: 'Grupo',
        groups: 'Grupos',
        detail: 'Detalle',
        details: 'Detalles',
        logout: 'Salir',
        defaultGroupName: 'Por defecto',

        requiredField: 'Campo Obligatorio',
        roles: 'Roles',
        email: 'Correo electrónico',
        name: 'Nombre',
        timezone: 'Zona horaria',
        owner: 'Propietario',
        server: 'Servidor',
        ftpPort: 'Puerto FTP',
        ftpUsername: 'Nombre de usuario FTP',
        ftpPassword: 'Contraseña FTP',
        period: 'Periodo',
        directory: 'Directorio',
        directoryForEveryDevice: 'Directorio para cada dispositivo',
        enabled: 'Activado',
        urlResource: 'URL del recurso',

        devID: 'DEV ID',
        devEUI: 'DEV EUI',
        appID: 'APP ID',
        appEUI: 'APP EUI',
        description: 'Descripción',
        url: 'URL',
        processID: 'Identificación del proceso',
        accessKey: 'Clave de acceso',
        rssi: 'RSSI',
        snr: 'SNR',
        dataRate: 'Velocidad de datos',
        lastBatteryLevel: 'Nivel de Batteria',
        lastActivityTimestamp: 'Estado',
        dateAndTime: 'DDatos e ora',
        serialNumber: 'Número de serie',
        model: 'Modelo',
        userCode: 'Código de usuario',
        groupName: 'Nombre del grupo',
        rainTip: 'Consejo de la lluvia',
        firmwareVersion: 'Versión FW',
        firmwareDate: 'Fecha FW',
        onlySIUnits: 'SOLO UNIDAD SI',
        isTimeSynchActive: 'Sincronizacion de tiempo',
        RFLogInterval: 'Intervalo RF/registro',
        measureInterval: 'Intervalo de medida',
        protocolType: 'Tipo de protocolo',
        timeSynchronization: 'Sincronización de tiempo',
        loggingActive: 'Registro activo',
        cyclicLogging: 'Registro circular',
        buzzerAlarm: 'Alarma de zumbador',
        refreshAllInfo: 'Actualizar toda la información',
        testBuzzer: 'Probar el zumbador',
        ignorePendingCommands: 'IGNORAR COMANDOS PENDIENTES',
        askHistoricalData: 'pedir datos historicos',
        devEUIorSerialNumber: 'DEV EUI / Número de serie',

        channels: 'Canales',
        status: 'Estado',
        measureType: 'Tipo de medida',
        unitOfMeasure: 'Unidad de medida',
        resolution: 'Precisión',
        channelName: 'Nombre del canal',
        qualifier: 'Calificador',

        measure: 'Medición',
        measures: 'Medidas',
        measuresNoData: 'No hay mediciones disponibles para el periodo solicitado',
        propagable: 'Propagable',
        propagateConfig: 'Propagar la configuración actual',
        confirmPropagateConfig: 'Todos los dispositivos del conjunto recibirán la misma configuración actual. ¿Estás seguro de que quieres continuar?',
        propagateSettings: 'Propagar la configuración',
        confirmPropagateSettings: 'Todos los dispositivos del conjunto recibirán la configuración actual. ¿Estás seguro de que quieres continuar?',
        operationSuccess: 'Operación satisfactoria',
        timestamp: 'Marca de tiempo',

        confirmDeleteGroup: 'Confirmar eliminar grupo?',
        confirmDeleteApplication: 'Confirmar eliminar la aplicación?',

        removeFromApplication: "Rimuovere dispositivo dall'applicazione",
        confirmRemoveFromApplication: "Sicuro di voler rimuovere il dispositivo dall'applicazione?",

        removeFromGroup: 'Eliminar el dispositivo de la aplicación',
        confirmRemoveFromGroup: '¿Estás seguro de que quieres eliminar el dispositivo del grupo?',

        selectRange: 'Elija el intervalo',
        today: 'Hoy',
        lastHour: 'Última hora',
        thisWeek: 'Esta semana',
        thisMonth: 'Este mes',
        thisYear: 'Este año',
        CH: 'CH',

        minute: 'minuto',
        hour: 'hora',
        day: 'día',
        week: 'semana',
        month: 'mes',
        year: 'año',

        hideMarkers: 'Ocultar marcadores',
        multiColor: 'Multicolor',

        nameOverride: 'Nombre sobrescrito',
        overrideChannelName: 'Sobrescribir el nombre del canal',
        overrideGroupSetting: 'Sobrescribir aunque forme parte de un grupo',
        notOverrideGroupSetting: 'NO sobrescribir si forma parte de un grupo',

        removeDeviceFromGroup: 'Eliminar el dispositivo del grupo',
        commandCommunications: 'Comunicaciones de comando',
        commandCommunicationNoData: 'No hay comunicaciones',
      },
      enums: {
        groupRoles: {
          GROUP_MANAGER: 'Súper Usuario',
          GROUP_VIEWER: 'Usuario',
        },
        periods: {
          SEC0: 'Tiempo real',
          SEC60: '1 min',
          SEC120: '2 min',
          SEC300: '5 min',
          SEC600: '10 min',
          SEC900: '15 min',
          SEC1800: '30 min',
          SEC3600: '1 hora',
          SEC7200: '2 horas',
          SEC14400: '4 horas',
          SEC28800: '8 horas',
          SEC43200: '12 horas',
          SEC86400: '24 horas',
        },
        intervals: {
          SEC1: '1 sec',
          SEC2: '2 sec',
          SEC5: '5 sec',
          SEC10: '10 sec',
          SEC15: '15 sec',
          SEC30: '30 sec',
          MIN1: '1 min',
          MIN2: '2 min',
          MIN5: '5 min',
          MIN10: '10 min',
          MIN15: '15 min',
          MIN30: '30 min',
          HOUR1: '1 horas',
          HOUR12: '12 horas',
          HOUR24: '24 horas',
        },
        alarms: {
          level2: 'umbral inferior superado',
          level3: 'umbral superior superado',
          level4: 'error de desbordamiento y alarma inferior',
          level5: 'error not_init y alarma inferior',
          level6: 'error de desbordamiento y alarma superior',
          level7: 'error y alarma superior',
        },
        batteryLevels: {
          level0: 'batería agotada',
          level1: 'media batería',
          level2: 'batería llena',
          level3: 'fuente de alimentación externa y batería de reserva',
          level4: 'sólo alimentación externa',
        },
        qualifiers: {
          archiv: 'ARCHIV',
          avg: 'AVG',
          none: 'NONE',
          max: 'MAX',
          min: 'MIN',
          tot: 'TOT',
        },
        measureTypes: {
          temperature: 'Temperatura',
          relativeHumidity: 'Humedad relativa',
          dewPoint: 'Punto de rocío',
          partialVaporPressure: 'Presión parcial de vapor',
          mixingRatio: 'Relación de mezcla',
          absoluteHumidity: 'Humedad absoluta',
          wetPoint: 'Mancha húmeda',
          differentialPressure: 'Presión diferencial',
          solarRadiation: 'Radiación solar',
          illuminance: 'Iluminación',
          carbonMonoxide: 'Monóxido de carbono',
          atmosphericPressure: 'Presión atmosférica',
          frequency: 'Frecuencia',
          mappedFrequency: 'Frecuencia asignada',
          dailyGlobalRadiation: 'Radiación global diaria',
          carbonDioxide: 'Dióxido de carbono',
          volumWaterContent: 'Contenido volumétrico de agua',
          soilMoisture: 'Humedad del suelo',
          accelerationZ: 'Aceleración Z',
          height: 'Altura',
          shockTime: 'Tiempo de choque',
          deltaSpeed: 'Diferencia de velocidad',
          windSpeed: 'Velocidad del viento',
          windDirection: 'Dirección del viento',
          windChill: 'Temperatura del viento',
          pyranometer: 'Piranómetro',
          uvaIrradiance: 'Irradiación UVA',
          uvaProportion: 'Proporción UVA',
          naturalWetBulb: 'Temperatura bulbo húmedo natural',
          globeTemperature: 'Temperatura global',
          wbgtIndoor: 'WBGT interior',
          wbgtOutdoor: 'WBGT exterior',
          windGust: 'Ráfaga de viento',
          maxRainRate: 'Intensidad máxima de la lluvia',
          dailyRain: 'Lluvia diaria',
          batteryVoltage: 'Tensión de la batería',
          mkt_1: 'Temperatura cinética media 1',
          mkt_2: 'Temperatura cinética media 2',
          mkt_3: 'Temperatura cinética media 3',
          contactInput: 'Entrada de contacto',
          flow: 'Flujo',
          volumetricWaterContent: 'Contenido volumétrico de agua 2',
          soilMoisture_2: 'Humedad del suelo 2',
          volumWaterContent_3: 'Contenido volumétrico de agua 3',
          soilMoisture_3: 'Humedad del suelo 3',
          airSpeed: 'Velocidad del aire',
          par: 'PAR',
          maxRainLastHour: 'Máxima lluvia en la última hora',
          gpsDirection: 'Dirección del GPS',
          naturalWetBulbComp: 'Calc. natural de bulbo húmedo',
          voltagePowerSupply: 'Tensión de alimentación',
          currentRain: 'Lluvia actual',
          sunPresence: 'Presencia del sol',
          sunLastMinute: 'Sol de último minuto',
          sunLast_10Minutes: 'Sol últimos 10 minutos',
          accelerationX: 'Aceleración X',
          accelerazionY: 'Aceleración Y',
          etHour: 'Eapotranspiración horaria',
          dailyEt: 'Evapotranspiración  diario',
          netRadiation: 'Radiación neta',
          relativePressure: 'Presión relativa',
          fluidLevel: 'Nivel de líquido',
          leafWetnessDown: 'Humectación de la hoja inferior',
          leafWetnessUp: 'Humectación de la hoja superior',
          windGustDirection: 'Dirección de la ráfaga de viento',
          uvbIrradiance: 'Irradiación UVB',
          uvcIrradiance: 'Irradiación UVC',
          albedo: 'Albedo',
          pm1_0: 'PM1.0',
          pm2_5: 'PM2.5',
          pm4_0: 'PM4.0',
          pm10: 'PM10',
          typParticleSize: 'Tamaño típico de las partículas',
          VOCIndex: 'Índice de COV',
          eventRain: 'Lluvia de eventos',
          firstRain: 'Primera lluvia',
          temperaturePt100_2W: 'Temperatura PT100 2C',
          temperaturePt100_3W: 'Temperatura PT100 3C',
          temperaturePt100_4W: 'Temperatura PT100 4C',
          temperaturePt1000_2W: 'Temperatura PT1000 2C',
          temperaturePt1000_3W: 'Temperatura PT1000 3C',
          temperaturePt1000_4W: 'Temperatura PT1000 4C',
          temperatureTcK: 'Temperatura TC K',
          temperatureTcJ: 'Temperatura TC J',
          temperatureTcT: 'Temperatura TC T',
          temperatureTcN: 'Temperatura TC N',
          temperatureTcR: 'Temperatura TC R',
          temperatureTcS: 'Temperatura TC S',
          temperatureTcB: 'Temperatura TC B',
          temperatureTcE: 'Temperatura TC E',
          voltage_0_1V: 'Tensión 0-1V',
          voltage_0_50mV: 'Tensión 0-50mV',
          current_4_20mA: 'Corriente 4-20mA',
          potentiometer: 'Potenciómetro',
          mappedVoltage_0_1V: 'Tensión 0-1V mapeada',
          mappedVoltage_0_50mV: 'Tensión 0-50mV mapeada',
          mappedCurrent_4_20mA: 'Corriente 4-20mA mapeada',
          mappedPotentiometer: 'Potenciómetro mapeado',
          voltage_0_10V: 'Tensión 0-10V',
          mappedVoltage_0_10V: 'Tensión 0-10V mapeada',
          digitalMeasure: 'Medición digital',
          voltage_50_50mV: 'Tensión -50...+50mV',
          mapVolt_50_50mV: 'Tensión -50...+50mV map.',
          pyrgeometer: 'Pirgeómetro',
          current: 'Corriente',
          temperaturePt100_3WHr: 'Temperatura PT100 3C HR',
          temperaturePt100_4WHr: 'Temperatura PT100 4C HR',
          temperaturePt1000_3WHr: 'Temperatura PT1000 3C HR',
          temperaturePt1000_4WHr: 'Temperatura PT1000 4C HR',
          flowRate: 'Flujo',
          counter: 'Contador',
          mappedCounter: 'Contador mapeado',
          totalRainCompensed: 'Total de lluvia compensada',
          dailyRainCompensed: 'Luvia diaria compensada',
          totaleEnergy: 'Energía total',
          energy: 'Energía',
          rainCounter: 'Contador de lluvia',
          notImplemented: 'No se ha aplicado',
          rssi: 'RSSI',
          snr: 'SNR',

          // Cayenne
          digitalIn: 'Entrada digital',
          digitalOut: 'Salida digital',
          analogIn: 'Entrada analógica',
          analogOut: 'Salida analógica',
          // illuminance: 'Illuminazione',
          presence: 'Presencia',
          // temperature: 'Temperatura',
          // relativeHumidity: "Umidita' relativa",
          // accelerationX: 'Accelerazione X',
          accelerationY: 'Aceleración Y',
          // accelerationZ: 'Accelerazione Z',
          // atmosphericPressure: 'Pressione Atmosferica',
        },
      },
      extractGraphQLErrors: {
        noDuplicate: 'No se permiten duplicados',
        networkError: 'Servidor no localizable, compruebe la conexión',
        unexpectedError: 'Se ha producido un error inesperado',
        unauthenticated: 'NO está autentificado para ver esta información',
      },
      header: {
        menu: {
          groups: 'Grupos',
          applications: 'Aplicaciones',
          devices: 'Dispositivos',
          logOut: 'Salir',
        },
      },
      allDevices: {
        list: {
          title: 'Todos los dispositivos',
          subtitle: 'Lista de todos los dispositivos',
          noData: 'No hay dispositivos',
        },
      },
      applications: {
        list: {
          title: 'Aplicaciones',
          subtitle: 'Lista de Aplicaciones',
          noData: 'No hay aplicaciones',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositivos',
            },
            settings: {
              title: 'Ajustes',
            },
            exportIntegrations: {
              title: 'Integraciones de exportación',
            },
          },
        },
        create: {
          title: 'Crear una nueva aplicación',
        },
        edit: {
          title: 'Editar aplicación',
        },
        delete: {
          title: '¿Estás seguro de que quieres eliminar la aplicación?',
        },
      },
      groups: {
        list: {
          title: 'Grupos',
          subtitle: 'Lista de grupos',
          noData: 'No hay grupos',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositivos',
            },
            settings: {
              title: 'Configuración',
            },
            users: {
              title: 'Usuarios',
            },
            exportIntegrations: {
              title: 'Integraciones de exportación',
            },
          },
        },
        create: {
          title: 'Crear un nuevo grupo',
        },
        edit: {
          title: 'Editar grupo',
        },
        delete: {
          title: '¿Estás seguro de que quieres eliminar el grupo?',
        },
        addDevice: {
          title: 'Añadir un dispositivo al grupo',
        },
      },
      userPermissions: {
        list: {
          title: 'Permisos',
          subtitle: 'Lista de usuarios autorizados y sus funciones',
          noData: 'No hay permisos',
        },
        edit: {
          title: 'Editar un permiso',
        },
      },
      devices: {
        list: {
          title: 'Dispositivos',
          subtitle: 'Lista de dispositivos',
          noData: 'No hay dispositivos',
        },
        details: {
          tabs: {
            monitors: {
              title: 'Monitorización',
            },
            settings: {
              title: 'Configuración',
            },
            exportIntegrations: {
              title: 'Integraciones de exportación',
            },
            advanced: {
              title: 'Configuración avanzada',
            },
            remoteConfigurations: {
              title: 'Configuraciones remotas',
            },
          },
        },
        edit: {
          title: 'Editar dispositivo',
        },
        monitorsTabs: {
          realTime: {
            title: 'Tiempo real',
          },
          chart: {
            title: 'Gráficos',
          },
          table: {
            title: 'Tabular',
          },
        },
        remoteConfigurationsTabs: {
          configurations: {
            title: 'Configuraciones',
          },
          terminal: {
            title: 'Terminal de comandos',
          },
        },
        create: {
          title: 'Crear un dispositivo',
        },
        commands: {
          title: 'Envío de comandos',
          log: 'Registro de comunicaciones',
          GENERIC: 'Comando',
          GET_INFO: 'Obtener info',
          SET_DEVICE_NAME: 'Establecer nombre dispositivo',
          SET_GROUP_NAME: 'Establecer nombre grupos',
          GET_COMMUNICATION_PROTOCOL: 'Obtener comunicación protocolo',
          SET_COMMUNICATION_PROTOCOL: 'Establecer protocolo de comunicación',
          SET_TRANSISSION_RATE: 'establecer índice transmisión',
          GET_TRANSISSION_RATE: 'Obtener índice transmisión',
          fPort: 'Puerto',
        },
      },
      groupInvites: {
        list: {
          title: 'Invitaciones',
          subtitle: 'Lista de invitaciones pendientes de aceptar',
          noData: 'No hay invitaciones',
        },
        create: {
          title: 'Crear una nueva invitación',
        },
      },
      groupOwnershipInvites: {
        create: {
          title: 'Invitación a ser dueño del grupo',
        },
      },
      exportIntegrations: {
        ftp: {
          title: 'FTP',
          edit: {
            title: 'Cambiar la configuración de la exportación FTP',
          },
          test: {
            title: 'Prueba',
            testFTP: 'Prueba de conexión FTP',
            testFTPBetweenDatetime: 'Prueba de carga FTP entre fechas',
            resetLast: 'Restablecer la fecha de la última exportación',
          },
        },
        deltaCloud: {
          title: 'DeltaCloud',
          edit: {
            title: 'Cambiar la configuración de exportación de DeltaCloud',
            setDefaultURL: 'Establecer URL predeterminada',
          },
          test: {
            title: 'Prueba',
            testDeltaCloud: 'Prueba de conexión DeltaCloud',
            testDeltaCloudBetweenDatetime: 'Probar la carga de DeltaCloud entre fechas',
            resetLast: 'Restablecer la fecha de la última exportación',
          },
        },
      },
    },
  },
};
