import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { clearTypenames } from '../../utils/graphql';

const GroupOwnershipInviteForm = (props) => {
  const {
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const handleFormSubmit = (values) => {
    const filteredValues = {
      ...values,
    };
    onFormSubmit(clearTypenames(filteredValues));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            as={(
              <TextField
                label={t('common.email')}
                placeholder={t('common.email')}
                error={errors.email}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
              />
          )}
            id="email"
            name="email"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

GroupOwnershipInviteForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default GroupOwnershipInviteForm;
