import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import {
  CustomButton,
} from '../../generic';

const DeviceUnlinkGroupForm = (props) => {
  const {
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');
  const [askConfirmVisible, setAskConfirmVisible] = React.useState(false);

  const handleFormSubmit = () => {
    onFormSubmit();
  };

  return (
    <>
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        onClick={() => setAskConfirmVisible(true)}
      >
        <Typography>{t('common.removeFromGroup')}</Typography>
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={askConfirmVisible}
        onClose={() => setAskConfirmVisible(false)}
      >
        <DialogTitle id="draggable-dialog-title">
          {t('common.confirmRemoveFromGroup')}
        </DialogTitle>
        <DialogActions>
          <CustomButton autoFocus onClick={() => setAskConfirmVisible(false)} color="info">
            {t('common.cancel')}
          </CustomButton>
          <CustomButton onClick={() => handleFormSubmit()} color="primary">
            {t('common.ok')}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeviceUnlinkGroupForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default DeviceUnlinkGroupForm;
