export default {
  fr: {
    translations: {
      common: {
        ok: 'Ok',
        yes: 'Oui',
        no: 'Non',
        areYouSure: 'Tu es sûr?',
        confirmLogout: 'Sûr de vouloir sortir?',
        cancel: 'Annule',
        actions: 'Actions',
        save: 'Sauvegarde',
        delete: 'Elimine',
        restore: 'Réinitialise',
        loadMore: 'Charge encore',
        filter: 'Trier',
        filterByName: 'Trier par nom',
        send: 'Envoie',
        resend: 'Renvoie',
        create: 'Crée',
        error: 'ERREUR',
        pending: 'En attente...',

        application: 'Application',
        applications: 'Applications',
        group: 'Groupe',
        groups: 'Groupes',
        detail: 'Détail',
        details: 'Détails',
        logout: 'Sors',
        defaultGroupName: 'Default',

        requiredField: 'Champ obligatoire',
        roles: 'Rôles',
        email: 'Mail',
        name: 'Nom',
        timezone: 'Fuseau horaire',
        owner: 'Propriétaire',
        server: 'Serveur',
        ftpPort: 'Porte FTP',
        ftpUsername: 'Nom utilisateur FTP',
        ftpPassword: 'Mot de passe FTP',
        period: 'Période',
        directory: 'Répertoire',
        directoryForEveryDevice: 'Répertoire pour tous les dispositifs',
        enabled: 'Habilité',
        urlResource: 'URL ressources',

        devID: 'DEV ID',
        devEUI: 'DEV EUI',
        appID: 'APP ID',
        appEUI: 'APP EUI',
        description: 'Déscription',
        url: 'URL',
        processID: 'Identifiant de processus',
        accessKey: "Clé d'accès",
        rssi: 'RSSI',
        snr: 'SNR',
        dataRate: 'Débit de données',
        lastBatteryLevel: 'Niveau de batterie',
        lastActivityTimestamp: 'Etat',
        dateAndTime: 'Date et heure',
        serialNumber: 'Numéro de série',
        model: 'Modèle',
        userCode: 'Code utilisateur',
        groupName: 'Nom du groupe',
        rainTip: 'Pointe pluie',
        firmwareVersion: 'Version FW',
        firmwareDate: 'Données FW',
        onlySIUnits: 'SEULEMENT UNITE OUI',
        isTimeSynchActive: "Synchronisation de l'heure",
        RFLogInterval: 'Intervalle RF/Log',
        measureInterval: 'Intervalle de mesure',
        protocolType: 'Type de protocole',
        timeSynchronization: "Synchronisation de l'heure",
        loggingActive: 'Logging activé',
        cyclicLogging: 'Logging circulaire',
        buzzerAlarm: 'Alarme sonore',
        refreshAllInfo: 'Rafraîchir toutes les informations',
        testBuzzer: 'Test avertisseur',
        ignorePendingCommands: 'IGNORER LES COMMANDES EN ATTENTE',
        askHistoricalData: 'demander des données historiques',
        devEUIorSerialNumber: 'DEV EUI / Numéro de série',

        channels: 'Canaux',
        status: 'Etat',
        measureType: 'Type de mesure',
        unitOfMeasure: 'Unité de mesure',
        resolution: 'Précision',
        channelName: 'Nom du canal',
        qualifier: 'Qualificateur',

        measure: 'Mesure',
        measures: 'Mesures',
        measuresNoData: 'Il n\'y a pas de mesures disponibles pour la période démandée',
        propagable: 'Transmissible',
        propagateConfig: 'Propage la configuration courante',
        confirmPropagateConfig: "Tous les dispositifs de l'ensemble recevront la même configuration courante. Sûr de continuer?",
        propagateSettings: 'Propage la configuration',
        confirmPropagateSettings: "Tous les dispositifs de l'ensemble recevront la configuration actuelle. Sûr de continuer?",
        operationSuccess: 'Opération réussie',
        timestamp: 'Horodatage',

        confirmDeleteGroup: 'Confirmer la suppression du groupe?',
        confirmDeleteApplication: "Confirmer la suppression de l'application?",

        removeFromApplication: "Retirer le dispositif de l'application",
        confirmRemoveFromApplication: "Sûr de vouloir retirer le dispositif de l'application?",

        removeFromGroup: 'Retirer le dispositif du groupe',
        confirmRemoveFromGroup: 'Sûr de vouloir retirer le dispositif du groupe?',

        selectRange: 'Sélectionne l\'intervalle',
        today: "Aujourd'hui",
        lastHour: 'Dernière heure',
        thisWeek: 'Cette semaine',
        thisMonth: 'Ce mois',
        thisYear: 'Cette année',
        CH: 'CH',

        minute: 'minute',
        hour: 'heure',
        day: 'jour',
        week: 'semaine',
        month: 'mois',
        year: 'année',

        hideMarkers: 'Cache marqueurs',
        multiColor: 'Multicouleur',

        nameOverride: 'Nom écrasé',
        overrideChannelName: 'Écraser le nom du canal',
        overrideGroupSetting: 'Écraser même s\'il fait partie d\'un groupe',
        notOverrideGroupSetting: 'NE PAS écraser s\'il fait partie d\'un groupe',

        removeDeviceFromGroup: 'Retirer dispositif du groupe',
        commandCommunications: 'Communication commandes',
        commandCommunicationNoData: 'Il n\'y a pas de communications',
      },
      enums: {
        groupRoles: {
          GROUP_MANAGER: 'Superutilisateur',
          GROUP_VIEWER: 'Utilisateur',
        },
        periods: {
          SEC0: 'Temps réel',
          SEC60: '1 min',
          SEC120: '2 min',
          SEC300: '5 min',
          SEC600: '10 min',
          SEC900: '15 min',
          SEC1800: '30 min',
          SEC3600: '1 h',
          SEC7200: '2 h',
          SEC14400: '4 h',
          SEC28800: '8 h',
          SEC43200: '12 h',
          SEC86400: '24 h',
        },
        intervals: {
          SEC1: '1 sec',
          SEC2: '2 sec',
          SEC5: '5 sec',
          SEC10: '10 sec',
          SEC15: '15 sec',
          SEC30: '30 sec',
          MIN1: '1 min',
          MIN2: '2 min',
          MIN5: '5 min',
          MIN10: '10 min',
          MIN15: '15 min',
          MIN30: '30 min',
          HOUR1: '1 h',
          HOUR12: '12 h',
          HOUR24: '24 h',
        },
        alarms: {
          level2: 'dépassement seuil inférieur',
          level3: 'dépassement seuil supérieur',
          level4: 'erreur underflow et alarme inférieure',
          level5: 'erreur not_init et alarme inférieure',
          level6: 'erreur overflow et alarme supérieure',
          level7: 'erreur et alarme supérieure',
        },
        batteryLevels: {
          level0: 'batterie déchargée',
          level1: 'moitié batterie',
          level2: 'batterie pleine',
          level3: 'alimentation externe et batterie de secours',
          level4: 'seulement alimentation externe',
        },
        qualifiers: {
          archiv: 'ARCHIV',
          avg: 'AVG',
          none: 'NONE',
          max: 'MAX',
          min: 'MIN',
          tot: 'TOT',
        },
        measureTypes: {
          temperature: 'Température',
          relativeHumidity: 'Humidité relative',
          dewPoint: 'Point de rosée',
          partialVaporPressure: 'Pression de vapeur partielle',
          mixingRatio: 'Mélange vapeur-air',
          absoluteHumidity: 'Humidité absolue',
          wetPoint: 'Température du bulbe humide',
          differentialPressure: 'Pression différentielle',
          solarRadiation: 'Radiation solaire',
          illuminance: 'Eclairement',
          carbonMonoxide: 'Monoxyde de carbone',
          atmosphericPressure: 'Pression atmosphérique',
          frequency: 'Fréquence',
          mappedFrequency: 'Fréquence mappée',
          dailyGlobalRadiation: 'Radiation globale quotidienne',
          carbonDioxide: 'Dioxyde de carbone',
          volumWaterContent: 'Teneur en eau volumétrique',
          soilMoisture: 'Humidité du sol',
          accelerationZ: 'Accélération Z',
          height: 'Hauteur',
          shockTime: 'Temps de choc',
          deltaSpeed: 'Vitesse delta',
          windSpeed: 'Vitesse du vent',
          windDirection: 'Direction du vent',
          windChill: 'Température ressentie',
          pyranometer: 'Pyranomètre',
          uvaIrradiance: 'Irradiation UVA',
          uvaProportion: 'Proportion UVA',
          naturalWetBulb: 'Température de bulbe humide naturelle',
          globeTemperature: 'Température globale',
          wbgtIndoor: 'WBGT indoor',
          wbgtOutdoor: 'WBGT outdoor',
          windGust: 'Rafale de vent',
          maxRainRate: 'Intensité maximale de pluie',
          dailyRain: 'Pluie quotidienne',
          batteryVoltage: 'Tension batterie',
          mkt_1: 'Température cinétique moyenne 1',
          mkt_2: 'Température cinétique moyenne 2',
          mkt_3: 'Température cinétique moyenne 3',
          contactInput: 'Entrée contact',
          flow: 'Fluxe',
          volumetricWaterContent: 'Teneur en eau volumétrique 2',
          soilMoisture_2: 'Humidité du sol 2',
          volumWaterContent_3: 'Teneur en eau volumétrique 3',
          soilMoisture_3: 'Humidité du sol 3',
          airSpeed: "Vitesse de l'air",
          par: 'PAR',
          maxRainLastHour: 'Maximum de pluie dernière heure',
          gpsDirection: 'Direction GPS',
          naturalWetBulbComp: 'Température de bulbe humide naturelle calc.',
          voltagePowerSupply: 'Tension d\'alimentation',
          currentRain: 'Pluie courante',
          sunPresence: 'Ensoleillement',
          sunLastMinute: 'Soleil dernière minute',
          sunLast_10Minutes: 'Soleil 10 dernières minutes',
          accelerationX: 'Accélération X',
          accelerazionY: 'Accélération Y',
          etHour: 'Evapotranspiration horaire',
          dailyEt: 'Evapotranspiration quotidienne',
          netRadiation: 'Radiation nette',
          relativePressure: 'Pression relative',
          fluidLevel: 'Niveau fluide',
          leafWetnessDown: 'Mouillage feuille inférieure',
          leafWetnessUp: 'Mouillage feuille supérieure',
          windGustDirection: 'Direction rafale de vent',
          uvbIrradiance: 'Irradiation UVB',
          uvcIrradiance: 'Irradiation UVC',
          albedo: 'Albedo',
          pm1_0: 'PM1.0',
          pm2_5: 'PM2.5',
          pm4_0: 'PM4.0',
          pm10: 'PM10',
          typParticleSize: 'Taille habituelle particules',
          VOCIndex: 'Indice de COV',
          eventRain: 'Événement pluie ',
          firstRain: 'Première pluie',
          temperaturePt100_2W: 'Température PT100 2F',
          temperaturePt100_3W: 'Température PT100 3F',
          temperaturePt100_4W: 'Température PT100 4F',
          temperaturePt1000_2W: 'Température PT1000 2F',
          temperaturePt1000_3W: 'Température PT1000 3F',
          temperaturePt1000_4W: 'Température PT1000 4F',
          temperatureTcK: 'Température TC K',
          temperatureTcJ: 'Température TC J',
          temperatureTcT: 'Température TC T',
          temperatureTcN: 'Température TC N',
          temperatureTcR: 'Température TC R',
          temperatureTcS: 'Température TC S',
          temperatureTcB: 'Température TC B',
          temperatureTcE: 'Température TC E',
          voltage_0_1V: 'Tension 0-1V',
          voltage_0_50mV: 'Tension 0-50mV',
          current_4_20mA: 'Electricité 4-20mA',
          potentiometer: 'Potentiomètre',
          mappedVoltage_0_1V: 'Tension 0-1V mappée',
          mappedVoltage_0_50mV: 'Tension 0-50mV mappée',
          mappedCurrent_4_20mA: 'Electricité 4-20mA mappée',
          mappedPotentiometer: 'Potentiomètre mappé',
          voltage_0_10V: 'Tension 0-10V',
          mappedVoltage_0_10V: 'Tension 0-10V mappée',
          digitalMeasure: 'Mesure digitale',
          voltage_50_50mV: 'Tension -50...+50mV',
          mapVolt_50_50mV: 'Tension -50...+50mV mappée',
          pyrgeometer: 'Pyrgéomètre',
          current: 'Electricité',
          temperaturePt100_3WHr: 'Température PT100 3F HR',
          temperaturePt100_4WHr: 'Température PT100 4F HR',
          temperaturePt1000_3WHr: 'Température PT1000 3F HR',
          temperaturePt1000_4WHr: 'Température PT1000 4F HR',
          flowRate: 'Portée',
          counter: 'Compteur',
          mappedCounter: 'Compteur mappé',
          totalRainCompensed: 'Pluie totale compensée',
          dailyRainCompensed: 'Pluie jour compensée',
          totaleEnergy: 'Energie totale',
          energy: 'Energie',
          rainCounter: 'Compteur pluie',
          notImplemented: 'Non implémentée',
          rssi: 'RSSI',
          snr: 'SNR',

          // Cayenne
          digitalIn: 'Entrée numérique',
          digitalOut: 'Sortie numérique',
          analogIn: 'Entrée analogique',
          analogOut: 'Sortie analogique',
          // illuminance: 'Illumination',
          presence: 'Présence',
          // temperature: 'Température',
          // relativeHumidity: "Humidité relative",
          // accelerationX: 'Accélération X',
          accelerationY: 'Accélération Y',
          // accelerationZ: 'Accélération Z',
          // atmosphericPressure: 'Pression atmosphérique',
        },
      },
      extractGraphQLErrors: {
        noDuplicate: 'Les doublons ne sont pas autorisés',
        networkError: 'Serveur inaccessible, vérifier la connexion',
        unexpectedError: 'Une erreur imprévue s’est produite',
        unauthenticated: 'Tu n\'es pas authentifié pour afficher ces informations',
      },
      header: {
        menu: {
          groups: 'Groupes',
          applications: 'Applications',
          devices: 'Dispositifs',
          logOut: 'Démarrer',
        },
      },
      allDevices: {
        list: {
          title: 'Tous les dispositifs',
          subtitle: 'Liste tous les dispositifs',
          noData: 'Il n\'y a pas de dispositifs',
        },
      },
      applications: {
        list: {
          title: 'Applications',
          subtitle: 'Liste des applications',
          noData: 'Il n\'y a pas d\'applications',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositifs',
            },
            settings: {
              title: 'Réglages',
            },
            exportIntegrations: {
              title: 'Intégrations d\'exportation',
            },
          },
        },
        create: {
          title: 'Crée une nouvelle application',
        },
        edit: {
          title: 'Modifie l\'application',
        },
        delete: {
          title: 'Sûr de vouloir supprimer l’application?',
        },
      },
      groups: {
        list: {
          title: 'Groupes',
          subtitle: 'Liste des Groupes',
          noData: 'Il n\'y a pas de groupes',
        },
        details: {
          tabs: {
            devices: {
              title: 'Dispositifs',
            },
            settings: {
              title: 'Réglages',
            },
            users: {
              title: 'Utilisateurs',
            },
            exportIntegrations: {
              title: 'Intégrations d\'exportation',
            },
          },
        },
        create: {
          title: 'Crée un nouveau groupe',
        },
        edit: {
          title: 'Modifie le groupe',
        },
        delete: {
          title: 'Sûr de vouloir supprimer le groupe?',
        },
        addDevice: {
          title: 'Ajoute un dispositif au groupe',
        },
      },
      userPermissions: {
        list: {
          title: 'Autorisations',
          subtitle: 'Liste des utilisateurs activés et leurs rôles',
          noData: 'Il n\'y a pas d\'autorisations',
        },
        edit: {
          title: 'Modifie une autorisation',
        },
      },
      devices: {
        list: {
          title: 'Dispositifs',
          subtitle: 'Liste des dispositifs',
          noData: 'Il n\'y a pas de dispositifs',
        },
        details: {
          tabs: {
            monitors: {
              title: 'Monitorage',
            },
            settings: {
              title: 'Réglages',
            },
            exportIntegrations: {
              title: 'Intégrations d\'exportation',
            },
            advanced: {
              title: 'Réglages avancés',
            },
            remoteConfigurations: {
              title: 'Configurations à distance',
            },
          },
        },
        edit: {
          title: 'Modifie le dispositif',
        },
        monitorsTabs: {
          realTime: {
            title: 'Realtime',
          },
          chart: {
            title: 'Graphiques',
          },
          table: {
            title: 'Tableaux',
          },
        },
        remoteConfigurationsTabs: {
          configurations: {
            title: 'Configurations',
          },
          terminal: {
            title: 'Terminal commandes',
          },
        },
        create: {
          title: 'Crée un dispositif',
        },
        commands: {
          title: 'Envoi commandes',
          log: 'Historique communication',
          GENERIC: 'Commande',
          GET_INFO: 'Demande de renseignements',
          SET_DEVICE_NAME: 'Configure nom dispositif',
          SET_GROUP_NAME: 'Configure nom groupe',
          GET_COMMUNICATION_PROTOCOL: 'Lis protocole communication',
          SET_COMMUNICATION_PROTOCOL: 'Configure protocole communication',
          SET_TRANSISSION_RATE: 'Configure débit de transmission',
          GET_TRANSISSION_RATE: 'Lis débit de transmission',
          fPort: 'Port',
        },
      },
      groupInvites: {
        list: {
          title: 'Invitations',
          subtitle: 'Liste des invitations en attente qui doivent encore être acceptées',
          noData: 'Il n\'y a pas d\'invitations',
        },
        create: {
          title: 'Crée une nouvelle invitation',
        },
      },
      groupOwnershipInvites: {
        create: {
          title: 'Invitation à devenir propriétaire du groupe',
        },
      },
      exportIntegrations: {
        ftp: {
          title: 'FTP',
          edit: {
            title: 'Modifie les paramètres d’exportation FTP',
          },
          test: {
            title: 'Test',
            testFTP: 'Test connexion FTP',
            testFTPBetweenDatetime: 'Envoie les données d’un intervalle de temps par FTP',
            resetLast: 'Reset date de la dernière exportation',
          },
        },
        deltaCloud: {
          title: 'DeltaCloud',
          edit: {
            title: 'Modifie les paramètres d’exportation DeltaCloud',
            setDefaultURL: "Définir l'URL par défaut",
          },
          test: {
            title: 'Test',
            testDeltaCloud: 'Test connexion DeltaCloud',
            testDeltaCloudBetweenDatetime: 'Envoie à DeltaCloud les données d\'un intervalle de temps',
            resetLast: 'Reset date de la dernière exportation',
          },
        },
      },
    },
  },
};
