import React from 'react';
import PropTypes from 'prop-types';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import {
  GroupDevicesList,
} from '../components';

const GroupUsersTab = (props) => {
  const { group, canEdit } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GroupDevicesList
          group={group}
          canEdit={canEdit}
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

GroupUsersTab.propTypes = propTypes;

export default GroupUsersTab;
