import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Loader,
  GraphQLErrorWrapper,
} from '../generic';

import { GROUP_ACCEPT_OWNERSHIP_INVITE_MUTATION } from './queries';

const GroupAcceptOwnershipInvitePage = () => {
  const { t } = useTranslation('translations');
  const { token } = useParams();
  const navigate = useNavigate();

  const [
    groupAcceptOwnershipInviteMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_ACCEPT_OWNERSHIP_INVITE_MUTATION);

  React.useEffect(() => {
    const runMutation = async () => {
      try {
        const result = await groupAcceptOwnershipInviteMutationAction({
          variables: {
            input: {
              token,
            },
          },
        });
        if (result.data && result.data.groupAcceptOwnershipInvite) {
          // message.success(t('groupInvites.accept.success'));
          navigate('/groups', { replace: true });
        }
      }
      catch (e) { } // eslint-disable-line
    };

    if (token) {
      runMutation();
    }
  }, [token, navigate, groupAcceptOwnershipInviteMutationAction, t]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  return (
    <Loader loading={loading} />
  );
};

export default GroupAcceptOwnershipInvitePage;
