import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  // IconButton,
  // Grid,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import {
  // Edit,
  Update,
} from '@material-ui/icons';
import {
  getTimezoneLabelWithOffset,
} from '../../utils/date';
import {
  CardWrapper,
  CustomTable,
  GraphQLErrorWrapper,
} from '../../generic';

import GroupCreateModal from './GroupCreateModal';
import GroupSearcher from './GroupSearcher';

import {
  GROUPS_QUERY,
  GROUP_CREATE_MUTATION,
} from '../queries';

const GroupsList = (props) => {
  const { pageSize, filter } = props;
  const { t } = useTranslation('translations');
  const navigate = useNavigate();

  // TODO check se va anche su IE
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [search, setSearch] = React.useState('');
  const [isGroupCreateModalOpen, setIsGroupCreateModalOpen] = React.useState(false);

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useQuery(GROUPS_QUERY, {
    variables: {
      pageSize,
      filter: {
        ...filter,
        search,
      },
    },
  });

  const [
    groupCreateMutationAction,
    {
      // loading: loadingCreate,
      error: errorCreate,
    },
  ] = useMutation(GROUP_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUPS_QUERY,
          variables: {
            pageSize,
            filter,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { groups: groupsConnection } = viewer;
      const { pageInfo, edges } = groupsConnection;
      return {
        groups: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      groups: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const { groups, pageInfo } = queryResult;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.groups;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.groups;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              groups: {
                ...previousResult.viewer.groups,
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  React.useEffect(() => {
    const createDefaultGroup = async () => {
      if (
        (
          search === ''
          || search === null
        )
        && !loading
        && groups.length === 0
      ) {
        try {
          await groupCreateMutationAction({
            variables: {
              input: {
                name: t('common.defaultGroupName'),
                timezone: tz || 'Europe/Rome', // TODO timezone from browser
                onlySIUnits: false,
              },
            },
          });
        }
        catch (e) { } // eslint-disable-line
      }
    };

    createDefaultGroup();
  }, [groupCreateMutationAction, groups, loading, search, t, tz]);

  // const handleEdit = (groupID) => {
  //   navigate(`/groups/${groupID}`);
  // };

  if (error || errorCreate) {
    return <GraphQLErrorWrapper error={error || errorCreate} />;
  }

  return (
    <>
      <CardWrapper
        cardTitle={t('groups.list.title')}
        cardSubtitle={t('groups.list.subtitle')}
        cardHeaderAction={() => navigate('/groups/new')}
        cardHeaderIcon="add"
        cardBody={
          (
            <>
              <GroupSearcher
                onSearch={setSearch}
              />
              <CustomTable
                loading={loading}
                noDataText={t('groups.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  '',
                  t('common.name'),
                  t('common.owner'),
                  t('common.timezone'),
                  // <Grid container justify="flex-end">{t('common.actions')}</Grid>,
                ]}
                tableData={groups.map((group, index) => ([
                  index + 1,
                  (
                    <Link style={{ cursor: 'pointer' }} color="primary" href={`/groups/${group.id}`}>
                      <Typography style={group.deletedAt ? { textDecoration: 'line-through' } : {}}>
                        {group.name}
                      </Typography>
                    </Link>
                  ),
                  group.owner,
                  getTimezoneLabelWithOffset(group.timezone),
                  // (
                  //   <Grid container justify="flex-end">
                  //     <IconButton color="primary" onClick={() => handleEdit(group.id)}>
                  //       <Edit fontSize="small" />
                  //     </IconButton>
                  //   </Grid>
                  // ),
                ]))}
              />
            </>
          )
        }
        cardFooter={(pageInfo && pageInfo.hasNextPage)
          && (
            <Button
              fullWidth
              onClick={() => handleLoadMore()}
              color="primary"
              variant="contained"
              startIcon={<Update />}
            >
              {t('common.loadMore')}
            </Button>
          )}
      />
      <GroupCreateModal
        isModalOpen={isGroupCreateModalOpen}
        onClose={() => setIsGroupCreateModalOpen(false)}
        pageSize={pageSize}
        filter={filter}
      />
    </>
  );
};

GroupsList.propTypes = {
  pageSize: PropTypes.number,
  filter: PropTypes.object, // eslint-disable-line
};

GroupsList.defaultProps = {
  pageSize: 20,
  filter: {},
};

export default GroupsList;
