import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

import {
  GroupAutocompleteSearcher,
} from '../../group/components';

import { clearTypenames } from '../../utils/graphql';

const DeviceChangeGroupForm = (props) => {
  const {
    device,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');
  const [currentGroup, setCurrentGroup] = React.useState(device.group);

  const handleFormSubmit = () => {
    if (currentGroup) {
      onFormSubmit(clearTypenames({
        id: device.id,
        groupId: currentGroup.id,
      }));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GroupAutocompleteSearcher
          style={{ width: 300 }}
          value={null}
          onChange={(value) => {
            setCurrentGroup(value);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          id="group"
          name="group"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => handleFormSubmit()}
        >
          <Typography>{t('common.save')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

DeviceChangeGroupForm.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    devID: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default DeviceChangeGroupForm;
