import { format, utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';
import { formatDistanceToNow } from 'date-fns';
import itLocale from 'date-fns/locale/it';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {
  timezonesNumeric,
  timezonesByCities,
} from '../enum/timezones';

export const getLocale = (locale) => {
  let dateFnsLocale;
  const cleanLocale = locale ? locale.split('-')[0] : 'en';
  switch (cleanLocale) {
    case 'it':
      dateFnsLocale = itLocale;
      break;
    case 'es':
      dateFnsLocale = esLocale;
      break;
    case 'fr':
      dateFnsLocale = frLocale;
      break;
    case 'ru':
      dateFnsLocale = ruLocale;
      break;
    case 'en':
      dateFnsLocale = enLocale;
      break;
    default: dateFnsLocale = enLocale;
  }

  return dateFnsLocale;
};

export const formatLocale = (date, formatString, locale, timezone) => {
  const formatOptions = {
    locale: getLocale(locale),
  };
  let dateToFormat = date;
  if (timezone) {
    dateToFormat = utcToZonedTime(date, timezone);
    formatOptions.timeZone = timezone;
  }
  return format(dateToFormat, formatString, formatOptions);
};

export const formatDistanceToNowLocale = (date, locale) => formatDistanceToNow(
  new Date(date),
  {
    addSuffix: true,
    includeSeconds: true,
    locale: getLocale(locale),
  },
);

export const getTimezoneLabelWithOffset = (timezone) => {
  const offsetInMilliseconds = getTimezoneOffset(timezone);
  const offsetInSeconds = Math.abs(offsetInMilliseconds / 1000);
  const offsetMinutes = Math.floor((offsetInSeconds / 60) % 60);
  const offsetHours = Math.floor(offsetInSeconds / 3600);
  const offsetMinutesString = offsetMinutes > 10 ? offsetMinutes : `0${offsetMinutes}`;
  const offsetHoursString = offsetHours > 10 ? offsetHours : `0${offsetHours}`;
  const offsetSignString = offsetInMilliseconds > 0 ? '+' : '-';
  let offsetString = `[${offsetSignString}${offsetHoursString}:${offsetMinutesString}]`;
  if (offsetString === '[-00:00]') offsetString = '[00:00]';
  if (timezonesNumeric.find((tz) => timezone === tz)) {
    return `GMT/UTC ${offsetString}`;
  }
  if (timezonesByCities.find((tz) => timezone === tz)) {
    return `${timezone} ${offsetString}`;
  }
  return 'unsupported Timezone';
};

export const getTimezoneOffsetCorrection = (timezone) => {
  const currentBrowserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offsetBrowserInMilliseconds = getTimezoneOffset(currentBrowserTimezone);
  const offsetDeviceInMilliseconds = getTimezoneOffset(timezone);
  const offsetInMilliseconds = offsetDeviceInMilliseconds - offsetBrowserInMilliseconds;
  const offsetInSeconds = offsetInMilliseconds / 1000;
  return offsetInSeconds;
};
