/* eslint-disable max-len */
import React from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Loader } from '../generic';
import { USER_ROLES } from '../enum';
import config from '../config';

const isAuthenticated = () => {
  const token = window.sessionStorage.getItem('token');
  if (!token) return false;

  const decoded = jwtDecode(token);
  const { exp } = decoded;
  const expirationDate = new Date(exp * 1000);

  if (new Date() > expirationDate) return false;

  return {
    ...decoded,
    token,
  };
};

const getCurrentUser = () => isAuthenticated();

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const apolloClient = useApolloClient();
  const [currentUser, setCurrentUser] = React.useState(getCurrentUser());

  React.useEffect(() => {
    if (!currentUser) {
      const isLogingIn = location.pathname === '/login';
      const isGroupInviteAccept = location.pathname.startsWith('/groupInviteAccept/');
      const isGroupAcceptOwnershipInvite = location.pathname.startsWith('/groupAcceptOwnershipInvite/');

      if (location.hash) {
        const token = location.hash.split('access_token=').pop().split('&token_type=Bearer')[0];
        if (token) {
          window.sessionStorage.setItem('token', token);
          setCurrentUser(getCurrentUser());
          if (isLogingIn) {
            navigate('/groups', { replace: true });
          }
          if (isGroupInviteAccept || isGroupAcceptOwnershipInvite) {
            navigate(location.pathname, { replace: true });
          }
        }
      }
      else {
        let URL = `${config.oauthUrl}?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&response_type=token`;
        if (isGroupInviteAccept || isGroupAcceptOwnershipInvite) {
          const newRedirectUri = config.redirect_uri.replace('/login', location.pathname);
          URL = `${config.oauthUrl}?client_id=${config.client_id}&redirect_uri=${newRedirectUri}&response_type=token`;
        }
        window.location.href = URL;
      }
    }
    return null;
  }, [currentUser, location, navigate]);

  const logout = async () => {
    await apolloClient.resetStore();
    window.sessionStorage.removeItem('token');
    localStorage.removeItem('i18nextLng');
    localStorage.clear();
    window.location = config.logoutUrl;
  };

  const iAmAdmin = React.useMemo(() => {
    if (
      currentUser
      && currentUser.roles
      && currentUser.roles.includes(USER_ROLES.ADMIN)
    ) {
      return true;
    }
    return false;
  }, [currentUser]);

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        iAmAdmin,
        logout,
      }}
    >
      {currentUser ? children : <Loader />}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const { Consumer: AuthenticationConsumer } = AuthenticationContext;
