import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

import { TRANSISSION_RATES } from '../../enum';

const CommandFormRow = (props) => {
  const {
    command,
    param,
    pendingCommands,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  const {
    errors, control, handleSubmit,
  } = useForm();

  const handleFormSubmit = (values) => {
    const modifiedValues = {
      ...values,
      command,
    };
    onFormSubmit(modifiedValues);
  };

  // 'GET_INFO',
  // 'SET_DEVICE_NAME',
  // 'SET_GROUP_NAME',
  // 'SET_COMMUNICATION_PROTOCOL',
  // 'SET_COMMUNICATION_PROTOCOL',
  // 'SET_TRANSISSION_RATE',
  // 'GET_TRANSISSION_RATE',

  let label = null;
  switch (command) {
    case 'SET_DEVICE_NAME':
      label = t('common.userCode');
      break;
    case 'SET_GROUP_NAME':
      label = t('common.groupName');
      break;
    default:
      break;
  }

  const isPending = React.useMemo(
    () => pendingCommands.includes(command), [command, pendingCommands],
  );

  return (
    <Paper style={{ width: 'inherit', padding: 10, margin: 10 }}>
      <form onSubmit={handleSubmit(handleFormSubmit)} id={command}>
        <Grid
          container
          key={command}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography>{t(`devices.commands.${command}`)}</Typography>
          </Grid>
          {['SET_DEVICE_NAME', 'SET_GROUP_NAME'].includes(command) && (
            <Grid item style={{ minWidth: 200 }}>
              <Controller
                as={(
                  <TextField
                    label={label}
                    placeholder={param}
                    error={errors.param}
                    fullWidth
                    required
                    autoCapitalize="none"
                    inputProps={{
                      maxLength: 20,
                      minLength: 1,
                      pattern: '^[a-zA-Z0-9 _-]*$',
                    }}
                  />
                )}
                id="param"
                defaultValue={param}
                name="param"
                control={control}
              />
            </Grid>
          )}
          {command === 'SET_COMMUNICATION_PROTOCOL' && (
            <Grid item style={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <FormLabel>{t('common.period')}</FormLabel>
                <Controller
                  as={(
                    <Select
                      labelId="roles-label"
                      fullWidth
                      required
                    >
                      <MenuItem key="null" value=""> </MenuItem>
                      <MenuItem key="Cayenne" value={0}>Cayenne LPP</MenuItem>
                      <MenuItem key="Proprietary" value={1}>Proprietary protocol</MenuItem>
                    </Select>
                  )}
                  id="param"
                  name="param"
                  defaultValue={1}
                  control={control}
                />
              </FormControl>
            </Grid>
          )}
          {command === 'SET_TRANSISSION_RATE' && (
            <Grid item style={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <FormLabel>{t('common.period')}</FormLabel>
                <Controller
                  as={(
                    <Select
                      labelId="roles-label"
                      fullWidth
                      required
                    >
                      <MenuItem key="null" value=""> </MenuItem>
                      {Object.keys(TRANSISSION_RATES)
                        .map((key) => (
                          <MenuItem key={key} value={TRANSISSION_RATES[key]}>
                            {t(`enums.periods.${key}`)}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                  id="param"
                  name="param"
                  defaultValue=""
                  control={control}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <Button
              type="submit"
              color={isPending ? 'default' : 'primary'}
              variant="contained"
            >
              <Typography>{isPending ? t('common.pending') : t('common.send')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

CommandFormRow.propTypes = {
  command: PropTypes.string.isRequired,
  param: PropTypes.string,
  pendingCommands: PropTypes.arrayOf(PropTypes.string),
  onFormSubmit: PropTypes.func.isRequired,
};

CommandFormRow.defaultProps = {
  param: '',
  pendingCommands: [],
};

export default CommandFormRow;
