import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Paper,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

import { clearTypenames } from '../../utils/graphql';
import { TimezoneSelector } from '../../generic';

const GroupForm = (props) => {
  const {
    group,
    onFormSubmit,
  } = props;
  const { t } = useTranslation('translations');

  // TODO check se va anche su IE
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    errors, control, handleSubmit,
  } = useForm({
    defaultValues: group
      ? {
        ...group,
      }
      : {
        timezone: tz || '',
        onlySIUnits: false,
      },
  });

  const handleFormSubmit = (values) => {
    onFormSubmit(clearTypenames({
      ...values,
    }));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Controller
            as={(
              <TextField
                label={t('common.name')}
                placeholder={t('common.name')}
                error={errors.name}
                helperText={errors.name ? t('common.requiredField') : null}
                fullWidth
                required
                autoFocus
                autoCapitalize="none"
              />
          )}
            id="name"
            defaultValue=""
            name="name"
            control={control}
          />
          {errors.name && <p>{t('common.requiredField')}</p>}
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} style={{ padding: 20 }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TimezoneSelector
                  id="timezone"
                  name="timezone"
                  control={control}
                />
                {errors.timezone && <p>{t('common.requiredField')}</p>}
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>{t('common.onlySIUnits')}</FormLabel>
                  <Controller
                    name="onlySIUnits"
                    control={control}
                    render={(checkboxProps) => (
                      <Checkbox
                        onChange={(e) => checkboxProps.onChange(e.target.checked)}
                        checked={checkboxProps.value}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
          >
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

GroupForm.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    onlySIUnits: PropTypes.bool,
    timezone: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

GroupForm.defaultProps = {
  group: null,
};

export default GroupForm;
