const SEC0 = 0;
const SEC60 = 60; // 1 min
const SEC120 = 120; // 2 min
const SEC300 = 300; // 5 min
const SEC600 = 600; // 10 min
const SEC900 = 900; // 15 min
const SEC1800 = 1800; // 30 min
const SEC3600 = 3600; // 1 h
const SEC7200 = 7200; // 2 h
const SEC14400 = 14400; // 4 h
const SEC28800 = 28800; // 8 h
const SEC43200 = 43200; // 12 h
const SEC86400 = 86400; // 24 H

export default {
  SEC0,
  SEC60,
  SEC120,
  SEC300,
  SEC600,
  SEC900,
  SEC1800,
  SEC3600,
  SEC7200,
  SEC14400,
  SEC28800,
  SEC43200,
  SEC86400,
};
