/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Chip,
  // Icon,
  Typography,
} from '@material-ui/core';
import {
  Warning,
} from '@material-ui/icons';
import Icon from '@mdi/react';
import {
  mdiAlphabeticalVariant,
  mdiAltimeter,
  mdiAxisXArrow,
  mdiAxisYArrow,
  mdiAxisZArrow,
  mdiBattery,
  mdiChartBellCurveCumulative,
  mdiChartLine,
  mdiCounter,
  mdiFan,
  mdiGauge,
  mdiLeaf,
  mdiLightningBolt,
  mdiMapMarker,
  mdiMoleculeCo,
  mdiMoleculeCo2,
  mdiPowerPlug,
  mdiSignalVariant,
  mdiSineWave,
  mdiSpeedometer,
  mdiThermometer,
  mdiTimelapse,
  mdiWaterPercent,
  mdiWater,
  mdiWavesArrowRight,
  mdiWavesArrowUp,
  mdiWeatherPouring,
  mdiWeatherRainy,
  mdiWeatherWindy,
  mdiWhiteBalanceSunny,
  mdiWindsock,
} from '@mdi/js';

import {
  Card,
  CardHeader,
  CardIcon,
  CardFooter,
} from '../../generic/Card';

const DeviceMeasureCard = (props) => {
  const {
    realLastValue,
    unitOfMeasure,
    measureType,
    alarm,
    error,
    icon,
  } = props;

  const loadedIcons = {
    'mdiAlphabeticalVariant': mdiAlphabeticalVariant,
    'mdiAltimeter': mdiAltimeter,
    'mdiAxisXArrow': mdiAxisXArrow,
    'mdiAxisYArrow': mdiAxisYArrow,
    'mdiAxisZArrow': mdiAxisZArrow,
    'mdiBattery': mdiBattery,
    'mdiChartBellCurveCumulative': mdiChartBellCurveCumulative,
    'mdiChartLine': mdiChartLine,
    'mdiCounter': mdiCounter,
    'mdiFan': mdiFan,
    'mdiGauge': mdiGauge,
    'mdiLeaf': mdiLeaf,
    'mdiLightningBolt': mdiLightningBolt,
    'mdiMapMarker': mdiMapMarker,
    'mdiMoleculeCo': mdiMoleculeCo,
    'mdiMoleculeCo2': mdiMoleculeCo2,
    'mdiPowerPlug': mdiPowerPlug,
    'mdiSignalVariant': mdiSignalVariant,
    'mdiSineWave': mdiSineWave,
    'mdiSpeedometer': mdiSpeedometer,
    'mdiThermometer': mdiThermometer,
    'mdiTimelapse': mdiTimelapse,
    'mdiWaterPercent': mdiWaterPercent,
    'mdiWater': mdiWater,
    'mdiWavesArrowRight': mdiWavesArrowRight,
    'mdiWavesArrowUp': mdiWavesArrowUp,
    'mdiWeatherPouring': mdiWeatherPouring,
    'mdiWeatherRainy': mdiWeatherRainy,
    'mdiWeatherWindy': mdiWeatherWindy,
    'mdiWhiteBalanceSunny': mdiWhiteBalanceSunny,
    'mdiWindsock': mdiWindsock,
  };

  return (
    <Card style={{ minHeight: 180 }}>
      <CardHeader>
        {icon && (
          <CardIcon>
            <Icon
              path={loadedIcons[icon] || mdiChartLine}
              size={1}
              color="white"
            />
          </CardIcon>
        )}
        <p>{measureType}</p>
        {error
          ? (
            <Typography variant="h4" color="error">
              {error}
            </Typography>
          )
          : (
            <Typography variant="h4">
              {realLastValue}
              {' '}
              <small>{unitOfMeasure}</small>
            </Typography>
          )}
      </CardHeader>
      {(alarm || error) && (
        <CardFooter>
          <Chip
            icon={<Warning color="error" />}
            label={alarm}
            color="secondary"
            variant="outlined"
          />
        </CardFooter>
      )}
    </Card>
  );
};

const propTypes = {
  realLastValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  measureType: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  alarm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  error: PropTypes.string,
  icon: PropTypes.string,
};

const defaultProps = {
  realLastValue: null,
  measureType: null,
  unitOfMeasure: null,
  alarm: null,
  error: null,
  icon: null,
};

DeviceMeasureCard.propTypes = propTypes;
DeviceMeasureCard.defaultProps = defaultProps;

export default DeviceMeasureCard;
