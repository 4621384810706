import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  startOfDay, sub, setMilliseconds, setSeconds,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core';

import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import {
  getLocale,
  getTimezoneOffsetCorrection,
} from '../../utils/date';
import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import {
  FTP_EXPORT_INTEGRATION_TEST_MUTATION,
  FTP_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION,
  FTP_EXPORT_INTEGRATION_RESET_LAST_MUTATION,
  FTP_EXPORT_INTEGRATION_TEST_JOB_MUTATION,
} from '../queries';

const FTPExportIntegrationTestingCard = (props) => {
  const {
    id,
    timezone,
  } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { currentUser } = React.useContext(AuthenticationContext);

  const [successOpen, setSuccessOpen] = React.useState(false);
  const [startRange, setStartRange] = React.useState(startOfDay(new Date()));
  const [endRange, setEndRange] = React.useState(new Date());
  const [resetLastDatetime, setResetLastDatetime] = React.useState(new Date());

  const correctionOffset = React.useMemo(() => getTimezoneOffsetCorrection(timezone), [timezone]);

  const [
    ftpExportIntegrationTestMutation,
    {
      loading,
      error,
    },
  ] = useMutation(FTP_EXPORT_INTEGRATION_TEST_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    ftpExportIntegrationTestBetweenDatetimeMutation,
    {
      loading: betweenDatetimeLoading,
      error: betweenDatetimeError,
    },
  ] = useMutation(FTP_EXPORT_INTEGRATION_TEST_BETWEEN_DATETIME_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    ftpExportIntegrationResetLastMutation,
    {
      loading: resetLastLoading,
      error: resetLastError,
    },
  ] = useMutation(FTP_EXPORT_INTEGRATION_RESET_LAST_MUTATION, {
    awaitRefetchQueries: true,
  });

  const [
    ftpExportIntegrationTestJobMutation,
    {
      loading: testJobLoading,
      error: testJobError,
    },
  ] = useMutation(FTP_EXPORT_INTEGRATION_TEST_JOB_MUTATION);

  const handleTestSubmit = async () => {
    try {
      const result = await ftpExportIntegrationTestMutation({
        variables: {
          input: {
            id,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleTestBetweenDatetimeSubmit = async () => {
    try {
      const result = await ftpExportIntegrationTestBetweenDatetimeMutation({
        variables: {
          input: {
            id,
            from: sub(startRange, { seconds: correctionOffset }),
            to: sub(endRange, { seconds: correctionOffset }),
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleResetLastSubmit = async () => {
    try {
      const result = await ftpExportIntegrationResetLastMutation({
        variables: {
          input: {
            id,
            date: sub(resetLastDatetime, { seconds: correctionOffset }),
            overrideGroupSetting: true,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleTestJobSubmit = async () => {
    try {
      const result = await ftpExportIntegrationTestJobMutation({
        variables: {
          input: {
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <CardWrapper
      cardTitle={t('exportIntegrations.ftp.test.title')}
      style={{ marginTop: 40 }}
      cardBody={
        (
          <Grid container spacing={4} style={{ paddingTop: 20 }}>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper
              error={error || betweenDatetimeError || resetLastError || testJobError}
            />
            <Loader
              loading={loading || betweenDatetimeLoading || resetLastLoading || testJobLoading}
            />
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleTestSubmit()}
                >
                  <Typography>{t('exportIntegrations.ftp.test.testFTP')}</Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      style={{ height: 30 }}
                      value={setMilliseconds(setSeconds(startRange, 0), 0)}
                      onChange={setStartRange}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      value={endRange}
                      onChange={setEndRange}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleTestBetweenDatetimeSubmit()}
                  >
                    <Typography>{t('exportIntegrations.ftp.test.testFTPBetweenDatetime')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} direction="row" justify="center" alignItems="center">
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
                    <KeyboardDateTimePicker
                      value={setMilliseconds(setSeconds(resetLastDatetime, 0), 0)}
                      onChange={setResetLastDatetime}
                      showTodayButton
                      todayLabel={t('common.today')}
                      okLabel={t('common.ok')}
                      cancelLabel={t('common.cancel')}
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleResetLastSubmit()}
                  >
                    <Typography>{t('exportIntegrations.ftp.test.resetLast')}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {currentUser.email === 'emmanuele.gonzato@geekcups.com' && (
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => handleTestJobSubmit()}
                  >
                    <Typography>TEST JOB</Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )
      }
    />
  );
};

FTPExportIntegrationTestingCard.propTypes = {
  id: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default FTPExportIntegrationTestingCard;
