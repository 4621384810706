import {
  createHttpLink,
} from 'apollo-link-http';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';

import config from './config';

const httpLink = createHttpLink({
  uri: config.graphQLUri,
});

const authLink = setContext((_, { headers }) => {
  const token = window.sessionStorage.getItem('token');
  const authHeaders = {
    authorization: token ? `Bearer ${token}` : '',
  };
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.sessionStorage.removeItem('token');
    window.location.replace('/');
  }
  else if (graphQLErrors) {
    // eslint-disable-next-line no-console
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
  }
});

const httpCompositeLink = ApolloLink.from([errorLink, authLink, httpLink]);

const apolloClient = new ApolloClient({
  link: httpCompositeLink,
  cache: new InMemoryCache(),
});

export default apolloClient;
