import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Icon,
} from '@material-ui/core';

import AdminNavbarLinks from './Navbars/AdminNavbarLinks';

import styles from '../assets/jss/material-dashboard-react/components/sidebarStyle.js';

const image = '../../assets/img/sidebar-4.jpg';

const useStyles = makeStyles(styles);

const Sidebar = (props) => {
  const classes = useStyles();

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }

  const {
    open,
    logo,
    routes,
    handleDrawerToggle,
  } = props;

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const activePro = ' ';
        const listItemClasses = classNames({
          [` ${classes.blue}`]: activeRoute(prop.path),
        });
        const whiteFontClasses = classNames({
          [` ${classes.whiteFont}`]: activeRoute(prop.path),
        });
        return (
          <NavLink
            to={prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            // eslint-disable-next-line react/no-array-index-key
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  const brand = (
    <div className={classes.logo}>
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a
        className={classNames(classes.logoLink)}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} style={{ width: 210 }} />
        </div>
      </a>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                // backgroundColor: "rgba(0, 0, 0, 0.87) ",
                backgroundImage: `url(${image})`,
                // backgroundImage: "url(./assets/img/sidebar-4.jpg)",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{
                // backgroundColor: "rgba(0, 0, 0, 0.87) ",
                backgroundImage: `url(${image})`,
                // backgroundImage: "url(./assets/img/sidebar-4.jpg)",
              }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

const propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
};

Sidebar.propTypes = propTypes;

export default Sidebar;
