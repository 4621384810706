import React from 'react';
import PropTypes from 'prop-types';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import ConfigurationCard from '../components/ConfigurationCard.jsx';

const ConfigurationsTab = (props) => {
  const { device } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <ConfigurationCard
          device={device}
        />
      </GridItem>
    </GridContainer>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

ConfigurationsTab.propTypes = propTypes;
export default ConfigurationsTab;
