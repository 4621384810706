import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  // Chip,
  Select,
  // Input,
  MenuItem,
  Grid,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';

import { GROUP_ROLES } from '../../enum';

import { clearTypenames } from '../../utils/graphql';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 48;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const GroupUserPermissionForm = (props) => {
  const {
    userPermission,
    onFormSubmit,
  } = props;

  const { t } = useTranslation('translations');

  const {
    // errors,
    control,
    handleSubmit,
  } = useForm(
    {
      defaultValues: {
        ...userPermission,
        role: userPermission.roles ? userPermission.roles[0] : null,
      },
    },
  );

  const handleFormSubmit = (values) => {
    const filteredValues = {
      ...values,
      roles: [values.role],
    };
    delete filteredValues.role;
    onFormSubmit(clearTypenames(filteredValues));
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} style={{ padding: 10 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>{t('common.roles')}</FormLabel>
            <Controller
              as={(
                <Select
                  labelId="role"
                  id="role"
                  variant="outlined"
                >
                  {Object.keys(GROUP_ROLES).map((key) => (
                    <MenuItem key={GROUP_ROLES[key]} value={GROUP_ROLES[key]}>
                      {t(`enums.groupRoles.${GROUP_ROLES[key]}`)}
                    </MenuItem>
                  ))}
                  {/* <Select
                    labelId="roles-label"
                    multiple
                    fullWidth
                    required
                    value={roles}
                    onChange={handleChange}
                    input={<Input id="roles-chip" />}
                    renderValue={(selected) => (
                      <div className={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={t(`enums.groupRoles.${GROUP_ROLES[value]}`)}
                            style={{ margin: 2 }}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  > */}
                </Select>
              )}
              id="role"
              name="role"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth type="submit" color="primary" variant="contained">
            <Typography>{t('common.save')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  userPermission: PropTypes.shape({
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  userPermission: null,
};

GroupUserPermissionForm.propTypes = propTypes;
GroupUserPermissionForm.defaultProps = defaultProps;

export default GroupUserPermissionForm;
