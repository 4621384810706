import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthenticationProvider } from './authentication';
import { AuthenticatedLayout } from './layouts';

import './assets/css/material-dashboard-react.css';
import apolloClient from './apollo';
import './i18n';

import * as serviceWorker from './serviceWorker';

const MainApp = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AuthenticationProvider>
        <AuthenticatedLayout />
      </AuthenticationProvider>
    </BrowserRouter>
  </ApolloProvider>
);

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
