import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';

import {
  Loader,
  GraphQLErrorWrapper,
  SuccessSnackBar,
} from '../../generic';
import DeviceAddToGroupForm from '../../device/components/DeviceAddToGroupForm';

import {
  GROUP_QUERY,
} from '../queries';
import {
  DEVICE_ADD_TO_GROUP_MUTATION,
} from '../../device/queries';

const GroupDeviceAddModal = (props) => {
  const {
    group,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation('translations');
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [controlledError, setControlledError] = React.useState(null);

  const handleError = (error) => {
    setControlledError(error);
  };

  const [
    deviceAddToGroupMutationAction,
    {
      loading,
    },
  ] = useMutation(DEVICE_ADD_TO_GROUP_MUTATION, {
    onError: handleError,
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setControlledError(null);
    onClose();
  };

  const handleFormSubmit = async (values) => {
    try {
      const result = await deviceAddToGroupMutationAction({
        variables: {
          input: {
            devEUI: values.devEUI,
            groupId: group.id,
          },
        },
      });
      if (result && result.data && result.data.deviceAddToGroup) {
        setSuccessOpen(true);
        onClose();
      }
    }
    catch (e) {
      setControlledError(e);
    }
  };

  return (
    <>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
      <Dialog
        fullWidth
        maxWidth="md"
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('groups.addDevice.title')}</DialogTitle>
        <DialogContent>
          <GraphQLErrorWrapper error={controlledError} />
          <Loader loading={loading} />
          <DeviceAddToGroupForm
            group={group}
            onFormSubmit={handleFormSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

GroupDeviceAddModal.propTypes = propTypes;

export default GroupDeviceAddModal;
