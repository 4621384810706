/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Typography,
} from '@material-ui/core';

import Loader from './Loader';
import styles from '../assets/jss/material-dashboard-react/components/tableStyle.js';

const useStyles = makeStyles(styles);

const CustomTable = (props) => {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    noDataText,
    loading,
  } = props;

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Loader loading={loading} />
      </Grid>
    );
  }

  if (!tableData || tableData.length === 0) {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h6" component="h6">
          {noDataText}
        </Typography>
      </Grid>
    );
  }

  return (
    <div className={classes.tableResponsive} style={{ overflow: 'auto' }}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[`${tableHeaderColor}TableHeader`]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => (
                <TableCell
                  className={`${classes.tableCell} ${classes.tableHeadCell}`}
                  key={key}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        {tableData && (
          <TableBody>
            {tableData.map((prop, key) => (
              <TableRow
                key={key}
                className={classes.tableBodyRow}
              >
                {prop.map((mappedProp, mappedKey) => (
                  <TableCell className={classes.tableCell} key={mappedKey} style={{ whiteSpace: 'nowrap' }}>
                    {mappedProp}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

const propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  ),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
    ),
  ),
  noDataText: PropTypes.string,
  loading: PropTypes.bool,
};

const defaultProps = {
  tableHeaderColor: 'gray',
  tableHead: undefined,
  tableData: null,
  noDataText: 'NO data',
  loading: false,
};

CustomTable.propTypes = propTypes;

CustomTable.defaultProps = defaultProps;

export default CustomTable;
