import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  Edit,
  Delete,
} from '@material-ui/icons';
import {
  CardWrapper,
  CustomTable,
  Loader,
  GraphQLErrorWrapper,
} from '../../generic';

import GroupUserPermissionEditModal from './GroupUserPermissionEditModal';

import {
  GROUP_QUERY,
  GROUP_DELETE_USER_PERMISSION_MUTATION,
} from '../queries';

const GroupUserPermissionsList = (props) => {
  const {
    group,
    canEdit,
  } = props;
  const { t } = useTranslation('translations');

  const [editUserPermission, setEditUserPermission] = React.useState(null);

  const [
    groupDeleteUserPermissionMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(GROUP_DELETE_USER_PERMISSION_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: GROUP_QUERY,
          variables: {
            id: group.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  const handleDelete = (userEmail) => {
    groupDeleteUserPermissionMutationAction({
      variables: {
        input: {
          id: group.id,
          email: userEmail,
        },
      },
    });
  };

  const handleEdit = (userPermission) => {
    setEditUserPermission(userPermission);
  };

  return (
    <>
      <CardWrapper
        cardTitle={t('userPermissions.list.title')}
        cardSubtitle={t('userPermissions.list.subtitle')}
        cardBody={
          (
            <>
              <GraphQLErrorWrapper error={error} />
              <Loader loading={loading} />
              <CustomTable
                noDataText={t('userPermissions.list.noData')}
                tableHeaderColor="primary"
                tableHead={[
                  t('common.email'),
                  t('common.roles'),
                  canEdit && (
                    <Grid container justify="flex-end">
                      {t('common.actions')}
                    </Grid>
                  ),
                ]}
                tableData={group.userPermissions.map((userPermission) => ([
                  userPermission.email,
                  userPermission.roles.map((role) => t(`enums.groupRoles.${role}`)).join(', '),
                  (canEdit && (
                    <Grid container justify="flex-end">
                      <IconButton color="primary" onClick={() => handleEdit(userPermission)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDelete(userPermission.email)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </Grid>
                  )),
                ]))}
              />
            </>
          )
        }
      />
      {editUserPermission && (
        <GroupUserPermissionEditModal
          group={group}
          userPermission={editUserPermission}
          onClose={() => setEditUserPermission(null)}
        />
      )}
    </>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
};

GroupUserPermissionsList.propTypes = propTypes;

export default GroupUserPermissionsList;
