import { gql } from '@apollo/client';
import { DEVICE_DETAIL_FRAGMENT } from '../device/queries';
import {
  DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT,
  FTP_EXPORT_INTEGRATION_FRAGMENT,
} from '../exportIntegration/queries';

export const APPLICATION_LIST_ITEM_FRAGMENT = gql`
  fragment ApplicationListItemFragment on Application {
    id
    appID
    appEUI
    timezone
    onlySIUnits
    description
  }
`;

export const APPLICATION_DETAIL_FRAGMENT = gql`
  fragment ApplicationDetailFragment on Application {
    id
    appID
    appEUI
    description
    url
    processID
    accessKey
    isV3
    onlySIUnits
    isTimeSynchActive
    timezone
    deltaCloudExportIntegration {
      ...DeltaCloudExportIntegrationFragment
    }
    ftpExportIntegration {
      ...FtpExportIntegrationFragment
    }
    createdAt
    updatedAt
    deletedAt
  },
  ${DELTA_CLOUD_EXPORT_INTEGRATION_FRAGMENT}
  ${FTP_EXPORT_INTEGRATION_FRAGMENT}
`;

export const APPLICATIONS_QUERY = gql`
  query applicationsQuery($afterCursor: String, $pageSize: Int!, $filter: ApplicationFilter) {
    viewer {
      applications(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...ApplicationListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${APPLICATION_LIST_ITEM_FRAGMENT}
`;

export const APPLICATION_QUERY = gql`
  query applicationQuery($id: ID!) {
    viewer {
      application(id: $id) {
        ...ApplicationDetailFragment
      }
    }
  }
  ${APPLICATION_DETAIL_FRAGMENT}
`;

export const APPLICATION_DEVICES_QUERY = gql`
  query applicationDevicesQuery($afterCursor: String, $pageSize: Int!, $applicationId: ID!, $filter: DeviceFilter) {
    viewer {
      application(id: $applicationId) {
        devices(first: $pageSize, after: $afterCursor, filter: $filter) {
          edges {
            node {
              ...DeviceDetailFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
  ${DEVICE_DETAIL_FRAGMENT}
`;

export const APPLICATION_UPDATE_MUTATION = gql`
  mutation ApplicationUpdateMutation($input: ApplicationUpdateInput!) {
    applicationUpdate(input: $input) {
      application {
        ...ApplicationDetailFragment
      }
    }
  }
  ${APPLICATION_DETAIL_FRAGMENT}
`;

export const APPLICATION_DELETE_MUTATION = gql`
  mutation ApplicationDeleteMutation($input: ApplicationDeleteInput!) {
    applicationDelete(input: $input) {
      success
    }
  }
`;

export const APPLICATION_UPDATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION = gql`
  mutation ApplicationUpdateDeltaCloudExportIntegrationMutation($input: ApplicationUpdateDeltaCloudExportIntegrationInput!) {
    applicationUpdateDeltaCloudExportIntegration(input: $input) {
      application {
        ...ApplicationDetailFragment
      }
    }
  }
  ${APPLICATION_DETAIL_FRAGMENT}
`;

export const APPLICATION_UPDATE_FTP_EXPORT_INTEGRATION_MUTATION = gql`
  mutation ApplicationUpdateFTPExportIntegrationMutation($input: ApplicationUpdateFTPExportIntegrationInput!) {
    applicationUpdateFTPExportIntegration(input: $input) {
      application {
        ...ApplicationDetailFragment
      }
    }
  }
  ${APPLICATION_DETAIL_FRAGMENT}
`;

export const APPLICATION_PROPAGATE_DELTA_CLOUD_EXPORT_INTEGRATION_MUTATION = gql`
  mutation ApplicationPropagateDeltaCloudExportIntegrationMutation($input: ApplicationPropagateDeltaCloudExportIntegrationInput!) {
    applicationPropagateDeltaCloudExportIntegration(input: $input) {
      success
    }
  }
`;

export const APPLICATION_PROPAGATE_FTP_EXPORT_INTEGRATION_MUTATION = gql`
  mutation ApplicationPropagateFTPExportIntegrationMutation($input: ApplicationPropagateFTPExportIntegrationInput!) {
    applicationPropagateFTPExportIntegration(input: $input) {
      success
    }
  }
`;
export const APPLICATION_PROPAGATE_SETTINGS_MUTATION = gql`
  mutation ApplicationPropagateSettingsMutation($input: ApplicationPropagateSettingsInput!) {
    applicationPropagateSettings(input: $input) {
      success
    }
  }
`;
