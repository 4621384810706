import { gql } from '@apollo/client';

export const GROUP_INVITE_CREATE_MUTATION = gql`
  mutation GroupInviteCreateMutation($input: GroupInviteCreateInput!) {
    groupInviteCreate(input: $input) {
      groupInvite {
        id
        email
        roles
      }
    }
  }
`;

export const GROUP_INVITE_DELETE_MUTATION = gql`
  mutation GroupInviteDeleteMutation($input: GroupInviteDeleteInput!) {
    groupInviteDelete(input: $input) {
      success
    }
  }
`;

export const GROUP_INVITE_ACCEPT_MUTATION = gql`
  mutation GroupInviteAcceptMutation($input: GroupInviteAcceptInput!) {
    groupInviteAccept(input: $input) {
      success
    }
  }
`;
