import React from 'react';
import PropTypes from 'prop-types';

import {
  GridItem,
  GridContainer,
} from '../../generic';

import {
  GroupUserPermissionsList,
  GroupOwnershipInvite,
} from '../components';
import {
  GroupInvitesList,
} from '../../groupInvite/components';

const GroupUsersTab = (props) => {
  const { group, canEdit } = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GroupOwnershipInvite
          group={group}
          canEdit={canEdit}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <GroupUserPermissionsList
          group={group}
          canEdit={canEdit}
        />
      </GridItem>
      {canEdit && (
        <GridItem xs={12} sm={12} md={12}>
          <GroupInvitesList
            group={group}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};

const propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

GroupUsersTab.propTypes = propTypes;

export default GroupUsersTab;
